<template>
	<div id="content" class="app-content">
		<b-col xl="12">
			<b-row>
				<b-col>
					<h1 class="page-header">
						<a @click="$router.back()" style="cursor: pointer;">{{ $t('invoices.Invoices') }}</a>
					</h1>
				</b-col>
				<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right" v-if="isReady && userHasRight('create')">
					<font-awesome-icon
						:icon="['fas', 'plus-square']"
						@click="isShowCreateInvoices = !isShowCreateInvoices"
						size="2xl"
						style="cursor: pointer; margin-left: 0.5em;"
						:title="$t('form.new')"
					/>
				</b-col>
			</b-row>	
			<hr class="mb-4" />
		</b-col>
        <invoice-create
			v-if="isReady && userHasRight('create')"
			:class="{ 'd-none': !isShowCreateInvoices }"
			@invoice-created="onInvoiceCreated"
		></invoice-create>
		<div class="row mb-2 fatture-grid-actions">
			<div class="col-lg-12 col-xl">
				<button
					v-if="userHasRight('create')"
					@click="publish"
					:disabled="isGridActionButtonDisabled"
					class="btn btn-primary"
					type="button"> {{ $t('invoices.Publish') }} </button>
				<button
					v-if="userHasRight('read')"
					@click="download('xml')"
					:disabled="isGridActionButtonDisabled"
					class="btn btn-primary"
					type="button"> {{ $t('invoices.DownloadXML') }} </button>
				<button
					v-if="userHasRight('read')"
					@click="download('pdf')"
					:disabled="isGridActionButtonDisabled"
					class="btn btn-primary"
					type="button"> {{ $t('invoices.DownloadPDF') }} </button>
				<button
					v-if="userHasRight('read')"
					@click="download('excel')"
					:disabled="isGridActionButtonDisabled"
					class="btn btn-primary"
					type="button"> {{ $t('invoices.ExportDetails') }} </button>
				<button
					v-if="userHasRight('read')"
					@click="showUpdateForm"
					:disabled="isGridActionButtonDisabled"
					class="btn btn-primary"
					:title="$t('invoices.UpdateNumber')"
					type="button"> {{ $t('general.Number') }}
				</button>
				<span v-if="isExecutingButtonAction"><i class="fa-solid fa-spinner fa-spin-pulse fa-2xl"
                                style="margin-left: 0.5em;"></i></span>
			</div>
			<div class="col d-flex justify-content-end">
				<div class="d-flex">
                    <div class="col d-flex align-items-center justify-content-end" style="margin-right: 0.4em;">
                        <label for="invoicesCreatable">{{ $t('grid.rowsSelected') }}: </label>
                    </div>
                    <div class="col">
						<input id="invoicesCreatable" class="form-control"
						:value="selectedRows.length" disabled />
                    </div>
					<div class="col d-flex justify-content-end">
						<button
							@click="unselect"
							class="btn btn-primary"
							type="button"> {{ $t('grid.unselect') }} </button>
					</div>
				</div>
			</div>
		</div>
		<Grid
			entityName="invoice"
            :isEdit="false"
			:columnsWhitelist="columnsWhitelist"
			:agGridProperties="{
				rowSelection: 'multiple',
				rowMultiSelectWithClick: true,
				paginationPageSize: 20,
			}"
			:agGridEvents="{
				selectionChanged: onSelectionChanged,
			}"
			@grid-ready="onGridReady"
			:columnExtensions="columnExtensions"
			:columnsPrepend="columnsPrepend"
		></Grid>
		<button
          @click="$router.back()"
          class="btn btn-secondary mt-3"
          type="button">
          {{ $t('form.cancel') }}
        </button>
		<b-modal
			id="modal-invoice-number-update"
			:title="$t('invoices.UpdateNumber')"
			:ok-title="$t('general.Save')"
			@ok="updateInvoiceNumber"
		>
			<b-form @submit="updateInvoiceNumber">
				<b-form-group
					label="SAPR"
					label-for="invoice-sapr"
				>
					<b-form-input
						id="invoice-sapr"
						v-model="invoiceUpdateForm.sapr"
						required
						disabled
					></b-form-input>
				</b-form-group>
				<b-form-group
					:label="$t('general.Year')"
					label-for="invoice-year"
				>
					<b-form-input
						id="invoice-year"
						v-model="invoiceUpdateForm.year"
						required
						disabled
					></b-form-input>
				</b-form-group>
				<b-form-group
					:label="$t('general.Month')"
					label-for="invoice-month"
				>
					<b-form-input
						id="invoice-month"
						v-model="invoiceUpdateForm.month"
						required
						disabled
					></b-form-input>
				</b-form-group>
				<b-form-group
					:label="$t('general.Number')"
					label-for="invoice-number"
				>
					<b-form-input
						id="invoice-number"
						v-model="invoiceUpdateForm.number"
						required
					></b-form-input>
				</b-form-group>
			</b-form>
		</b-modal>
	</div>
</template>

<script>

import axios from 'axios';

import Grid from '../components/Grid.vue';
import InvoiceCreate from '../components/InvoiceCreate.vue';
import formatter from '../helpers/formatter';
import api from '../_services/CommercialMgmtService.js';
import { getAPIObjectURL } from '../_services/TechnicalMgmtService.js';

export default {	
	name: 'InvoicePage',
	components: {
		Grid,
        InvoiceCreate,
	},
	data() {
		return {
			formatter,
            isShowCreateInvoices: true,
			invoicesCreateForm: {},
			columnsWhitelist: [
				'sapr',
				'year',
				'month',
				'quantity',
				'amount',
				'status',
				'number',
				'date',
			],
			gridApi: null,
			gridColumnApi: null,
			selectedRows: [],
			isExecutingButtonAction: false,
			invoiceUpdateForm: {},
			columnExtensions: [],
			columnsPrepend: [],
			isReady: false,
			userRights: [],
		};
	},
	async created () {
		this.isReady = false;
		this.userRights = (await axios.get(`${getAPIObjectURL('invoice')}/current-user-rights`)).data;
		this.isReady = true;
	},
	beforeMount() {
		this.columnsPrepend = [
			{
				field: 'fund_name',
				headerName: this.$t('general.Fund'),
				// filter: 'none',
			},
			{
				field: 'SPV_name',
				headerName: 'SPV',
				// filter: 'none',
			},
			{
				field: 'plant_name',
				headerName: this.$t('plants.Plant'),
				// filter: 'none',
			},
		];
		this.columnExtensions = [
			{
				field: 'sapr',
				headerName: 'SAPR',
			},
			{
				field: 'year',
				headerName: this.$t('general.Year'),
			},
			{
				field: 'month',
				headerName: this.$t('general.Month'),
			},
			{
				field: 'status',
				headerName: this.$t('invoices.Published'),
			},
			{
				field: 'quantity',
				headerName: this.$t('invoices.Quantity'),
			},
			{
				field: 'amount',
				headerName: this.$t('invoices.Amount'),
			},
			{
				field: 'number',
				headerName: this.$t('general.Number'),
			},
			{
				field: 'date',
				headerName: this.$t('general.Date'),
			},
		];
	},
	// onbeforeMount() {
	// 	window.onbeforeunload
	// },
	computed: {
		isGridActionButtonDisabled() {
			return this.selectedRows.length === 0 || this.isExecutingButtonAction;
		},
	},
	methods: {
		userHasRight(right) {
			return this.userRights.includes(right);
		},
		showUpdateForm() {
			for (const row of this.selectedRows) {
				this.invoiceUpdateForm = {
					sapr: row.sapr,
					year: row.year,
					month: row.month,
					number: row.number,
				};
				break;
			}
			this.$bvModal.show('modal-invoice-number-update');
		},
		async updateInvoiceNumber() {
			const functionality = async () => {
				try {
					const response = await api.invoicesUpdateNumber(this.invoiceUpdateForm);
					this.gridApi.refreshServerSide();
					return response;
				}
				finally {
					this.$bvModal.hide('modal-invoice-number-update');
				}
			};
			return await this.executeButtonFunction(functionality);
		},
		onGridReady(params) {
			this.gridApi = params.api;
			this.gridColumnApi = params.columnApi;
		},
		async executeButtonFunction(func) {
			if (this.selectedRows.length === 0) {
				this.$alert('No rows selected', 'Error', 'error');
				return;
			}
			this.isExecutingButtonAction = true;
			try {
				await func();
			}
			catch (e) {
				console.error(e);
				this.$alert('Error', 'Error', 'error');
			}
			finally {
				this.isExecutingButtonAction = false;
			}
		},
		async download(format) {
			const functionality = async () => {
				const invoiceIdentifiers = this.selectedRows.map(row => ({
					sapr: row.sapr,
					year: row.year,
					month: row.month,
				}));
				await api.invoicesDownload(invoiceIdentifiers, format);
			};
			return await this.executeButtonFunction(functionality);
		},
		async publish() {
			const functionality = async () => {
				const invoiceIdentifier = this.selectedRows.map(row => ({
					sapr: row.sapr,
					year: row.year,
					month: row.month,
				}));
				const response = await api.invoicesPublish(invoiceIdentifier);
				this.gridApi.refreshServerSide();
				return response;
			};
			return await this.executeButtonFunction(functionality);
		},
		unselect() {
			this.gridApi?.deselectAll();
		},
		onSelectionChanged() {
			const selectedRows = this.gridApi?.getSelectedRows();
			this.selectedRows = selectedRows;
		},
		onInvoiceCreated() {
			this.gridApi?.refreshServerSide();
		},
	},
};
</script>
<style>
.fatture-grid-actions button:not(:first-child) {
	margin-left: 0.4em;
}
</style>