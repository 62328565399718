<template>
    <b-list-group v-if="isReady" class="border-0" flush>
        <div v-if="!userHasRight('read')" class="alert alert-danger" role="alert">
            {{ $t('rights.noReadRights') }}
        </div>
        <ag-grid-vue
            v-else
            style="width: 100%; height: 100%"
            @grid-ready="onGridReady"
            :columnDefs="columns"
            :defaultColDef="defaultColDef"
            class="ag-theme-alpine"
            rowModelType="serverSide"
            cacheBlockSize="100"
            :pagination="true"
            :animateRows="true"
            @first-data-rendered="onFirstDataRendered"	
            @row-double-clicked="select"
            :context="context"
            :autoSizeStrategy="autoSizeStrategy"
			v-bind="agGridProperties"
			v-on="agGridEvents"
			:localeText="localeText"
        />
	</b-list-group>
</template>
<script>
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import axios from 'axios';
import moment from 'moment';

import GridActions from "../components/ag-grid/GridActions.vue";
import {defaultColDef} from '../config/agGridDefaults';
import { getAPIObjectURL, defaultGridSort } from '../_services/TechnicalMgmtService.js';
import { SCHEMA_PROPERTIES_TO_HIDE } from "../components/Form.vue";
import { useFormStates } from '../_store/form.js';
import { storeToRefs } from 'pinia';

import { AG_GRID_LOCALE_DE, AG_GRID_LOCALE_IT, AG_GRID_LOCALE_EN } from '@ag-grid-community/locale';

let agGridLocale = AG_GRID_LOCALE_EN;

const locale = localStorage.getItem('customLocale');
if (locale === 'de') {
	agGridLocale = AG_GRID_LOCALE_DE;
}
else if (locale === 'it') {
	agGridLocale = AG_GRID_LOCALE_IT;
}

export default {	
	name: 'Grid',
	components: {
		AgGridVue,
		// eslint-disable-next-line vue/no-unused-components
		GridActions,
	},
	props: {
		entityName: {
			type: String,
		},
		isSelect: {
			type: Boolean,
			default: false,
		},
		selectEntityName: {
			type: String,
		},
		selectEntityId: {
			type: Number,
		},
		selectEntityColumn: {
			type: String,
		},
        isEdit : {
            type: Boolean,
            default: true,
        },
		columnsWhitelist: {
			type: Array,
			default: () => null,
		},
		agGridProperties: {
			type: Object,
			default: () => ({
				paginationPageSize: 20,
			}),
		},
		agGridEvents: {
			type: Object,
			default: () => ({}),
		},
		columnExtensions: {
			type: Array,
			default: () => [],
		},
		columnsPrepend: {
			type: Array,
			default: () => [],
		},
		onFetch: {
			type: Function,
			default: (params) => params,
		},
	},
	data() {
		return {
			gridApi: null,
			gridColumnApi: null,
			schema: null,
			isReady: false,
			columns: [],
			defaultColDef,
			userRights: [],
			context: null,
			autoSizeStrategy: null,
			localeText: agGridLocale,
		};
	},
	methods: {
		select(event) {
            if (!this.isEdit) {
                return;
            }
			this.onGridAction('edit', event.data);
		},
		onGridAction(action, data) {
			if (action === 'edit') {
				if (this.entityName === 'silver-plant-failures') {
					this.$router.push(`/plant-failures/form/${data.id}`);
				}
				else if (this.entityName === 'users') {
					this.$router.push(`/users/form/${data.id}`);
				}
				else {
					this.$router.push(`/forms/${this.entityName}/${data.id}`);
				}
			}
			else if (action === 'select') {
				const form = this.getForm(this.selectEntityName, this.selectEntityId);
				if (!form) {
					console.error('grid page: form to select into not found');
					return;
				}
				form.data[this.selectEntityColumn] = data.id;
				console.log('updated form', form);
				this.$router.back();
			}
			else if (action === 'delete') {
				this.$confirm(this.$t('form.confirmDelete'), this.$t('form.delete'), {
					confirmButtonText: this.$t('form.yes'),
					cancelButtonText: this.$t('form.no'),
					type: 'warning',
				}).then(async () => {
					try {
						console.log('deleting data', data);
						await axios.delete(`${getAPIObjectURL(this.entityName)}/${data.id}`);
						this.gridApi.refreshServerSide();
					}
					catch (e) {
						this.checkResponseForErrors(e.response);
						this.$alert(e?.response?.data?.message, 'Error', 'error');
						console.error('error while deleting data', e);
					}
				}).catch(() => {
					console.log('deletion canceled');
				});
			}
		},
		onFirstDataRendered() {
			// params.api.sizeColumnsToFit();
			// this.autoSizeAll(true);
		},
		userHasRight(right) {
			return this.userRights.includes(right);
		},
		checkResponseForErrors(response) {
			if (response.status === 401) {
				if (response?.data?.message?.includes('auth: session')) {
					this.$alert(this.$t('errors.sessionInvalid'), 'Error', 'error');
					this.$router.push('/user/login');
					return;
				}
				this.$alert(this.$t('errors.missingRights'), 'Error', 'error');
			}
		},
		onGridReady(params) {
			this.gridApi = params.api;
			this.gridColumnApi = params.columnApi;
			// params.api.sizeColumnsToFit();
			const dataSource = {
				getRows: async params => {
					try {
						defaultGridSort(params.request);
						params = this.onFetch(params);
						const response = await axios.post(`${getAPIObjectURL(this.entityName)}/query`, params.request);
						params.success({
							rowData: response.data.data,
							rowCount: response.data.count,
						});
					}
					catch (e) {
						this.checkResponseForErrors(e.response);
						console.error('error while fetching data', e);
						params.fail();
					}
				}
			};
			this.gridApi.setServerSideDatasource(dataSource);
			this.columnApi = params.columnApi;
			this.$emit('grid-ready', params);
		},
		createGridColumns (schema) {
			const JSONSchema = schema.JSONSchema;
			// const foreignKeys = schema.foreignKeys;
			let columns = [];
			for (const [columnName, definition] of Object.entries(JSONSchema.properties)) {
				if (this.entityName === 'silver-plants' && columnName === 'id') {
					// show id for this table
				}
				else if (
					SCHEMA_PROPERTIES_TO_HIDE.includes(columnName)
					// || foreignKeys.find(k => k.column_name === columnName)
					|| this.columnsWhitelist !== null && !this.columnsWhitelist.includes(columnName)
				) {
					continue;
				}
				const columnDefinition = {
					field: columnName,
					cellDataType: definition.type,
				};
				if (definition.type === 'number' || definition.type === 'integer') {
					columnDefinition.filter = 'agNumberColumnFilter';
					columnDefinition.filterParams = {
						filterOptions: ['equals', 'lessThan', 'greaterThan'],
					};
				}
				if (definition.format?.includes('date')) {
					columnDefinition.cellDataType = 'dateString';
					columnDefinition.filter = 'agDateColumnFilter';
					if (definition.format === 'date') {
						columnDefinition.cellRenderer = ({ value }) => value && moment(value).format('L');
					}
					else {
						columnDefinition.cellRenderer = ({ value }) => value && moment(value).format('lll');
					}
				}
				const columnExtension = this.columnExtensions.find(c => c.field === columnName);
				if (columnExtension) {
					Object.assign(columnDefinition, columnExtension);
				}
				columns.push(columnDefinition);
			}
			columns = this.columnsPrepend.concat(columns);
            if (this.isEdit && this.userHasRight('update')
                || this.isSelect) {
                columns.push({
                    field: 'actions',
                    cellRenderer: 'GridActions',
                    cellRendererParams: {
                        isEdit: this.isEdit && this.userHasRight('update'),
                        isSelect: this.isSelect,
						isDelete: this.userHasRight('delete'),
                    },
                });
            }
			this.columns = columns;
		},
		async loadInitialData() {
			this.isReady = false;
			try {
				const [schemaResponse, rightsResponse] = await Promise.all([
					axios.get(`${getAPIObjectURL(this.entityName)}/schema`),
					axios.get(`${getAPIObjectURL(this.entityName)}/current-user-rights`),
				]);
				this.schema = schemaResponse.data;
				this.userRights = rightsResponse.data;
				this.createGridColumns(this.schema);
				this.isReady = true;
			}
			catch (e) {
				this.checkResponseForErrors(e.response);
				console.error('error while fetching data', e);
			}
		},
		autoSizeAll(skipHeader) {
			// auto-sizes all columns to fit content but shrinks headers to them being not readable
			const allColumnIds = [];
			this.gridColumnApi.getColumns().forEach((column) => {
				allColumnIds.push(column.getId());
			});
			this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
		},
	},
	beforeMount() {
		this.context = { parent: this };
	},
	watch: {
		entityName: async function() {
			await this.loadInitialData();	
		},
	},
	async created() {
		this.autoSizeStrategy = {
			type: 'fitCellContents',
		};
		await this.loadInitialData();
	},
	setup() {
		const formStates = useFormStates();
		const { getForm } = storeToRefs(formStates);
		return { getForm, formStates };
	}
}
</script>