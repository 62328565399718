<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">Documents</b-breadcrumb-item>
					<b-breadcrumb-item active>O&M</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ accum[0].text }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="10">

					<b-row>
						<b-col><h2 class="page-header">{{ accum[0].text }}</h2></b-col>
						<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right"><a v-b-modal.modal-newFile><i class="fas fa-2xl fa-fw me-2 fa-plus-square"></i></a></b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">	
							<div style="height: 100%">			
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:columnDefs="columnDefs"
									:rowData="tree"
									:rowSelection="rowSelection"
									:pagination="true"
									:paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered"													
								/>
							</div>
						</b-col>	
					</b-row>

				</b-col>
			</b-row>
		</b-container>
		<!-- BEGIN modals -->
		<ModalNewFile />
		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

//import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';
import moment from 'moment';
import ModalNewFile from '../components/ModalNewFile';

import axios from 'axios'


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

import btnCellRenderer from '../components/ag-grid/btnActionsCellRenderer.vue';

import allMenuPoints from '../config/AppSidebarMenu.vue'

export default {
	name: 'DocumentsLoadGraphs',
	setup() {
		const store = defaultStore()
		return { store}
	},
	data() {
		return {
			technical: [],
			columnDefs: [
				{ headerName: 'Folder', field: 'folder_name', sortable: true, filter: true, minWidth: 150, maxWidth: 200, suppressSizeToFit: true, },
				{ headerName: 'File', field: 'file_name', sortable: true, filter: true, minWidth: 300, maxWidth: 450, suppressSizeToFit: true, },
				{ headerName: 'Description', field: 'description', sortable: true, filter: true, minWidth: 200, maxWidth: 400, suppressSizeToFit: true, },
				{ headerName: 'Actions', pinned: 'right', minWidth: 150, maxWidth: 200, cellRenderer: 'btnCellRenderer',
					cellRendererParams: {
						clicked: function(params, file, folder) {
							switch(params) {
								default:
									break;
								case 'delete':
									console.log('delete', file, folder);
										axios.post( 'https://edm.psaierenergies.it/files/delete-file?path=/'+folder+'/'+file, {
											headers: {
												"Content-Type": "application/json",
												"Access-Control-Allow-Origin": "*",
												"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
												"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
											},
										}).then(response => {
											console.log(response)										
											window.location.reload();							
										}).catch(err => {
											console.log(err.response);
										});
									break;
								case 'download':
									axios.post( 'https://edm.psaierenergies.it/files/download/', JSON.stringify( {"files": [folder+'/'+file] } ), {
										headers: {
											"Content-Type": "application/json",
											"Access-Control-Allow-Origin": "*",
											"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
											"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
										},
										responseType: "blob",
									}).then(response => {
										//console.log(response)

										var fileURL = window.URL.createObjectURL(new Blob([response.data]));
										var fileLink = document.createElement('a');
									
										fileLink.href = fileURL;
										fileLink.setAttribute('download', file);
										document.body.appendChild(fileLink);
									
										fileLink.click();
										
									}).catch(err => {
										console.log(err.response);
									});
									break;
							}
						}
					},
				},
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: {
				resizable: true,
				flex: 1,
				minWidth: 100
			},
			rowData: null,
			tree: [],
			folderRoute: '',
			accum: [],
		}
	},
	components: {
		ModalNewFile,
		AgGridVue,
		/* eslint-disable vue/no-unused-components */
		btnCellRenderer,
		appSidebarMenu: allMenuPoints,
		
	},
	methods: {	
		shorten(params) {
			return moment(String(params.value)).format('DD/MM/YYYY');
		},
		getTree() {
			console.log('Get files tree list:');
			this.store.setPlantID(null);

			axios.get( 'https://edm.psaierenergies.it/files/tree?folder='+this.folderRoute.path, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
					"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
				},
				responseType: "json",
			}).then(response => {				
				this.tree = response.data;
				console.log(this.tree);
			}).catch(err => {
				console.log(err.response);
			});
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onGridReady(params) {
			console.log('grid ready');
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
		},
	},
	created() {
		this.folderRoute = this.$route;
		//console.log('path:', this.folderRoute.path);
		
		let accum = [];
		function find(searchData, target) {
			target.forEach( (f) => {
				if(f.children) {
					find(searchData, f.children);					
					f.children.filter( arr => { if(arr.url == searchData) { accum.push(arr); } });
				}				
			});
		}

		find(this.folderRoute.path, allMenuPoints);
		this.accum = accum;
		//console.log(this.accum)

		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;

		this.getTree();	

	},
	/*mounted() {
		if (localStorage.getItem('reloaded')) {
			// The page was just reloaded. Clear the value from local storage
			// so that it will reload the next time this page is visited.
			localStorage.removeItem('reloaded');
		} else {
			// Set a flag so that we know not to reload the page twice.
			localStorage.setItem('reloaded', '1');
			location.reload();
		}
	}*/
}

</script>


