import axios from 'axios';

function isLocal() {
    return location.hostname === "localhost" || location.hostname === "127.0.0.1";
}

const HOST = isLocal() ? 'localhost:3000' : 'edm.psaierenergies.it';
const PROTOCOL = isLocal() ? 'http' : 'https';
const SERVICE_PATH_PREFIX = 'services/mail-service';

const API_BASE_URL = `${PROTOCOL}://${HOST}/${SERVICE_PATH_PREFIX}`;


class MailService {
    sendMail(mailInfo) {
        return axios.post(API_BASE_URL+'/mail/send', mailInfo, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
            },
            responseType: "json",
        })
    }
}

export default new MailService();