<template>
    <b-modal id="modal-newTechnical" centered ref="modal" title="New Technical" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            
			<div class="row mt-2">
				<label for="deadline" class="col-md-2 col-form-label">Deadline</label>
				<div class="col-md-10">
					<b-form-datepicker id="deadline" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.deadline" :state="deadlineState" aria-describedby="input-deadline-feedback" placeholder="deadline" trim></b-form-datepicker>
					<b-form-invalid-feedback id="input-deadline-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="priority" class="col-md-2 col-form-label">Priority</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="priority" v-model="form.priority" :options="priority_options" :state ="priorityState" aria-describedby="input-priority-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-priority-feedback">Select plant</b-form-invalid-feedback>
				</div>
			</div>

            <div class="row mt-2">
				<label for="responsible" class="col-md-2 col-form-label">Responsible</label>
				<div class="col-md-10">
					<b-form-input id="responsible" v-model="form.responsible" :state="responsibleState" aria-describedby="input-responsible-feedback" placeholder="responsible" trim></b-form-input>
					<b-form-invalid-feedback id="input-responsible-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>
                        
			<div class="row mt-2">
				<label for="description" class="col-md-2 col-form-label">Description</label>
				<div class="col-md-10">
					<b-form-input id="description" v-model="form.taskDescription" :state="descriptionState" aria-describedby="input-description-feedback" placeholder="description" trim></b-form-input>
					<b-form-invalid-feedback id="input-description-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>
            
			<div class="row mt-2">
				<label for="todo" class="col-md-2 col-form-label">To do</label>
				<div class="col-md-10">
					<b-form-input id="todo" v-model="form.toDo" :state="todoState" aria-describedby="input-todo-feedback" placeholder="todo" trim></b-form-input>
					<b-form-invalid-feedback id="input-todo-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>
			
			<div class="row mt-2">
				<label for="plant" class="col-md-2 col-form-label">Plant</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="plant" v-model="form.plant.plantId" :options="plants" :state ="plantState" aria-describedby="input-reason-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-reason-feedback">Select plant</b-form-invalid-feedback>
				</div>
			</div>

        </form>
    </b-modal>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalNewTechnical',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {		
            priority_options: [
				{ value: null, text: 'Please select priority level' },
				{ value: 1, text: 'High' },
				{ value: 2, text: 'Medium' },
				{ value: 3, text: 'Low' }
			],
			status_options: [
				{ value: null, text: 'Please select an option' },
				{ value: 1, text: 'Done' },
				{ value: 2, text: 'Progress' },
				{ value: 3, text: 'Pending' }
			],
			form: {
				deadline: '',
				priority: null,
				responsible: '',
				status: 3,
				taskDescription: '',
				toDo: '',
				plant: { plantId: this.store.plantID }
			},
			show: true,
			plants: [
				{ value: null, text: 'Please select plant' },
			]
        }
    },
    computed: {
		deadlineState() {
			return this.form.deadline.length > 1 ? true : false
		},
		priorityState() {
			return this.form.priority === null ? false : true
		},
		responsibleState() {
			return this.form.responsible === '' ? false : true
		},
		descriptionState() {
			return this.form.taskDescription === '' ? false : true
		},
		todoState() {
			return this.form.toDo === '' ? false : true
		},
		plantState() {
			console.log( isNaN(this.form.plant.plantId) );
			return this.form.plant.plantId > 0 ? true : false
		},
    },
    methods: {
        /* MODAL */
        // Validator function
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
            this.form.deadline = '';
			this.form.priority = null;
			this.form.responsible = '';
			this.form.status = 3;
			this.form.taskDescription = '';
			this.form.toDo = '';
			this.show = false;

			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

			//const today = new Date();
			//const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
			this.form.deadline = this.form.deadline+'T00:00:00'
			
			//console.log(JSON.stringify(this.form));

			TechnicalMgmtService.regTechnical( JSON.stringify(this.form) ).then((response) => {
                //console.log( response.status );   
				if( response.status == 201 ) {
					//get technical after register
					TechnicalMgmtService.getTechnical().then((response) => {
						console.log(response);
						this.$parent.gridApi.setRowData( response.data )
					}).catch((err) => {
						console.log(err);
						this.technical = [{ "deadline": "2022-06-20 02:00:00.000", "priority": "ND", "responsible": "ND", "status": "ND", "toDo": "ND" }];
					});
				}				
            }).catch((err) => {
				console.log(err);
			});

            this.$nextTick(() => {
                this.$bvModal.hide('modal-newTechnical')
            })
        },
		getModalData() {
			TechnicalMgmtService.getPlants( JSON.stringify(this.form) ).then((response) => {
				for (const [key, value] of Object.entries(response.data)) {
					this.plants.push( { value: value.plantId, text: value.name, index: key } );
				} 
            }).catch((err) => {
				console.log(err);
			});
		}		
    },
	created() {
		this.getModalData();
	}
}
</script>