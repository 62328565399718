import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: localStorage.getItem("customLocale"),
    fallbackLocale: 'en',
    messages: {
        en: {
            market:
            {
                    GMEPrices: 'Prices GME',
            },
            profile: {
                Fullname: 'Name',
                ChangePW: 'Change Password',
                password: {
                    OldPassword: 'Old Password',
                    NewPassword: 'New Password',
                    ConfirmPassword: 'Confirm Password',
                    NotMatching: 'Passwords do not match'
                },
            },
            general: {
                Year: "Year",
                Month: "Month",
                Number: "Number",
                Day: "Day",
                Hour: "Hour",
                Save: 'Save',
                Fund: "Fund",
                Funds: "Funds",
                SPVs: "SPVs",
                Date: "Date",
                DueDate: "DueDate",
                Documents: 'Documents',
                TechnicalManagement: 'Technical Management',
                CommercialManagement: 'Commercial Management',
                Failures: 'Failures',
                RegisteredFailures: 'Registered Failures',
                MarketData: 'Market Data',
                ScheduledActivities: 'Scheduled Activities',
                Monitoring: 'Monitoring',
                FieldNotEmpty: 'Field cannot be empty',
                Update: 'Update',
                All: 'All',
                startDate: 'Start date',
                endDate: 'Start date',
                Name: 'Name',
                Description: 'Description',
                Location: 'Location',
                Contact: 'Contact',
                Producer: 'Producer',
                users: 'Users',
                role: 'Role',
                password: 'Password',
                is_active: 'Active',
            },
            plants: {
                Plants: 'Plants',
                Plant: 'Plant',
                Name: "Plant Name",
                Type: "Type",
                GeneralInformation: 'General'
            },
            failures:{
                InsertNewFailure: 'New Failure',
                Reason: 'Reason',
                Setpoint: 'Setpoint',
                StartDate: 'Start Date',
                EndDate: 'End Date',
                AfterStart: 'End has to be after start',
                ThreeHours: "The start has to be at least three hours in the future",
                TXT_FIRE: "Fire",
                TXT_THEFT: "Theft",
                TXT_OTHER: "Other",
                TXT_POWER_FAILURE: "Power failure",
                TXT_WEATHER: "Weather",
                TXT_INVERTER_PROBLEM: "Inverter problem",
                TXT_COMMUNICATION_PROBLEM: "Communication problem",
            },
            tasks: {
                Tasks: "Tasks",
                Type: 'Tipo',
                State: 'Stato'
            },
            invoices: {
                Invoices: "Invoices",
                IssueInvoices: "Issue Invoices",
                ExportDetails: "Export Details",
                DownloadPDF: "Download PDF",
                DownloadXML: "Download XML",
                Publish: "Publish",
                ToPublish: "To Publish",
                Published: "Published",
                Confirmed: "Confirmed by producer",
                Quantity: "Quantity",
                Amount: "Amount",
                DueDate: "Due Date",
                CreateInvoices: "Create Invoices",
                Count: "Count",
                AmountInvoicesCreatable: "Can create",
                Create: "Create",
                CreateSuccess: "Invoices created successfully",
                UpdateNumber: "Update invoice number",
            },
            deals: {
                Deals: "Deals",
                type: "Type of deal",
            },
            meters: {
                Register: 'Register',
                meters: 'Meters',
            },
            dataloggers: {
                dataloggers: 'Dataloggers',
            },
            location: {
                PostalCode: 'Postal code',
                City: 'City',
                Country: 'Country',
            },
            contact: {
                FirstName: 'First name',
                LastName: 'Last name',
                Email: 'Email',
            },
            form: {
                edit: 'Edit',
                submit: 'Save',
                delete: 'Delete',
                cancel: 'Back',
                select: 'Select',
                confirmDelete: 'Are you sure you want to delete this record?',
                new: "New",
                success: "Success",
                error: "Error while performing operation",
            },
            grid: {
                unselect: 'Unselect',
                rowsSelected: 'Rows selected',
            },
            rights: {
                noReadRights: 'The user does not have read rights',
            },
            login: {
                wrongUsernameOrPassword: 'Wrong username or password, please retry',
            },
            errors: {
                fetchingData: 'Error while fetching data',
                missingRights: 'You do not have the rights to view this content',
                sessionInvalid: 'Your session has expired, please log in again',
            }
        },
        de: {
            market:
            {
                    GMEPrices: 'Preise GME',
            },
            profile: {
                Fullname: 'Name',
                ChangePW: 'Passwort ändern',
                password: {
                    OldPassword: 'Altes Passwort',
                    NewPassword: 'Neues Passwort',
                    ConfirmPassword: 'Passwort bestätigen',
                    NotMatching: 'Passwörter stimmen nicht überein'
                },
            },
            general: {
                Year: "Jahr",
                Month: "Monat",
                Day: "Tag",
                Hour: "Stunde",
                Save: 'Speichern',
                Date: "Datum",
                Number: 'Nummer',
                Documents: 'Dokumente',
                GeneralData: 'Allgemein',
                TechnicalManagement: 'Technische Verwaltung',
                CommercialManagement: 'Abrechnung',
                Failures: 'Ausfälle',
                RegisteredFailures: 'Registrierte Ausfälle',
                MarketData: 'Marktdaten',
                ScheduledActivities: 'Geplante Aktivitäten',
                Monitoring: 'Monitoring',
                FieldNotEmpty: 'Pflichtfeld',
                Update: 'Aktualisieren',
                Fund: 'Fond',
                Funds: 'Fonds',
                SPVs: "SPVs",
                All: 'Alle',
                startDate: 'Start-Datum',
                endDate: 'End-Datum',
                Name: 'Name',
                Description: 'Beschreibung',
                Location: 'Ort',
                Contact: 'Kontakt',
                Producer: 'Produzent',
                users: 'Benutzer',
                role: 'Rolle',
                password: 'Passwort',
                is_active: 'Aktiv',
            },
            plants: {
                Plants: 'Anlagen',
                Plant: 'Anlage',
                Name: 'Name Anlage',
                Type: 'Typ',
                GeneralInformation: 'Allgemein'
            },
            tasks: {
                Tasks: 'Aufgaben',
                Type: 'Typ',
                State: 'Status'
            },
            failures:{
                InsertNewFailure: 'Neuer Ausfall',
                Reason: 'Grund',
                Setpoint: 'Erwartete Produktion',
                StartDate: 'Beginn',
                EndDate: 'Ende',
                AfterStart: 'Das Ende muss nach dem Beginn liegen',
                ThreeHours: "Der Ausfall muss mindestens drei Stunden in der Zukunft liegen",
                TXT_COMMUNICATION_PROBLEM: "Kommunikationsproblem",
                TXT_FIRE: "Feuer",
                TXT_THEFT: "Diebstahl",
                TXT_OTHER: "Andere",
                TXT_POWER_FAILURE: "Stromausfall",
                TXT_WEATHER: "Wetter",
                TXT_INVERTER_PROBLEM: "Wechselrichterproblem",
            },
            invoices: {
                Invoices: "Rechnungen",
                IssueInvoices: "Rechnungen generieren",
                ExportDetails: "Details exportieren",
                DownloadXML: "Download XML",
                DownloadPDF: "Download PDF",
                Publish: "Veröffentlichen",
                ToPublish: "Zu veröffentlichen",
                Published: "Veröffentlicht",
                Confirmed: "Vom Produzenten bestätigt",
                Quantity: "Energiemenge",
                Amount: "Betrag",
                DueDate: "Fällig",
                CreateInvoices: "Rechnungen erstellen",
                Count: "Zählen",
                AmountInvoicesCreatable: "Anzahl",
                Create: "Erstellen",
                CreateSuccess: "Rechnungen erfolgreich erstellt",
                UpdateNumber: "Rechnungsnummer aktualisieren",
            },
            deals: {
                Deals: "Deals",
                type: "Typ des Deals",
            },
            meters: {
                Register: 'Matrikel',
                meters: 'Zähler',
            },
            dataloggers: {
                dataloggers: 'Dataloggers',
            },
            location: {
                PostalCode: 'Postleitzahl',
                City: 'Stadt',
                Country: 'Land',
            },
            contact: {
                FirstName: 'Vorname',
                LastName: 'Nachname',
                Email: 'Email',
            },
            form: {
                edit: 'Bearbeiten',
                submit: 'Speichern',
                delete: 'Löschen',
                cancel: 'Zurück',
                select: 'Auswählen',
                confirmDelete: 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?',
                new: "Neu",
                success: "Erfolgreich",
                error: "Fehler beim Ausführen der Operation",
            },
            grid: {
                unselect: 'Deselektieren',
                rowsSelected: 'Zeilen ausgewählt',
            },
            rights: {
                noReadRights: 'Der Nutzer verfügt über keinerlei Leserechte',
            },
            login: {
                wrongUsernameOrPassword: 'Falscher Benutzer oder Password, bitte erneut versuchen',
            },
            errors: {
                fetchingData: 'Fehler beim Abrufen der Daten',
                missingRights: 'Sie haben keine Rechte, um diesen Inhalt anzuzeigen',
                sessionInvalid: 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an',
            }
        },
        it: {
            market:
            {
                    GMEPrices: 'Prezzi GME',
            },
            profile: {
                Fullname: 'Nome',
                ChangePW: 'Aggiorna Password',
                password: {
                    OldPassword: 'Vecchia Password',
                    NewPassword: 'Nuova Password',
                    ConfirmPassword: 'Conferma Password',
                    NotMatching: 'Le Password non corrispondono'
                }

            },
            general: {
                Year: 'Anno',
                Month: "Mese",
                Date: "Data",
                Day: "Giorno",
                Hour: "Ora",
                Number: "Numero",
                Save: 'Salva',
                Documents: 'Documenti',
                TechnicalManagement: 'Gestione Tecnica',
                CommercialManagement: 'Fatture',
                Failures: 'Indisponibilità Impianti',
                RegisteredFailures: 'Indistponibilità Registrate',
                MarketData: 'Dati di Mercato',
                Monitoring: 'Monitoraggio',
                FieldNotEmpty: 'Campo Obbligatorio',
                ScheduledActivities: 'Attività',
                Update: 'Aggiorna',
                Fund: "Fondo",
                Funds: "Fondi",
                SPVs: "SPVs",
                All: 'Tutto',
                startDate: 'Data inizio',
                endDate: 'Data fine',
                Name: 'Nome',
                Description: 'Descrizione',
                Location: 'Luogo',
                Contact: 'Contatto',
                Producer: 'Produttore',
                users: 'Utenti',
                role: 'Ruolo',
                password: 'Password',
                is_active: 'Attivo',
            },
            plants: {
                Plants: 'Impianti',
                Plant: 'Impianto',
                Name: 'Nome Impianto',
                Type: 'Tipo',
                GeneralInformation: 'Generale'
            },
            tasks: {
                Tasks: 'Compiti',
                Type: 'Tipo',
                State: 'Stato'
            },
            failures:{
                InsertNewFailure: 'Nuova indisponibilità',
                Reason: 'Raggione',
                Setpoint: 'Produzione aspettata',
                StartDate: 'Inizio',
                EndDate: 'Fine',
                AfterStart: "Fine deve essere dopo l'inizio",
                ThreeHours: "L'inizio deve essere tre ore dopo adesso",
                TXT_FIRE: "Incendio",
                TXT_THEFT: "Furto",
                TXT_OTHER: "Altro",
                TXT_POWER_FAILURE: "Interruzione di corrente",
                TXT_WEATHER: "Meteo",
                TXT_INVERTER_PROBLEM: "Problema inverter",
                TXT_COMMUNICATION_PROBLEM: "Problema di comunicazione",
            },
            invoices: {
                Invoices: "Fatture",
                IssueInvoices: "Genera Fatture",
                ExportDetails: "Dettagli",
                DownloadXML: "Scarica XML",
                DownloadPDF: "Scarica PDF",
                Publish: "Pubblica",
                ToPublish: "Da pubblicare",
                Published: "Pubblicato",
                Confirmed: "Confermato dal fornitore",
                DueDate: "Data di scadenza",
                Quantity: "Quantità",
                Amount: "Importo",
                CreateInvoices: "Crea Fatture",
                Count: "Contare",
                AmountInvoicesCreatable: "Numero",
                Create: "Crea",
                CreateSuccess: "Fatture create con successo",
                UpdateNumber: "Aggiorna numero fattura",
            },
            deals: {
                Deals: "Deals",
                type: "Tipo di deal",
            },
            meters: {
                Register: 'Matricola',
                meters: 'Contatori',
            },
            dataloggers: {
                dataloggers: 'Dataloggers',
            },
            location: {
                PostalCode: 'Codice postale',
                City: 'Città',
                Country: 'Paese',
            },
            contact: {
                FirstName: 'Nome',
                LastName: 'Cognome',
                Email: 'Email',
            },
            form: {
                edit: 'Modifica',
                submit: 'Salva',
                delete: 'Cancella',
                cancel: 'Indietro',
                select: 'Seleziona',
                confirmDelete: 'Sei sicuro di voler cancellare?',
                new: "Nuovo",
                success: "Successo",
                error: "Errore durante l'operazione",
            },
            grid: {
                unselect: 'Deseleziona',
                rowsSelected: 'Righe selezionate',
            },
            rights: {
                noReadRights: 'L\'utente non ha i diritti di lettura',
            },
            login: {
                wrongUsernameOrPassword: 'Nome utente o password errata, prego riprovare',
            },
            errors: {
                fetchingData: 'Errore durante il recupero dei dati',
                missingRights: 'Non hai i diritti per visualizzare questi contenuti',
                sessionInvalid: 'La sessione è scaduta, per favore effettua nuovamente il login',
            }
        }
    }
})