<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/dashboard/plants">Plants</b-breadcrumb-item>
					<!-- <b-breadcrumb-item active>{{ data.name }}</b-breadcrumb-item> -->
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">
					<h1 class="page-header">
						Plant details
					</h1>

					<hr class="mb-4" />
				</b-col>
				<b-col xl="12" class="card">
					<Form
						class="card-body"
						api-object-name="Plants"
						:api-object-id="id"
					/>
				</b-col>

			</b-row>

			<hr class="mb-4" />

		</b-container>
		<!-- BEGIN modals -->

		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

// import moment from 'moment';
import Form from '../components/Form.vue';

export default {	
	name: 'PlantDetails',
	components: {
		Form,
	},
	data() {
		return {
			data: null,
		};
	},
	props: {
		id: {
			type: Number,
			required: true
		}
	},
}
</script>
