<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">Dashboard</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ $t('general.ScheduledActivities') }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="10">

					<b-row>
						<b-col><h2 class="page-header">{{ $t('general.ScheduledActivities') }}</h2></b-col>
						<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right"><a v-b-modal.modal-newScheduled><i class="fas fa-2xl fa-fw me-2 fa-plus-square"></i></a></b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">	
							<div style="height: 100%">			
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:columnDefs="columnDefs"
									:rowData="scheduled"
									:rowSelection="rowSelection"
									:pagination="true"
									:paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered"	
									@selection-changed="onSelectionChanged"													
								/>
							</div>
						</b-col>	
					</b-row>

				</b-col>
			</b-row>
		</b-container>
		<!-- BEGIN modals -->
		<ModalNewScheduled />
		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import ModalNewScheduled from '../components/ModalNewScheduled';
import { defaultStore } from '../_store/storage';
import moment from 'moment'


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
	name: 'Scheduled-activities',
	setup() {
		const store = defaultStore()
		return { store}
	},
	data() {
		return {
			scheduled: [],
			columnDefs: [
				{ field: 'activityId', sortable: true, filter: true, minWidth: 50, maxWidth: 120, suppressSizeToFit: true,},				
				{ headerName: 'Plant', field: 'plantDto.plantName', sortable: true, filter: true, minWidth: 50, maxWidth: 120, suppressSizeToFit: true,},
				{ headerName: 'Programmed', field: 'startDate', sortable: true, filter: true, minWidth: 100, maxWidth: 150, suppressSizeToFit: true, valueFormatter: this.shorten },
				{ field: 'responsible', sortable: true, filter: true, minWidth: 100, maxWidth: 150 },
				{ field: 'toDo', sortable: true, filter: true, width: 150, }
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: {
				resizable: true,
				flex: 1,
				minWidth: 100
			},
			rowData: null,
		}
	},
	components: {
		ModalNewScheduled,
		AgGridVue,
	},
	methods: {	
		shorten(params) {
			//console.log( params );
			return moment(String(params.value)).format('DD/MM/YYYY');
		},
		priority(params) {
			//console.log(str)
			switch(params.value) {
				default:
					"0";
					break;
				case "1":
					return 'High';
				case "2":
					return 'Medium';
				case "3":
					return 'Low';
			}
		},
		status(params) {
			//console.log(str)
			switch(params.value) {
				default:
					"0";
					break;
				case "1":
					return 'Done';
				case "2":
					return 'Progress';
				case "3":
					return 'Pending';
			}
		},
		getScheduled() {
			console.log('Get scheduled activities list:');
			this.store.setPlantID(null);

			TechnicalMgmtService.getScheduled().then((response) => {
				console.log(response);
				this.scheduled = response.data;
			}).catch((err) => {
				console.log(err);
				this.scheduled = [{ "deadline": "2022-06-20 02:00:00.000", "responsible": "ND", "toDo": "ND" }];
			});
		},
		openDetails(id) {
			//this.store.setPlantID(id);
			window.location.href = window.location.origin + "/dashboard/scheduled-activities/" + id
			// axios.delete('https://your.rest.api/users/' + id)
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsString = '';
			var maxToShow = 5;
			selectedRows.forEach(function (selectedRow, index) {
				if (index >= maxToShow) {
					return;
				}
				if (index > 0) {
					selectedRowsString += ', ';
				}
				selectedRowsString += selectedRow.activityId;
			});
			if (selectedRows.length > maxToShow) {
				var othersCount = selectedRows.length - maxToShow;
				selectedRowsString +=
				' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
			}
			console.log(selectedRowsString);
			this.openDetails(selectedRowsString);
			//document.querySelector('#selectedRows').innerHTML = selectedRowsString;
		},
		onGridReady(params) {
			console.log('grid ready');
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
		},
	},
	created() {
		console.log( 'Scheduled activities: init' );
		this.getScheduled();
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;
	}
}

</script>


