<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">Fulfillments</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="10">

					<b-row>
						<b-col><h2 class="page-header">Fulfillments</h2></b-col>
					</b-row>

					<hr class="mb-4" />

				</b-col>
			</b-row>
		</b-container>
		<!-- BEGIN modals -->

		<!-- END modals -->
	</div>
</template>

<script>

import { defaultStore } from '../_store/storage';

export default {
	name: 'Fulfillments',
	setup() {
		const store = defaultStore()
		return { store}
	},
	components: {

	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
		}
	},
	methods: {
		
	},
	created() {

	}
}
</script>
