<script>
import Dashboard from '../pages/Dashboard.vue'
import DashboardPlants from '../pages/DashboardPlants.vue'
import DashboardPlantDetails from '../pages/DashboardPlantDetails.vue'
import Fulfillments from '../pages/Fulfillments.vue'
import PlantElectricMeters from '../pages/PlantElectricMeters.vue'
import DashboardTechnicalActivities from '../pages/DashboardTechnicalActivities.vue'
import TechnicalActivityDetails from '../pages/TechnicalActivityDetails.vue'
import AddTechnicalActivity from '../pages/AddTechnicalActivity.vue'
import Tasks from '../pages/Tasks.vue'
import AddAdministrativeActivity from '../pages/AddAdministrativeActivity.vue'
import TaskDetails from '../pages/TaskDetails.vue'
import DashboardScheduledActivities from '../pages/DashboardScheduledActivities.vue'
import ScheduledActivityDetails from '../pages/ScheduledActivityDetails.vue'
/**Documents */
import DocumentsGeneralData from '../pages/DocumentsGeneralData.vue'
import DocumentsSecurity from '../pages/DocumentsSecurity.vue'
import DocumentsOmAvailability from '../pages/DocumentsFilesList.vue'
import DocumentsLoadGraphs from '../pages/DocumentsFilesList.vue'
import DocumentsInsurance from '../pages/DocumentsInsurance.vue'
import DocumentsMaintenance from '../pages/DocumentsFilesList.vue'
import DocumentsReports from '../pages/DocumentsFilesList.vue'
import DocumentsSiteVisit from '../pages/DocumentsFilesList.vue'
import DocumentsTlc from '../pages/DocumentsFilesList.vue'
import DocumentsArera from '../pages/DocumentsArera.vue'
import DocumentsStacking from '../pages/DocumentsStacking.vue'
import DocumentsDomiciliation from '../pages/DocumentsDomiciliation.vue'

/** */
import MonitoringDataLoggers from '../pages/MonitoringDataLoggers.vue'
import MonitoringDataLoggerDetails from '../pages/MonitoringDataLoggerDetails.vue'
import MonitoringElectricMeters from '../pages/MonitoringElectricMeters.vue'
import MonitoringElectricMeterDetails from '../pages/MonitoringElectricMeterDetails.vue'
import CmngmInvoices from '../pages/CmngmInvoices.vue'
import CmngmPlantDeals from '../pages/CmngmPlantDeals.vue'
import CmngmFunds from '../pages/CmngmFunds.vue'
import CmngmSPVs from '../pages/CmngmSPVs.vue'
import CmngmPlantFailures from '../pages/CmngmPlantFailures.vue'
import CmngmPlantFailureDetails from '../pages/CmngmPlantFailureDetails.vue'
import PageScrumBoard from '../pages/PageScrumBoard.vue'
import PageSearchResults from '../pages/PageSearchResults.vue'
import PageError from '../pages/PageError.vue'
import PageLogin from '../pages/PageLogin.vue'
import PageRegister from '../pages/PageRegister.vue'
import Profile from '../pages/Profile.vue'
import Settings from '../pages/Settings.vue'
import MarketDataGME from '../pages/MarketDataGME.vue'
import FormPage from '../pages/FormPage.vue';
import GridPage from '../pages/GridPage.vue';
import InvoicePage from '../pages/InvoicePage.vue';
import PlantFailuresForm from '../pages/PlantFailuresForm.vue'
import UsersForm from '../pages/UsersForm.vue'

const routes = [
	{ path: '*', redirect: '/user/login' },
  { path: '/dashboard', component: Dashboard },
  {
    path: '/forms/:entityName/:id?',
    component: FormPage,
    props: route => ({
      entityName: route.params.entityName,
      id: parseInt(route.params.id),
      isEdit: route.query.isEdit === 'true',
    })
  },
  {
    path: '/grids/:entityName',
    component: GridPage,
    props: route => ({
      entityName: route.params.entityName,
      isSelect: route.query.isSelect === 'true',
      selectEntityName: route.query.selectEntityName,
      selectEntityId: parseInt(route.query.selectEntityId),
      selectEntityColumn: route.query.selectEntityColumn,
    }),
  },
  {
    path: '/invoices',
    component: InvoicePage,
  },
  {
    path: '/plant-failures/form/:id?',
    name: 'PlantFailureForm',
    component: PlantFailuresForm,
    props: route => ({
      id: parseInt(route.params.id),
    })
  },
  {
    path: '/users/form/:id?',
    name: 'UsersForm',
    component: UsersForm,
    props: route => ({
      id: parseInt(route.params.id),
    })
  },
  { path: '/dashboard/plants', component: DashboardPlants },
  { path: '/dashboard/plant', component: DashboardPlantDetails },
  {
    path: '/dashboard/plant/:id?',
    name: 'PlantDetails',
    component: DashboardPlantDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  {
    path: '/dashboard/plant/:id?/electric-meters',
    name: 'ElectricMeters',
    component: PlantElectricMeters,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  { path: '/dashboard/technical-activities', component: DashboardTechnicalActivities },
  {
    path: '/dashboard/technical-activities/:id?',
    name: 'TechnicalActivityDetails',
    component: TechnicalActivityDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  {
    path: '/dashboard/technical-activities/add/:id?',
    name: 'AddTechnicalActivity',
    component: AddTechnicalActivity,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  { path: '/dashboard/tasks', component: Tasks },
  {
    path: '/dashboard/tasks/:id?',
    name: 'TaskDetails',
    component: TaskDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  {
    path: '/dashboard/tasks/add/:id?',
    name: 'AddAdministrativeActivity',
    component: AddAdministrativeActivity,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  { path: '/fulfillments', component: Fulfillments },
  //commercial management
  { path: '/invoices/monthly-invoices', component: CmngmInvoices },
  { path: '/invoices/deals-managing', component: CmngmPlantDeals },
  { path: '/invoices/funds', component: CmngmFunds },
  { path: '/invoices/spvs', component: CmngmSPVs },
  { path: '/plant-failures/failures-managing', component: CmngmPlantFailures },
  {
    path: '/plant-failures/failure/:id?',
    name: 'FailureDetails',
    component: CmngmPlantFailureDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  { path: '/dashboard/scheduled-activities', component: DashboardScheduledActivities },//ScheduledActivityDetails
  {
    path: '/dashboard/scheduled-activities/:id?',
    name: 'ScheduledActivityDetails',
    component: ScheduledActivityDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  //monitoring
  { path: '/monitoring/data-loggers', component: MonitoringDataLoggers },
  {
    path: '/monitoring/dataLogger/:id?',
    name: 'DataloggerDetails',
    component: MonitoringDataLoggerDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  { path: '/monitoring/electric-meters', component: MonitoringElectricMeters },
  {
    path: '/monitoring/electric-meter/:id?',
    name: 'ElectricMeterDetails',
    component: MonitoringElectricMeterDetails,
    props: (route) => {
        const id = Number.parseInt(route.params.id);
        return { id }
      },
  },
  //documents
  { path: '/documents/general-data', component: DocumentsGeneralData },
  { path: '/documents/security', component: DocumentsSecurity },
  { path: '/documents/insurance', component: DocumentsInsurance },
  { path: '/documents/om/availabilty', component: DocumentsOmAvailability },
  { path: '/documents/om/load-graphs', component: DocumentsLoadGraphs },
  { path: '/documents/om/maintenance', component: DocumentsMaintenance },
  { path: '/documents/om/reports', component: DocumentsReports },
  { path: '/documents/om/site-visit', component: DocumentsSiteVisit },
  { path: '/documents/om/tlc', component: DocumentsTlc },
  { path: '/documents/arera', component: DocumentsArera },  
  { path: '/documents/stacking', component: DocumentsStacking }, 
  { path: '/documents/domiciliation', component: DocumentsDomiciliation }, 
  
  //
  { path: '/market-data/gme-prices', component: MarketDataGME },

  { path: '/page/scrum-board', component: PageScrumBoard },
  { path: '/page/search-results', component: PageSearchResults },
  { path: '/page/error', component: PageError },
  { path: '/user/login', component: PageLogin },
  { path: '/user/register', component: PageRegister },
  { path: '/user/profile', component: Profile },
  { path: '/settings', component: Settings },
]

export default routes;
</script>../pages/FormPage.vue
