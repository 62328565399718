<template>
    <b-modal id="modal-monthlyInvoicesGenerate" centered ref="modal" size="lg" title="Generate Invoices" ok-title="Start" ok-variant="success" cancel-title="Exit" cancel-variant="danger" @show="openModal" @hidden="closeModal" @ok="handleOk">

        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="row mt-2 justify-content-between">
                <label for="yearSelect" class="col-sm-2 col-form-label">Year:</label>
                <div class="col-md-5">
                    <b-form-select id="yearSelect" class="form-select" v-model="form.migYear" :options="yearOptions" @change="getDealsWithoutInvoice()"></b-form-select>
                </div>
            </div>

            <div class="row mt-2 justify-content-between">
                <label for="monthSelect" class="col-sm-2 col-form-label">Month:</label>
                <div class="col-md-5">
                    <b-form-select id="monthSelect" class="form-select" v-model="form.migMonth" :options="monthOptions" @change="getDealsWithoutInvoice()"></b-form-select>
                </div>
            </div>
            
            <div class="row mt-2 justify-content-between">
                <label for="fondSelect" class="col-sm-2 col-form-label">Fond:</label>
                <div class="col-md-8">
                    <b-form-select id="fondSelect" class="form-select" v-model="form.migFond" :options="fondOptions" @change="changeFond()" trim></b-form-select>
                </div>
            </div>

            <div class="row mt-2 justify-content-between">
                <label for="spvSelect" class="col-sm-2 col-form-label">SPV:</label>
                <div class="col-md-8">
                    <b-form-select id="spvSelect" class="form-select" v-model="form.migSPV" :options="spvOptions" @change="changeSPV()" trim></b-form-select>
                </div>
            </div>

            <div class="row mt-2 justify-content-between">
                <label for="plantSelect" class="col-sm-2 col-form-label">Plant:</label>
                <div class="col-md-5">
                    <b-form-select id="plantSelect" class="form-select" v-model="form.migPlant" :options="plantOptions" @change="changePlant()" trim></b-form-select>
                </div>
            </div>

            <div class="row mt-4 mb-2">
                <div class="col-sm">Invoices to generate: {{form.migInvoicesToGenerate}}</div>
            </div>

            <b-form-textarea ref="migInvoiceLog" class="border border-dark rounded" rows="8" plaintext></b-form-textarea>

        </form>

    </b-modal>
</template>

<script>

import CommercialMgmtService from '../_services/CommercialMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalMonthlyInvoicesGenerate',
    setup() {
        const store = defaultStore()
        return { store}
    },
    component: {
    },
    data() {
        let yearOptions = [];
        let ts = new Date();
        for (let y = 2018; y <= ts.getFullYear(); y++) {
            yearOptions.push({ value: y, index: y, text: String(y) });
        }
        let monthOptions = [
            { value: 1, text: "January" },
            { value: 2, text: "February" },
            { value: 3, text: "March" },
            { value: 4, text: "April" },
            { value: 5, text: "May" },
            { value: 6, text: "June" },
            { value: 7, text: "July" },
            { value: 8, text: "August" },
            { value: 9, text: "September" },
            { value: 10, text: "October" },
            { value: 11, text: "November" },
            { value: 12, text: "December" }
        ];
        return {
            form: {
                migYear: ts.getMonth() == 0 ? ts.getFullYear() - 1 : ts.getFullYear(),
                migMonth: ts.getMonth() == 0 ? 12 : ts.getMonth(),
                migFond: null,
                migSPV: null,
                migPlant: null,
                migInvoicesToGenerate: '0'
            },
            yearOptions,
            monthOptions,
            fondOptions: [],
            spvOptions: [],
            plantOptions: [],
            dealIDs: [],
            fonds: [],
            spvs: [],
            plants: []
        }
    },
    methods: {
        addLineToTextArea(text) {
            let ta = this.$refs.migInvoiceLog.$el;
            ta.value += text + "\n";
            ta.scrollTop = ta.scrollHeight;
        },
        getFormValues(event) {
            event.preventDefault();
            // console.log(JSON.stringify(this.form));
        },
        openModal() {
            this.refreshOptions();
        },
        closeModal() {
            let ts = new Date();
            this.form.migYear = ts.getMonth() == 0 ? ts.getFullYear() - 1 : ts.getFullYear();
            this.form.migMonth = ts.getMonth() == 0 ? 12 : ts.getMonth();
            this.form.migFond = null;
            this.form.migSPV = null;
            this.form.migPlant = null;
            this.form.migInvoicesToGenerate = '0';
            this.dealIDs = [];
        },
        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();

            if (this.dealIDs.length <= 0) {
                this.addLineToTextArea("No invoices to generate.");
            }
            else {
                this.migInvoiceLog = '';
                this.addLineToTextArea("Invoice generation started.\n");
                
                for (let d = 0; d < this.dealIDs.length; d++) {
                    let result = await this.$parent.calculateInvoice(this.dealIDs[d]);
                    if (result[0]) {
                        this.addLineToTextArea(String(d + 1) + "/" + String(this.dealIDs.length) + " deal '" + this.dealIDs[d] + "': sucess.");
                    }
                    else {
                        this.addLineToTextArea(String(d + 1) + "/" + String(this.dealIDs.length) + " deal '" + this.dealIDs[d] + "': error -> " + result[1] + ".");
                    }
                }

                this.addLineToTextArea("\nInvoice generation finished.\n");
            }

            // this.$nextTick(() => {
            //     this.$bvModal.hide('modal-monthlyInvoicesGenerate');
            // })
        },
        refreshOptions(type) {
            if (type == null) {
                this.fondOptions = [ {value: null, text: 'No Fond selected', index: -1} ];
                this.spvOptions = [ {value: null, text: 'No SPV selected', index: -1} ];
                this.plantOptions = [ {value: null, text: 'No Plant selected', index: -1} ];

                for (const [key, value] of Object.entries(this.fonds)) {
                    this.fondOptions.push( { value: value.fondId, text: value.name, index: key } );
                }
                for (const [key, value] of Object.entries(this.spvs)) {
                    this.spvOptions.push( { value: value.spvId, text: value.name, index: key } );
                }
                for (const [key, value] of Object.entries(this.plants)) {
                    this.plantOptions.push( { value: value.plantId, text: value.name + " - " + value.sapr, index: key } );
                }

                // TEST
                // this.form.migMonth = 4;
                // this.form.migPlant = 268;
            }
            else if (type == 3) {
                if (this.form.migPlant != null) {
                    this.form.migFond = null;
                    this.fondOptions = [ {value: null, text: 'No Fond selected', index: -1} ];
                    this.form.migSPV = null;
                    this.spvOptions = [ {value: null, text: 'No SPV selected', index: -1} ];
                    for (let p = 0; p < this.plants.length; p++) {
                        if (this.plants[p].plantId == this.form.migPlant) {
                            let fondIds = [];
                            for (const [key, value] of Object.entries(this.spvs)) {
                                if (value.spvId == this.plants[p].spvId) {
                                    if (value.fond) fondIds.push(value.fond.fondId);
                                    this.spvOptions.push( { value: value.spvId, text: value.name, index: key } );
                                    this.form.migSPV = value.spvId;
                                }
                            }
                            for (const [key, value] of Object.entries(this.fonds)) {
                                if (fondIds.includes(value.fondId)) {
                                    this.fondOptions.push( { value: value.fondId, text: value.name, index: key } );
                                    this.form.migFond = value.fondId;
                                }
                            }
                        }
                    }
                }
                else {
                    this.plantOptions = [ {value: null, text: 'No Plant selected', index: -1} ];
                    for (const [key, value] of Object.entries(this.plants)) {
                        if (value.spvId == this.form.migSPV) {
                            this.plantOptions.push( { value: value.plantId, text: value.name + " - " + value.sapr, index: key } );
                        }
                    }
                }
            }
            else if (type == 2) {
                if (this.form.migSPV != null) {
                    this.form.migFond = null;
                    this.form.migPlant = null;
                    this.fondOptions = [ {value: null, text: 'No Fond selected', index: -1} ];
                    this.plantOptions = [ {value: null, text: 'No Plant selected', index: -1} ];
                    for (let s = 0; s < this.spvs.length; s++) {
                        if (this.form.migSPV == this.spvs[s].spvId) {
                            for (const [key, value] of Object.entries(this.fonds)) {
                                if (this.spvs[s].fond && this.spvs[s].fond.fondId == value.fondId) {
                                    this.fondOptions.push( { value: value.fondId, text: value.name, index: key } );
                                    this.form.migFond = value.fondId;
                                }
                            }
                        }
                    }
                    for (const [key, value] of Object.entries(this.plants)) {
                        if (value.spvId == this.form.migSPV) {
                            this.plantOptions.push( { value: value.plantId, text: value.name + " - " + value.sapr, index: key } );
                        }
                    }
                }
                else {
                    this.spvOptions = [ {value: null, text: 'No SPV selected', index: -1} ];
                    let spvIds = [];
                    for (const [key, value] of Object.entries(this.spvs)) {
                        if (value.fond && value.fond.fondId == this.form.migFond) {
                            spvIds.push(value.spvId);
                            this.spvOptions.push( { value: value.spvId, text: value.name, index: key } );
                        }
                    }
                    this.plantOptions = [ {value: null, text: 'No Plant selected', index: -1} ];
                    for (const [key, value] of Object.entries(this.plants)) {
                        if (spvIds.includes(value.spvId)) {
                            this.plantOptions.push( { value: value.plantId, text: value.name + " - " + value.sapr, index: key } );
                        }
                    }
                    this.form.migPlant = null;
                }
            }
            else if (type == 1) {
                if (this.form.migFond != null) {
                    this.form.migSPV = null;
                    this.form.migPlant = null;
                    this.spvOptions = [ {value: null, text: 'No SPV selected', index: -1} ];
                    this.plantOptions = [ {value: null, text: 'No Plant selected', index: -1} ];
                    let spvIds = [];
                    for (const [key, value] of Object.entries(this.spvs)) {
                        if (value.fond && value.fond.fondId == this.form.migFond) {
                            spvIds.push(value.spvId);
                            this.spvOptions.push( { value: value.spvId, text: value.name, index: key } );
                        }
                    }
                    for (const [key, value] of Object.entries(this.plants)) {
                        if (spvIds.includes(value.spvId)) {
                            this.plantOptions.push( { value: value.plantId, text: value.name + " - " + value.sapr, index: key } );
                        }
                    }
                }
                else {
                    this.fondOptions = [ {value: null, text: 'No Fond selected', index: -1} ];
                    for (const [key, value] of Object.entries(this.fonds)) {
                        this.fondOptions.push( { value: value.fondId, text: value.name, index: key } );
                    }
                    this.spvOptions = [ {value: null, text: 'No SPV selected', index: -1} ];
                    for (const [key, value] of Object.entries(this.spvs)) {
                        this.spvOptions.push( { value: value.spvId, text: value.name, index: key } );
                    }
                    this.plantOptions = [ {value: null, text: 'No Plant selected', index: -1} ];
                    for (const [key, value] of Object.entries(this.plants)) {
                        this.plantOptions.push( { value: value.plantId, text: value.name + " - " + value.sapr, index: key } );
                    }
                    this.form.migSPV = null;
                    this.form.migPlant = null;
                }
            }
            this.getDealsWithoutInvoice();
        },
        changeFond() {
            this.refreshOptions(1);
        },
        changeSPV() {
            this.refreshOptions(2);
        },
        changePlant() {
            this.refreshOptions(3);
        },
        async loadModalData() {
            const nameComparison = (objectA, objectB) => { if (objectA.name < objectB.name) return -1; if (objectA.name > objectB.name) return 1; return 0; };

            let res = await CommercialMgmtService.getFonds();
            if (res.data) this.fonds = res.data;
            this.fonds.sort(nameComparison);

            res = await CommercialMgmtService.getSPVs();
            if (res.data) this.spvs = res.data;
            this.spvs.sort(nameComparison);

            res = await CommercialMgmtService.getPlants();
            if (res.data) this.plants = res.data;
            this.plants.sort(nameComparison);

            this.refreshOptions();
        },
        getDealsWithoutInvoice() {
            CommercialMgmtService.getDealsWithoutInvoice({ "year": this.form.migYear, "month": parseInt(this.form.migMonth), "fond_id": this.form.migFond, "spv_id": this.form.migSPV, "plant_id": this.form.migPlant }).then((response) => {
                this.dealIDs = response.data;
                this.form.migInvoicesToGenerate = String(this.dealIDs.length);
            }).catch((err) => {
                console.log(err);
            });
        },
    },
    created() {
        this.loadModalData();
    }
}
</script>