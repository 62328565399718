<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/dashboard/plants">Dashboard</b-breadcrumb-item>
					<b-breadcrumb-item :to="{ path: '/dashboard/plant/'+this.plantId }">{{ plant.name }}</b-breadcrumb-item>
					<b-breadcrumb-item active>Electric meters</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="10">

					<h1 class="page-header">
						Electric meters for {{ plant.name }} ({{ plant.plantId }}) plant
					</h1>

					<hr class="mb-4" />

					<table class="table table-striped">
						<thead>
							<tr>
								<th>CE Number</th>
								<th>Plant ID</th>
								<th>Logger ID</th>
								<th>actions</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="ce in celist" v-bind:key="ce.ce_id" @click="plantDetails(plant.plantId)">
								<td> {{ ce.ceNumber }}</td>
								<td> {{ ce.plantId }}</td>
								<td> {{ ce.loggerId }}</td>
								<td> <a @click="openDetails(row.plantId)"><i class="fas fa-lg fa-fw me-2 fa-folder-open"></i></a> </td>
							</tr>
						</tbody>
					</table>

				</b-col>
			</b-row>
		</b-container>
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';

export default {
	name: 'ElectricMeters',
	setup() {
		const store = defaultStore()
		return { store}
	},
	data() {
		return {
			plant: [],
			celist: [{'ceNumber':'ND', 'plantId':'ND', 'loggerId':'ND'}],
			plantId: this.$route.params.id
		}
	},
	methods: {
		getPlantDetails() {
			console.log('Get electric meters for: ' + this.plantId);

			TechnicalMgmtService.getPlantDetails( this.plantId ).then((response) => {
				this.plant = response.data;
				if(this.plant.electricMeters.length != 0) {
					this.celist = this.plant.electricMeters;	
				}				
			}).catch((err) => {
				console.log(err);
			});
		},
		checkID() {
			let url = this.$route;
			//let regex = new RegExp('\\b' + affiliation.split(/\s+or\s+/i).join('\\b|\\b') + '\\b', 'i');
			console.log( url );
			return 1;
		},
		plantDetails(id) {
			this.store.setPlantID(id);
			window.location.href = window.location.origin + "/dashboard/plant/" + id
			// axios.delete('https://your.rest.api/users/' + id)
		}
	},
	created() {
		console.log('page created, init:');
		this.getPlantDetails();
	}
}
</script>
