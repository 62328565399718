import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const defaultStore = defineStore('storage', {
  state: () => ({
    plantID: useStorage('plantID', null),
    loggerID: useStorage('loggerID', null),
    technicalActivityID: useStorage('technicalActivityID', null),
    administrativeActivityID: useStorage('administrativeActivityID', null),
  }),
  getters: {
    getPlantID: (state) => state.plantID,
    getLoggerID: (state) => state.loggerID,
    getTechnicalActivityID: (state) => state.technicalActivityID,
    getAdministrativeActivityID: (state) => state.administrativeActivityID
  },
  actions: {
    /**
     * Set plantID
     * @param {int} plantID
     * @param {int} loggerID
     */
    setPlantID(plantID) {
      this.plantID = plantID
    },
    setLoggerID(loggerID) {
      this.loggerID = loggerID
    },
    setTechnicalActivityID(technicalActivityID) {
      this.technicalActivityID = technicalActivityID
    },
    setAdministrativeActivityID(administrativeActivityID) {
      this.administrativeActivityID = administrativeActivityID
    },
  }
})