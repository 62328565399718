<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">Monitoring</b-breadcrumb-item>
					<b-breadcrumb-item active>Data Loggers</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="10">

					<b-row>
						<b-col><h2 class="page-header">Data loggers</h2></b-col>
					</b-row>

					<hr class="mb-4" />
					
					<b-row>
						<b-col xl="12">	
							<div style="height: 100%">			
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:columnDefs="columnDefs"
									:rowData="dataloggers"
									:rowSelection="rowSelection"
									:pagination="true"
									:paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered"	
									@selection-changed="onSelectionChanged"										
									rowModelType="serverSide"
									:cacheBlockSize="cacheBlockSize"
									@grid-size-changed="resizeColumns"			
								/>
							</div>
						</b-col>	
					</b-row>

				</b-col>
			</b-row>
		<!-- BEGIN modals -->
		<ModalNewDataLogger />
		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import ModalNewDataLogger from '../components/ModalNewDataLogger';
import { defaultStore } from '../_store/storage';


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
	name: 'Dataloggers',
	setup() {
		const store = defaultStore()
		return { store}
	},
	components: {
		ModalNewDataLogger,
		AgGridVue
	},
	data() {
		return {
			dataloggers: [],
			columnDefs: [
				{ headerName: this.$t('general.Name'), field: 'name', sortable: true, filter: true },
				{ headerName: this.$t('plants.Name'), field: 'plant_name', sortable: true, filter: true },
				{ headerName: 'SAPR', field: 'plant_SAPR', sortable: true, filter: true },
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: {
				resizable: true,
				flex: 1,
				minWidth: 100
			},
			rowData: null,
		}
	},
	methods: {
		getDataloggers() {
			console.log('Get dataloggers list:');
			this.store.setPlantID(null);

			TechnicalMgmtService.getDataloggers().then((response) => {
				console.log(response);
				this.dataloggers = response.data;
			}).catch((err) => {
				console.log(err);
				this.dataloggers = [{ "ipAddress": "ND", "apiAddress": "ND", "name": "ND" }];
			});
		},
		openDetails(id) {
			//this.store.setPlantID(id);
			window.location.href = window.location.origin + "/monitoring/dataLogger/" + id
			// axios.delete('https://your.rest.api/users/' + id)
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsString = '';
			var maxToShow = 5;
			selectedRows.forEach(function (selectedRow, index) {
				if (index >= maxToShow) {
					return;
				}
				if (index > 0) {
					selectedRowsString += ', ';
				}
				selectedRowsString += selectedRow.loggerId;
			});
			if (selectedRows.length > maxToShow) {
				var othersCount = selectedRows.length - maxToShow;
				selectedRowsString += ' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
			}
			//console.log(selectedRowsString[0]);
			this.openDetails(selectedRowsString);
			//document.querySelector('#selectedRows').innerHTML = selectedRowsString;
		},
		onGridReady(params) {
			const dataSource = {
				getRows: async params => {
					try {
						const response = await TechnicalMgmtService.getDataloggers(params.request);
						params.success({
							rowData: response.data.data,
							rowCount: response.data.count,
						});
					}
					catch (e) {
						console.error('error while fetching plant data', e);
						params.fail();
					}
				}
			};			
			this.gridApi = params.api;
			this.gridApi.setServerSideDatasource(dataSource);
			this.columnApi = params.columnApi;
		},
		resizeColumns() {
			this.gridApi.sizeColumnsToFit();
		},
	},
	created() {
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;
		this.cacheBlockSize = 100;
	}
}
</script>
