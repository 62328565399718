<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/monitoring/data-loggers">Dataloggers</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ logger.name }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">

					<h1 class="page-header">
						Datalogger details
					</h1>

					<hr class="mb-4" />

					<b-card :header="'nome: '+logger.name">
						<b-card-text class="card-text mb-3"></b-card-text>

						<b-list-group class="border-0" flush>
							<b-list-group-item><small>IP address: </small>{{ logger.ipAddress }}</b-list-group-item>
							<b-list-group-item><small>API address: </small>{{ logger.apiAddress }}</b-list-group-item>
						</b-list-group>

					</b-card>

					<hr class="mb-4" />

					<b-row>
						<b-col><h2 class="page-header">Stats from {{ dates.start }} to {{ dates.end }}</h2></b-col>
						<b-col md="6" sm="6" xs="6" class="mb-1">
							<form ref="form" @submit.stop.prevent="handleSubmit">
								<b-row>
									<b-col>
										<label for="start-date-input" class="col-md-2 col-form-label">Start</label>
										<b-form-datepicker id="start-date-input" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.start" aria-describedby="input-start-feedback" placeholder="start" trim></b-form-datepicker>
									</b-col>
									<b-col>
										<label for="end-date-input" class="col-md-2 col-form-label">End</label>
										<b-form-datepicker id="end-date-input" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.end" aria-describedby="input-end-feedback" placeholder="end" trim></b-form-datepicker>
									</b-col>
								</b-row>
							</form>
						</b-col>
					</b-row>

					<b-row>
						<apexchart height="300px" ref="realtimeChart" :options="chartOptions" :series="series"></apexchart>
					</b-row>

					<b-row>
						<b-col><h4>Electric Meters</h4></b-col>
					</b-row>
					
					<b-row>
						<b-col xl="12">	
							<div style="height: 100%">			
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:columnDefs="columnDefsCe"
									:rowData="celist"
									:rowSelection="rowSelection"
									:pagination="true"
									:paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered"	
									@selection-changed="onSelectionChanged"													
								/>
							</div>
						</b-col>	
					</b-row>

				</b-col>
			</b-row>

			<hr class="mb-4" />			

		</b-container>
		<!-- BEGIN modals -->
		<ModalAddElectricMeters />
		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import CommercialMgmtService from '../_services/CommercialMgmtService';
import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';
import ModalAddElectricMeters from '../components/ModalAddElectricMeters';
import moment from 'moment';


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {	
	name: 'MonitoringDataLoggerDetails',
	setup() {
		const store = defaultStore()
		return { store }
	},	
	components: {
		ModalAddElectricMeters,

		AgGridVue
	},
	data() {
		return {
			logger: [],
			links: {
				celist: "/dashboard/plant/" + this.id + "/electric-meters"
			},
			columnDefsCe: [
				{ field: 'id', sortable: true, filter: true, minWidth: 50, maxWidth: 150, suppressSizeToFit: true,},
				{ field: 'ident', sortable: true, filter: true, minWidth: 100, maxWidth: 300, suppressSizeToFit: true },
				{ field: 'loggerId', sortable: true, filter: true, minWidth: 300, maxWidth: 400, suppressSizeToFit: true },
				{ field: 'plantDto', sortable: true, filter: true, minWidth: 50, maxWidth: 200 },
				{ field: 'readTs', sortable: true, filter: true, minWidth: 50, maxWidth: 200 },
				{ field: 'serialNumber', sortable: true, filter: true, minWidth: 50, maxWidth: 200 }
			],
			series: [],
			form: {
				start: moment().format('YYYY-MM-DD'),
				end: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD')
			},
			lineChart: {
				data: {
					labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
					datasets: [{
						color: '#1f6bff',
						backgroundColor: 'rgba(31, 107, 255, .2)',
						borderColor: '#1f6bff',
						borderWidth: 1.5,
						pointBackgroundColor: '#ffffff',
						pointBorderWidth: 1.5,
						pointRadius: 4,
						pointHoverBackgroundColor: '#1f6bff',
						pointHoverBorderColor: '#ffffff',
						pointHoverRadius: 7,
						label: 'Total production',
						data: [12, 19, 4, 5, 2, 3]
					}]
				},				
			},
			celist: [{'ceNumber':'ND', 'plantId':'ND', 'loggerId':'ND'}],
			currentPage: 1,
			totalPages: 0,
			maxPageLinks: 5,
			filters: {
				ceNumber: { value: '', keys: ['ceNumber'] },
				loggerId: { value: '', keys: ['loggerId'] }
			},
			selected: null,
			options: [
				{ value: null, text: 'Please select an option' },
				{ value: 'a', text: 'This is First option' },
				{ value: 'b', text: 'Selected Option' },
				{ value: { C: '3PO' }, text: 'This is an option with object value' },
				{ value: 'd', text: 'This one is disabled', disabled: true }
			],
			chartOptions: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight'
				},
				title: {
					text: 'Production kW/h',
					align: 'left'
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						opacity: 0.5
					},
				},
				noData: {
					text: 'No data.'
				},
				xaxis: {
					type: 'datetime',
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						showDuplicates: false,
						datetimeFormatter: {
							year: 'yyyy',
							month: "MMM 'yy",
							day: 'dd MMM',
							hour: 'HH:mm',
						},
					}
				}
			},
		}
	},
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	computed: {
		dates () {
			return {
				start: moment(this.form.start).format('DD.MM.YYYY'),
				end: moment(this.form.end).format('DD.MM.YYYY')
			}
		}
	},
	watch: {
		form: {
			handler (form) {
				this.getProductionUpnr({
					start: moment(form.start+'T01:00:00').format('YYYY-MM-DDThh:mm:ss'),
					end: moment(form.end+'T00:00:00').format('YYYY-MM-DDThh:mm:ss')
				});
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		getLoggerDetails() {
			// Trovo il valore impostato nella form precedente
			//console.log(this.store.plantID)
			console.log('Get Datalogger details for ID: ' + this.id);

			TechnicalMgmtService.getLoggerDetails(this.id).then((response) => {
				this.logger = response.data;
				if(this.logger.electricMeters.length != 0) {
					this.celist = this.logger.electricMeters;
					console.log(this.celist)
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		getProductionUpnr( params ) {

			//console.log(params) //ok

			this.series = [];
			this.chartOptions.xaxis.categories.splice(0);

			CommercialMgmtService.getProductionUPNR( params ).then((response) => {	

				let data = [];

				console.log('parsing data', response.data);

				for (const [key, value] of Object.entries(response.data)) {
					this.key = key;
					
					if( this.series[value.version-1] === undefined ) {
						this.series[value.version-1] = { name: 'version: '+value.version, data: [] };	
					}			
					this.series[value.version-1].data.push(value.value);
					this.chartOptions.xaxis.categories.push( value.ts );
				}
				
				for (let i = 0; i < this.series.length; i++) {						
					data.push( { data: this.series[i].data, name: this.series[i].name  } );
				}

				this.$refs.realtimeChart.updateSeries( data , false, true);

			}).catch((err) => {
				console.log(err);
			});
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsString = '';
			var maxToShow = 5;
			selectedRows.forEach(function (selectedRow, index) {
				if (index >= maxToShow) {
					return;
				}
				if (index > 0) {
					selectedRowsString += ', ';
				}
				selectedRowsString += selectedRow.loggerId;
			});
			if (selectedRows.length > maxToShow) {
				var othersCount = selectedRows.length - maxToShow;
				selectedRowsString += ' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
			}
			//console.log(selectedRowsString[0]);
			this.openDetails(selectedRowsString);
			//document.querySelector('#selectedRows').innerHTML = selectedRowsString;
		},
		onGridReady(params) {
			console.log('grid ready');
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
		},
	},
	created() {
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;

		this.store.setLoggerID(this.id);
		this.getLoggerDetails();
	}
}
</script>
