<template>
    <b-modal id="modal-newDataLogger" centered ref="modal" title="New data logger" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">

		<b-row>
			<b-col><h5 class="page-header">New data Logger</h5></b-col>
		</b-row>	
	
		<hr class="mb-4" />

		<form ref="form" @submit.stop.prevent="handleSubmit">
			<!--
				/*
				{
				"ipAddress":"ipAddress",
				"userName":"userName",
				"password":"password",
				"apiAddress":"apiAddress
				}
				*/
			-->
            <div>
                <b-form-input id="ipAddress" v-model="form.ipAddress" :state="ipAddressState" aria-describedby="input-ipAddress-feedback" placeholder="IP address" trim></b-form-input>
                <b-form-invalid-feedback id="input-ipAddress-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
			<div>
                <b-form-input id="userName" v-model="form.userName" :state="userNameState" aria-describedby="input-userName-feedback" placeholder="User name" trim></b-form-input>
                <b-form-invalid-feedback id="input-userName-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
			<div>
                <b-form-input id="password" v-model="form.password" :state="passwordState" aria-describedby="input-password-feedback" placeholder="password" trim></b-form-input>
                <b-form-invalid-feedback id="input-password-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
			<div>
                <b-form-input id="apiAddress" v-model="form.apiAddress" :state="apiAddressState" aria-describedby="input-apiAddress-feedback" placeholder="API address" trim></b-form-input>
                <b-form-invalid-feedback id="input-apiAddress-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
        </form>

    </b-modal>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalNewElectricMeters',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {
			/*
			{
			"ipAddress":"ipAddress",
			"userName":"userName",
			"password":"password",
			"apiAddress":"apiAddress
            }
			*/
			form: {
				ipAddress: '',
				userName: '',
				password: '',
				apiAddress: 1
			},
			show: true
        }
    },
    computed: {
		ipAddressState() {
			return this.form.ipAddress === '' ? false : true
		},
		userNameState() {
			return this.form.userName === '' ? false : true
		},
		passwordState() {
			return this.form.password === '' ? false : true
		},
		apiAddressState() {
			return this.form.apiAddress === '' ? false : true
		},
    },
    methods: {
        isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
			/*
			{
			"ipAddress":"ipAddress",
			"userName":"userName",
			"password":"password",
			"apiAddress":"apiAddress
            }
			*/
            this.form.ipAddress = '';
			this.form.userName = '';
			this.form.password = '';
			this.form.apiAddress = '';

			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()			
			console.log(JSON.stringify(this.form));

			TechnicalMgmtService.regDataLogger( JSON.stringify(this.form) ).then((response) => {
                console.log( response );   
            }).catch((err) => {
				console.log(err);
			});
 
            this.$nextTick(() => {
                this.$bvModal.hide('modal-newDataLogger')
            })
        },
    },
}
</script>