<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">Dashboard</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ $t('tasks.Tasks') }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="10">

					<b-row>
						<b-col><h2 class="page-header">{{ $t('tasks.Tasks') }}</h2></b-col>
						<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right"><a v-b-modal.modal-newTask><i class="fas fa-2xl fa-fw me-2 fa-plus-square"></i></a></b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">	
							<div style="height: 100%">			
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:columnDefs="columnDefs"
									:rowData="tasks"
									:rowSelection="rowSelection"
									:pagination="true"
									:paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered"	
									@selection-changed="onSelectionChanged"													
								/>
							</div>
						</b-col>	
					</b-row>

				</b-col>
			</b-row>
		</b-container>
		<!-- BEGIN modals -->
		<ModalNewTask />
		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import ModalNewTask from '../components/ModalNewTask';
import { defaultStore } from '../_store/storage';
import moment from 'moment'


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
	name: 'Tasks',
	setup() {
		const store = defaultStore()
		return { store}
	},
	components: {
		ModalNewTask,
		/* eslint-disable vue/no-unused-components */
		AgGridVue,
	},
	data() {
		return {
			tasks: [],
			columnDefs: [
				{ headerName: this.$t('plants.Name'), field: 'plantDto.plantName', sortable: true, filter: true, minWidth: 50, maxWidth: 400, suppressSizeToFit: true,},
				{ headerName: "Deadline", field: 'deadline', sortable: true, minWidth: 100, maxWidth: 200, suppressSizeToFit: true, filter: true, },
				{ headerName: this.$t('tasks.Type'), field: 'task', sortable: true, minWidth: 100, maxWidth: 150, suppressSizeToFit: true, filter: true, },
				{ headerName: this.$t('tasks.State'), field: 'status', sortable: true, minWidth: 100, maxWidth: 150, suppressSizeToFit: true,  filter: true, },
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: {
				resizable: true,
				flex: 1,
				minWidth: 100
			},
			rowData: null,
		}
	},
	methods: {
		shorten(params) {
			return moment(String(params)).format('DD/MM/YYYY');
		},
		task(params) {
			switch(params) {
				default:
					"0";
					break;
				case "1":
					return 'administrative';
				case "2":
					return 'technical';
			}
		},
		status(params) {
			switch(params) {
				default:
					"0";
					break;
				case "1":
					return 'Done';
				case "2":
					return 'Progress';
				case "3":
					return 'Pending';
			}
		},
		getTasks() {
			console.log('Get task activities list:');
			this.store.setPlantID(null);

			TechnicalMgmtService.getAdministrative().then((response) => {
				let array = response.data;
				for(let i = 0; i < array.length; i++) {
					array[i].deadline = this.shorten(array[i].deadline)
					array[i].status = this.status(array[i].status)
					array[i].task = this.task(array[i].task)
				}

				array = this.sortedArray(array);
				this.tasks = array;
			}).catch((err) => {
				console.log(err);
			});

			console.log(this.tasks);
		},
		openDetails(id) {
			//this.store.setPlantID(id);
			window.location.href = window.location.origin + "/dashboard/tasks/" + id
			// axios.delete('https://your.rest.api/users/' + id)
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsId = selectedRows[0].activityId;

			this.openDetails(selectedRowsId);
		},
		onGridReady(params) {
			console.log('grid ready');
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
		},
		sortedArray(array) {
			console.log(array)
			function compare(a, b) {
				if (a.insertTs < b.insertTs) return -1;
				if (a.insertTs > b.insertTs) return 1;
				return 0;
			}
			return array.sort(compare);
		}
	},
	created() {
		this.getTasks();
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;
	}
}
</script>
