
<template>
    <div>
        <font-awesome-icon ref="delete" class="px-2" icon="fa-solid fa-trash" @click="btnClickedHandler('delete')" />
            
        <font-awesome-icon ref="download" class="px-2" icon="fa-solid fa-download" @click="btnClickedHandler('download')" />   
            
        <b-tooltip :target="() => $refs['delete']" title="Delete file"></b-tooltip>
        <b-tooltip :target="() => $refs['download']" title="Download file"></b-tooltip>
        
    </div>
</template>
<script>
export default ({
    name: 'btnCellRenderer',
    data: function () {
        return {
            file: null,
            folder: null,
        };
    },
    beforeMount() {
        // this.params contains the cell and row information and is made available to this component at creation time
        // see ICellRendererParams for more details
        this.file = this.params.data.file_name;
        this.folder = this.params.data.folder_name;
    },
    methods: {
        btnClickedHandler(params) {
            if(params == 'delete') {
                this.$confirm("Sei sicuro di voler cancellare il flie?").then(() => {
                    this.params.clicked(params, this.file, this.folder);
                })
            } else {
                //download
                this.params.clicked(params, this.file, this.folder);
            }
        },
    },
});
</script>
