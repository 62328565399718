<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/dashboard/tasks">Task list</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ activity.activityId }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">
					<h1 class="page-header">
						Task detail
					</h1>
					<hr class="mb-4" />
				</b-col>
				<b-col xl="12">
					<b-card :header="'ID: '+activity.activityId + '; deadline: ' + shorten(activity.deadline) + '; status: ' + getStatus(activity.status)">
						<b-card-text class="card-text mb-3"></b-card-text>

						<b-list-group class="border-0" flush>
							<b-list-group-item><div class="row"><div class="col-2">Activity ID:</div><div class="col-10">{{ activity.activityId }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Deadline:</div><div class="col-10">{{ shorten(activity.deadline) }}</div></div></b-list-group-item>						
							<b-list-group-item><div class="row"><div class="col-2">Priority:</div><div class="col-10"><b-form-select class="form-select" size="lg" id="priority" v-model="activity.priority" :options="priority_options"></b-form-select></div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Responsible:</div><div class="col-10">{{ activity.responsible }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Task description:</div><div class="col-10">{{ activity.taskDescription }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">To do:</div><div class="col-10">{{ activity.toDo }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Status:</div><div class="col-10"><b-form-select class="form-select" size="lg" id="status" v-model="activity.status" :options="status_opt"></b-form-select></div></div></b-list-group-item>
							
							<b-list-group-item><div class="row"><div class="col-2">Task</div><div class="col-10">{{ activity.task }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Progress</div><div class="col-10"><b-form-input type="number" min="0" max="100" step="10" id="progress" v-model="activity.progress"></b-form-input></div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Insert</div><div class="col-10">{{ shorten(activity.insertTs) }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Comments</div><div class="col-10"><b-form-textarea id="comments" v-model="activity.comments"></b-form-textarea></div></div></b-list-group-item>
						
						</b-list-group>

						<hr class="mb-4" />

						<div class="col-md-2">
							<button class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click="updateDetails()">update</button>
						</div>

					</b-card>

				</b-col>

			</b-row>

		</b-container>
		<!-- BEGIN modals -->

		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';
import moment from 'moment';

export default {	
	name: 'TaskDetails',
	setup() {
		const store = defaultStore()
		return { store }
	},	
	components: {

	},
	data() {
		return {
			activity: [],
			status_opt: [
				{ value: 1, text: 'Done' },
				{ value: 2, text: 'Progress' },
				{ value: 3, text: 'Pending' }
			],
			priority_options: [
				{ value: 1, text: 'Low' },
				{ value: 2, text: 'Medium' },
				{ value: 3, text: 'High' }
			],
			form: [
				{
					op: 'replace',
					path: '/progress',
					value: ''
				},
				{
					op: 'replace',
					path: '/status',
					value: ''
				},
				{
					op: 'replace',
					path: '/comments',
					value: ''
				},
			],
		}
	},
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	methods: {
		shorten(str) {
			return moment(String(str)).format('DD/MM/YYYY');
		},
		getStatus(str) {
			let txt;			
			Object.keys(this.status_opt).forEach(key => {
				if(this.status_opt[key].value == str){
					txt = this.status_opt[key].text;	
				}
			});	
			return txt;
		},
		getActivityDetails() {
			// Trovo il valore impostato nella form precedente
			//console.log(this.store.plantID)
			console.log('Get activity details for: ' + this.id);

			TechnicalMgmtService.getAdministrativeActivityDetails(this.id).then((response) => {
				console.log(response.data)
				this.activity = response.data;				
			}).catch((err) => {
				console.log(err);
			});
		},
		updateDetails() {
			/*
			{
              "op":"replace",
              "path":"/name",
              "value":"newName"}
			*/
			this.form[0].value = this.activity.progress;
			this.form[1].value = this.activity.status;
			this.form[2].value = this.activity.comments;

			console.log(this.form)

			TechnicalMgmtService.updateAdministrativeDetails(this.activity.activityId, this.form ).then((response) => {
				console.log(response.data);
				//this.activity = response.data;					
			}).catch((err) => {
				console.log(err);
			});
		}
	},
	created() {
		this.store.setPlantID(null);
		this.getActivityDetails();
	}
}
</script>
