<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container style="max-width: none;">
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">{{ $t('general.Failures') }}</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ $t('general.Failures') }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">

					<b-row>
						<b-col>
							<h2 class="page-header">{{ $t('general.RegisteredFailures') }}</h2>
						</b-col>
						<b-col md="1" sm="6" xs="3" class="mb-1 text-truncate" style="text-align: right"><a
								v-b-modal.modal-newFailure><i class="fas fa-2xl fa-fw me-2 fa-plus-square"
									v-b-modal.modal-addElectricMeters></i></a></b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">
							<div style="height: 100%">
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:columnDefs="columnDefs"
									:rowSelection="rowSelection"
									:pagination="true" :paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered" @selection-changed="onSelectionChanged"
									@grid-size-changed="onGridSizeChange"
									rowModelType="serverSide"
									cacheBlockSize="50"
									:defaultColDef="defaultColDef"
								/>
							</div>
						</b-col>
					</b-row>

				</b-col>
			</b-row>
		</b-container>
		<!-- BEGIN modals -->
		<ModalNewFailure />
		<!-- END modals -->
	</div>
</template>

<script>

import CommercialManagementAPI from '../_services/CommercialMgmtService';
import ModalNewFailure from '../components/ModalNewFailure';
import moment from 'moment';
import { i18n } from '@/plugins/i18n.js';
import {defaultColDef} from '../config/agGridDefaults';


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
	name: i18n.t('general.Failures'),
	components: {
		ModalNewFailure,
		AgGridVue,
	},
	data() {
		return {
			failures: [],
			columnDefs: [
				{ headerName: this.$t('plants.Name'), field: 'plant_name', sortable: true, },
				{ headerName: 'SAPR', field: 'plant_SAPR', sortable: true, },
				{ headerName: 'SPV', field: 'SPV_name', sortable: true, },
				{ headerName: this.$t('failures.Reason'), field: 'description', sortable: true, },
				{ headerName: this.$t('failures.Setpoint'), field: 'setpoint', sortable: true, valueFormatter: params => params.data.setpoint + ' %' },
				{ headerName: this.$t('failures.StartDate'), field: 'start_date', sortable: true, valueFormatter: params => moment(params.data.start_date).format('YYYY-MM-DD hh:mm:ss'), filter: 'agDateColumnFilter'},
				{ headerName: this.$t('failures.EndDate'), field: 'end_date', sortable: true, valueFormatter: params =>  moment(params.data.start_date).format('YYYY-MM-DD hh:mm:ss'), filter: 'agDateColumnFilter'},
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef,
			rowData: null,
		}
	},
	methods: {
		shorten(params) {
			return moment(String(params.value)).format('DD/MM/YYYY');
		},
		plantName(params) {
			for (const [key, value] of Object.entries(this.plants)) {
				if (value.plantId === params.value) {
					return 'ID: ' + key + '; Name: ' + value.name;
				}
			}
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		printFilterModel() {
			var filterModel = this.gridApi.getFilterModel();
			console.log(filterModel);
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsString = '';
			var maxToShow = 5;
			selectedRows.forEach(function (selectedRow, index) {
				if (index >= maxToShow) {
					return;
				}
				if (index > 0) {
					selectedRowsString += ', ';
				}
				selectedRowsString += selectedRow.failuresId;
			});
			if (selectedRows.length > maxToShow) {
				var othersCount = selectedRows.length - maxToShow;
				selectedRowsString +=
					' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
			}
			console.log(selectedRowsString);
			this.openDetails(selectedRowsString);
			//document.querySelector('#selectedRows').innerHTML = selectedRowsString;
		},
		onGridReady(params) {
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
			this.gridApi.sizeColumnsToFit();
			const dataSource = {
				getRows: async params => {
					try {
						const response = await CommercialManagementAPI.getPlantFailures(params.request);
						params.success({
							rowData: response.data.data,
							rowCount: response.data.count,
						});
					}
					catch (e) {
						console.error('error while fetching fnds data', e);
						params.fail();
					}
				}
			};
			this.gridApi.setServerSideDatasource(dataSource);
		},
		columnsChanged() {
			this.gridApi.sizeColumnsToFit()
		},
		onGridSizeChange() {
			this.gridApi.sizeColumnsToFit()
		},

	},

	created() {
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;
	}
}
</script>
