<template>
    <b-modal id="modal-newPermission" centered ref="modal" title="New Permission" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            
			<div class="row mt-2">
				<label for="path" class="col-md-2 col-form-label">Path</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="path" v-model="permission.permissions[0].path" :options="routes_opt" :state ="pathState" aria-describedby="input-path-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-path-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>  
			
			<div class="row mt-2">
				<label for="method" class="col-md-2 col-form-label">Method</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="method" v-model="permission.permissions[0].method" :options="methods_opt" :state ="methodState" aria-describedby="input-method-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-method-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div> 

			<div class="row mt-2">
				<label for="role" class="col-md-2 col-form-label">Roles</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="role" v-model="permission.id" :options="roles_opt" :state ="roleState" aria-describedby="input-role-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-role-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div> 
			
        </form>
    </b-modal>
</template>

<script>

import authService from '../_services/auth.service';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalnewPermission',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {
            routes_opt: [
				{ value: null, text: 'Please select path' },
			],
			methods_opt: [
				//save to /roles/{id}
				{ value: null, text: 'Please select method' },
				{ value: 1, text: 'add_plant' },
				{ value: 1, text: 'change_plant' },
				{ value: 1, text: 'delete_plant' },
				{ value: 1, text: 'export_list' },
				{ value: 1, text: 'add_technical_activity' },
				{ value: 1, text: 'change_technical_activity' },
				{ value: 1, text: 'delete_technical_activity' },
				{ value: 1, text: 'add' },
				{ value: 1, text: 'change' },
				{ value: 1, text: 'delete' },
				{ value: 1, text: 'generate' },
				{ value: 1, text: 'publish' },
				{ value: 1, text: 'export' },
				{ value: 1, text: 'download_invoice' },
				{ value: 1, text: 'download_invoice_detail' },
				{ value: 1, text: 'update_user_name' },
				{ value: 1, text: 'update_user_fullname' },
				{ value: 1, text: 'update_user_email' },
				{ value: 1, text: 'update_user_password' },
				{ value: 1, text: 'update_user_permission' },
				{ value: 1, text: 'update_user_active' },
				{ value: 1, text: 'update_user_category' },
				{ value: 1, text: 'delete_user' },
				{ value: 1, text: 'visit_user' },
				{ value: 1, text: 'update_user_role' },
				{ value: 1, text: 'update_user_status' },
				{ value: 1, text: 'export_user' },
			],
			roles_opt: [
				{ value: null, text: 'Please select role' },
			],

			permission: {
				id: '',
				name: '',
				permissions: [
					{
						path: '',
						method: '',
						description: '',
					}
				]
			},
			form: [
				{
					op: 'add',
					path: '/path',
					value: '/dashboard/plants'
				},
			],
			show: true,
        }
    },
    computed: {
		pathState() {
			return this.form.path === '' ? false : true
		},
		methodState() {
			return this.form.method === '' ? false : true
		},
		roleState() {
			return this.form.role === '' ? false : true
		},
    },
    methods: {
        /* MODAL */
        // Validator function
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
            this.path = '';
			this.method = '';
			this.role = '';

			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

			//console.log( this.roles_opt[this.form.id].text )

			Object.keys(this.roles_opt).forEach(key => {
				if(this.roles_opt[key].value === this.permission.id){
					this.permission.name = this.roles_opt[key].text;	
				}
			});	

			Object.keys(this.methods_opt).forEach(key => {
				if(this.methods_opt[key].value === this.permission.permissions[0].method){
					this.form[0].value = this.methods_opt[key].text;	
				}
			});	

			console.log(JSON.stringify(this.form));

			authService.saveRolePermission(this.permission.id, this.form ).then((response) => {
                console.log( response.status );   
				if( response.status == 201 ) {					
					console.og(response);
				}		   
            }).catch((err) => {
				console.log(err);
			});

            this.$nextTick(() => {
                this.$bvModal.hide('modal-newPermission');
            })
        },
		getModalData() {
			let routes = this.$router.options.routes;
			for (const [key, value] of Object.entries(routes)) {
				this.routes_opt.push({ value: value.path, text: value.path, index: key });
			}

			authService.getRoles().then((response) => {
				this.roles = response.data;
				for (const [key, value] of Object.entries(this.roles)) {
					this.roles_opt.push({ value: value.id, text: value.name, index: key });
				}
				//console.log(this.roles)
            }).catch((err) => {
				console.log(err);
			});

		}	
    },
	async created() {
		await this.getModalData();
	}
}
</script>