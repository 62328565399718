<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container style="max-width: none;">
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
				<b-breadcrumb-item href="#">{{ $t('market.GMEPrices') }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">

					<b-row>
						<b-col>
						<b-col><h2 class="page-header">{{  $t('market.GMEPrices') }}</h2></b-col>
						</b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">
							<div style="height: 100%">
								<ag-grid-vue @grid-ready="onGridReady" style="width: 100%; height: 100%"
									class="ag-theme-alpine" :columnDefs="columnDefs" :rowData="failures"
									:rowSelection="rowSelection" :pagination="true" :paginationPageSize="paginationPageSize"
									@first-data-rendered="onFirstDataRendered" @selection-changed="onSelectionChanged"
									@grid-size-changed="onGridSizeChange" />
							</div>
						</b-col>
					</b-row>

				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>

import Service from '../_services/CommercialMgmtService';
import { defaultStore } from '../_store/storage';
import moment from 'moment';
import { i18n } from '@/plugins/i18n.js';


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
	mounted() {
		// window.addEventListener('resize', this.handleViewportChange);
		// this.gridOptions.api.addEventListener('gridSizeChanged', this.onGridSizeChange);
	},
	name: i18n.t('general.Failures'),
	setup() {
		const store = defaultStore()
		return { store }
	},
	components: {
		AgGridVue,
	},
	data() {
		return {
			failures: [],
			columnDefs: [
				{ headerName: this.$t('plants.Name'), field: 'plantDto.plantName', sortable: true, filter: true, },
				{ headerName: this.$t('failures.Reason'), field: 'reasonDto.description', sortable: true, filter: true, },
				{ headerName: this.$t('failures.Setpoint'), field: 'setpoint', sortable: true, filter: true, valueFormatter: params => params.data.setpoint + ' %' },
				{ headerName: this.$t('failures.StartDate'), field: 'startDate', sortable: true, filter: true, valueFormatter: params => moment(params.data.startDate).format('YYYY-MM-DD') + ' ' + params.data.startDate.slice(11,) }, // Remove the 'T' from the date
				{ headerName: this.$t('failures.EndDate'), field: 'endDate', sortable: true, filter: true, valueFormatter: params => params.data.startDate.slice(0, 10) + ' ' + params.data.startDate.slice(11,) }
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: {
				resizable: true,
				flex: 1,
				minWidth: 100,
				floatingFilter: true,
			},
			rowData: null,
		}
	},
	methods: {
		shorten(params) {
			return moment(String(params.value)).format('DD/MM/YYYY');
		},
		plantName(params) {
			for (const [key, value] of Object.entries(this.plants)) {
				if (value.plantId === params.value) {
					return 'ID: ' + key + '; Name: ' + value.name;
				}
			}
		},
		getFailures() {
			console.log('Get failures list:');
			this.store.setPlantID(null);

			Service.getFailures().then((response) => {
				console.log(response.data)
				this.failures = response.data;
			}).catch((err) => {
				console.log(err);
				this.failures = [{ "ceId": "ND", "ceNumber": "ND", "plantId": "ND", "loggerId": "ND" }];
			});
		},
		openDetails(id) {
			this.store.setPlantID(id);
			window.location.href = window.location.origin + "/plant-failures/failure/" + id
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		printFilterModel() {
			var filterModel = this.gridApi.getFilterModel();
			console.log(filterModel);
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsString = '';
			var maxToShow = 5;
			selectedRows.forEach(function (selectedRow, index) {
				if (index >= maxToShow) {
					return;
				}
				if (index > 0) {
					selectedRowsString += ', ';
				}
				selectedRowsString += selectedRow.failuresId;
			});
			if (selectedRows.length > maxToShow) {
				var othersCount = selectedRows.length - maxToShow;
				selectedRowsString +=
					' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
			}
			console.log(selectedRowsString);
			this.openDetails(selectedRowsString);
			//document.querySelector('#selectedRows').innerHTML = selectedRowsString;
		},
		onGridReady(params) {
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
			this.gridApi.sizeColumnsToFit();
		},
		columnsChanged() {
			this.gridApi.sizeColumnsToFit()
		},
		onGridSizeChange() {
			this.gridApi.sizeColumnsToFit()
		},

	},

	created() {
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;

		this.getFailures();
	}
}

/*const shorten = params => {
	//console.log( params );
	const date = params.value;
	return String(moment(String(date)).format('DD/MM/YYYY'));
}*/

/*
const countryValueFormatter = params => {
	const country = params.value;
	return country + ' (' + COUNTRY_CODES[country].toUpperCase() + ')';
};
*/

</script>
