<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/plant-failures/failures-managing">Plant failures</b-breadcrumb-item>
					<b-breadcrumb-item active>details</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">
					<h1 class="page-header">
						Failure details
					</h1>
					<hr class="mb-4" />
				</b-col>
				<b-col xl="10">
					<b-card :header="'ID: '+failure.failuresId + ''">
						<b-card-text class="card-text mb-3">plant name: <b>{{ failure.plantDto.plantName }}</b></b-card-text>

						<form ref="form" @submit.prevent="signInButtonPressed" >
            
							<div class="row mt-2">
								<label for="reason" class="col-md-2 col-form-label">Reason</label>
								<div class="col-md-10">
									<b-form-input id="reason" v-model="failure.reasonDto.description" readonly></b-form-input>
								</div>
							</div>
							<div class="row mt-2">
								<label for="setpoint" class="col-md-2 col-form-label">Setpoint %</label>
								<div class="col-md-10">
									<b-form-input id="setpoint" v-model="failure.setpoint" trim></b-form-input>
								</div>
							</div>
							<div class="row mt-2">
								<label for="start" class="col-md-2 col-form-label">Start date</label>
								<div class="col-md-5">	
									<b-form-datepicker id="start" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :min="minDate" v-model="failure.startDate" :state="startState" aria-describedby="input-start-feedback" trim></b-form-datepicker>
									<b-form-invalid-feedback id="input-start-feedback">Invalid date</b-form-invalid-feedback>
								</div>
								<div class="col-md-5">
									<b-form-timepicker id="st" v-model="failure.startTime" :state="startTimeState" aria-describedby="input-start_t-feedback" trim></b-form-timepicker>
									<b-form-invalid-feedback id="input-start_t-feedback">Invalid date</b-form-invalid-feedback>
								</div>
							</div>
							<div class="row mt-2">
								<label for="end" class="col-md-2 col-form-label">End date</label>
								<div class="col-md-5">
									<b-form-datepicker id="end" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :min="minDate" v-model="failure.endDate" :state="endState" aria-describedby="input-end-feedback" trim></b-form-datepicker>
									<b-form-invalid-feedback id="input-end-feedback">Invalid date</b-form-invalid-feedback>
								</div>
								<div class="col-md-5">
									<b-form-timepicker id="end_time" v-model="failure.endTime" :state="endTimeState" aria-describedby="input-end-feedback" trim></b-form-timepicker>
								</div>
							</div>	
							
							<div class="row mt-2">							
								<b-button-group size="md col-md-2">
									<b-button variant="primary" @click="updateFailure()" :disabled="isDisabled" >update</b-button>
									<b-button variant="primary" @click="deleteFailure()" :disabled="isDisabled" >delete</b-button>
								</b-button-group>
							</div>
							
						</form>

					</b-card>

				</b-col>

			</b-row>

			<hr class="mb-4" />

		</b-container>
		<!-- BEGIN modals -->

		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import CmngmService from '../_services/CommercialMgmtService';
import { defaultStore } from '../_store/storage';
import moment from 'moment';

export default {	
	name: 'FailureDetails',
	setup() {
		const store = defaultStore()
		return { store }
	},	
	components: {

	},
	data() {
		return {
			test: '',
			update_disabled: false,
			delete_disabled: false,
			minDate: moment().format(),
			failure: {
				plantDto: {plantName: '', plantId: '', sapr: ''},
				reasonDto: {description: '', reasonId: '' },
				startDate: '',
				startTime: moment().format('HH:mm:ss'),
				endDate: '',
				endTime: moment().add(1, 'hour').format('HH:mm:ss'),
				setpoint: ''
			},
			form: [
				{
					op: 'replace',
					path: '/setpoint',
					value: ''
				},
				{
					op: 'replace',
					path: '/startDate',
					value: ''
				},
				{
					op: 'replace',
					path: '/endDate',
					value: ''
				},
			],
		}
	},
	computed: {
		isDisabled: function(){
			return !this.startState || !this.startTimeState || !this.endState || !this.startTimeState || !this.endTimeState;
		},
		startState() {
			console.log('startDateCheck', this.failure.startDate)
			let now = moment().add(2, 'hours').format('L');
			let startDate = moment( this.failure.startDate );
			//console.log(now, startDate, startDate.isSameOrAfter( now ) );

			return startDate.isSameOrAfter( now ) ? true : false;
		},
		startTimeState() {
			console.log('startTimeCheck', this.failure.startTime)
			let now;
			let startTime = moment( this.failure.startTime, 'hh:mm' );
			if( moment( this.failure.startDate ).isSame( moment().format('L') ) ) {
				console.log('is same', this.failure.startDate, this.failure.startTime);
				now = moment().add(2, 'hours');				
			} else if ( moment( this.failure.startDate ).isAfter( moment().format('L') ) ) {
				console.log('is after', this.failure.startDate, this.failure.startTime);
				now = moment('00:00', 'hh:mm');
			}			
			//console.log(now, startTime, startTime.isAfter( now ) );

			return startTime.isAfter( now ) ? true : false;
		},		
		endState() {
			let now = moment( this.failure.startDate ).format('L');
			let startDate = moment( this.failure.endDate );
			//console.log(now, startDate, startDate.isSameOrAfter( now ) );

			return startDate.isSameOrAfter( now ) ? true : false;
		},
		endTimeState() {
			console.log('endTimeCheck')
			let now = moment( this.failure.startTime, 'hh:mm' );
			let startTime = moment( this.failure.endTime, 'hh:mm' );
			//console.log(now, startTime, startTime.isAfter( now ) );

			return startTime.isAfter( now ) ? true : false;
		},
	},
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	methods: {
		shorten(str) {
			return moment(String(str)).format('DD/MM/YYYY');
		},
		getFailureDetails() {
			// Trovo il valore impostato nella form precedente
			//console.log(this.store.plantID)
			console.log('Get failure details for: ' + this.id);

			CmngmService.getFailureDetails(this.id).then((response) => {
				console.log(response.data)
				this.failure = response.data;

				let sd, st, ed, et, now;
				sd = moment(response.data.startDate).format('YYYY-MM-DD');
				st = moment(response.data.startDate).format('HH:mm');
				ed = moment(response.data.endDate).format('YYYY-MM-DD');
				et = moment(response.data.endDate).format('HH:mm');

				this.failure.startDate = sd;
				this.failure.startTime = st;
				this.failure.endDate = ed;
				this.failure.endTime = et;

				/*now = moment();
				sd = moment(this.failure.startDate);*/

				this.update_disabled = moment(sd).isBefore(now);
				this.delete_disabled = moment(sd).isBefore(now);

			}).catch((err) => {
				console.log(err);
			});

		}, 
		updateFailure() {
			/*
			{
              "op":"replace",
              "path":"/name",
              "value":"newName"}
			*/
			this.form[0].value = this.failure.setpoint;
			this.form[1].value = this.failure.startDate;
			this.form[2].value = this.failure.endDate;

			/*for (const [key, value] of Object.entries(response.data)) {
				this.fonds.push( { value: value.fondId, text: value.name, index: key } );
			} */

			CmngmService.updateFailureDetails(this.id, this.form ).then((response) => {
				console.log(response.data);
				//this.failure = response.data;					
			}).catch((err) => {
				console.log(err);
			});
		},
		deleteFailure() {
			this.$confirm("Sei sicuro?").then((cond) => {
				if(cond) {
					CmngmService.deleteFailureDetails(this.id).then((response) => {
						if(response.status == '204') {
							window.location.href = '/plant-failures/failures-managing'
						}
						//this.failure = response.data;					
					}).catch((err) => {
						console.log(err);
					});
				}
			})
		}
	},
	created() {
		//this.store.setPlantID(this.id);
		this.getFailureDetails();
		
	}
}
</script>
