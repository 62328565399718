<template>
	<div id="content" class="app-content">
		<b-col xl="12">
			<b-row>
				<b-col>
					<h1 class="page-header">
						<a @click="$router.back()" style="cursor: pointer;">{{ formatter.entityName(entityName) }}</a>
					</h1>
				</b-col>
				<b-col v-if="isReady && userHasRight('create')" md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right">
					<font-awesome-icon
						:icon="['fas', 'plus-square']"
						@click="edit"
						size="2xl"
						style="cursor: pointer; margin-left: 0.5em;"
						:title="$t('form.new')"
					/>
				</b-col>
			</b-row>	
			<hr class="mb-4" />
		</b-col>
		<Grid
			:entityName="entityName"
			:isSelect="isSelect"
			:selectEntityName="selectEntityName"
			:selectEntityId="selectEntityId"
			:selectEntityColumn="selectEntityColumn"
		></Grid>
		<button
          @click="$router.back()"
          class="btn btn-secondary mt-3"
          type="button">
          {{ $t('form.cancel') }}
        </button>
	</div>
</template>

<script>

import axios from 'axios';

import Grid from '../components/Grid.vue';
import formatter from '../helpers/formatter';

import { getAPIObjectURL } from '../_services/TechnicalMgmtService.js';

export default {	
	name: 'GridPage',
	components: {
		Grid,
	},
	props: {
		entityName: {
			type: String,
		},
		isSelect: {
			type: Boolean,
			default: false,
		},
		selectEntityName: {
			type: String,
		},
		selectEntityId: {
			type: Number,
		},
		selectEntityColumn: {
			type: String,
		},
	},
	data() {
		return {
			isReady: false,
			formatter,
			userRights: [],
		};
	},
	watch: {
		async entityName() {
			await this.updateRights();
		},
	},
	created() {
		this.updateRights();
	},
	methods: {
		userHasRight(right) {
			return this.userRights.includes(right);
		},
		async updateRights() {
			this.isReady = false;
			this.userRights = (await axios.get(`${getAPIObjectURL(this.entityName)}/current-user-rights`)).data;
			this.isReady = true;
		},
		edit() {
			if (this.entityName === 'silver-plant-failures') {
				this.$router.push('/plant-failures/form');
			}
			else if (this.entityName === 'users') {
				this.$router.push('/users/form');
			}
			else {
				this.$router.push('/forms/' + this.entityName);
			}
		},
	},
};
</script>
