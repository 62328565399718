<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/dashboard/scheduled-activities">Scheduled activities</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ activity.activityId }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">
					<h1 class="page-header">
						Scheduled activity details
					</h1>
					<hr class="mb-4" />
				</b-col>
				<b-col xl="12">
					<b-card :header="'ID: '+activity.activityId + '; deadline: ' + shorten(activity.deadline) ">
						<b-card-text class="card-text mb-3"></b-card-text>

						<b-list-group class="border-0" flush>
							<b-list-group-item><div class="row"><div class="col-2">Activity ID:</div><div class="col-10">{{ activity.activityId }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Start date:</div><div class="col-10">{{ shorten(activity.startDate) }}</div></div></b-list-group-item>						
							<!--b-list-group-item><div class="row"><div class="col-2">Deadline:</div><div class="col-10">{{ shorten(activity.deadline) }}</div></div></b-list-group-item-->
							<b-list-group-item><div class="row"><div class="col-2">Responsible:</div><div class="col-10">{{ activity.responsible }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">Task description:</div><div class="col-10">{{ activity.taskDescription }}</div></div></b-list-group-item>
							<b-list-group-item><div class="row"><div class="col-2">To do:</div><div class="col-10">{{ activity.toDo }}</div></div></b-list-group-item>
						</b-list-group>

						<!--hr class="mb-4" />

						<div class="col-md-2">
							<button class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3" @click="updateDetails()">update</button>
						</div-->

					</b-card>

				</b-col>

			</b-row>

		</b-container>
		<!-- BEGIN modals -->

		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';
import moment from 'moment';

export default {	
	name: 'ScheduledActivityDetails',
	setup() {
		const store = defaultStore()
		return { store }
	},	
	components: {

	},
	data() {
		return {
			activity: [],
			status_opt: [
				{ value: 1, text: 'Done' },
				{ value: 2, text: 'Progress' },
				{ value: 3, text: 'Pending' }
			],
			form: [
				{
					op: 'replace',
					path: '/status',
					value: ''
				},
			],
		}
	},
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	methods: {
		shorten(str) {
			return moment(String(str)).format('DD/MM/YYYY');
		},
		getStatus(str) {
			Object.keys(this.status_opt).forEach(key => {
				if(this.status_opt[key].value === str){
					return this.status_opt[key].text;	
				}
			});	
		},
		getActivityDetails() {
			// Trovo il valore impostato nella form precedente
			//console.log(this.store.plantID)
			console.log('Get activity details for: ' + this.id);

			TechnicalMgmtService.getScheduledActivityDetails(this.id).then((response) => {
				console.log( response.data );
				this.activity = response.data;
			}).catch((err) => {
				console.log(err);
				this.plant = [{ "plantId": "ND", "name": "ND", "type": "ND", "city": "ND", "coeffProduction": "ND", "voltage": "ND", "ceNumber": "ND", "pmax": "ND", "sapr": "ND", "pod": "ND" }];
			});
		},
		updateDetails() {
			console.log(this.activity);

			/*
			{
              "op":"replace",
              "path":"/name",
              "value":"newName"}
			*/
			this.form[0].value = this.activity.status;

			TechnicalMgmtService.updateScheduledDetails(this.activity.activityId, this.form ).then((response) => {
				console.log(response.data);
				//this.activity = response.data;					
			}).catch((err) => {
				console.log(err);
			});
		}
	},
	created() {
		this.store.setPlantID(null);
		this.getActivityDetails();
	}
}
</script>
