<template>
    <b-modal id="modal-newElectricMeter" centered ref="modal" title="Add electric meter" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">

		<b-row>
			<b-col><h5 class="page-header">New electric meter</h5></b-col>
		</b-row>	
	
		<hr class="mb-4" />

		<form ref="form" @submit.stop.prevent="handleSubmit">
            <div>
                <b-form-input id="ce_number" v-model="form.ce_number" :state="ce_numberState" aria-describedby="input-ce_number-feedback" placeholder="ce number" trim></b-form-input>
                <b-form-invalid-feedback id="input-ce_number-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
			<div>
                <b-form-input id="plant_id" v-model="form.plant.plantId" :state="plantIdState" aria-describedby="input-plant_id-feedback" placeholder="plant id" trim></b-form-input>
                <b-form-invalid-feedback id="input-plant_id-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
			<div>
                <b-form-input id="data_logger" v-model="form.dataLogger.loggerId" :state="dataLoggerState" aria-describedby="input-data_logger-feedback" placeholder="logger id" trim></b-form-input>
                <b-form-invalid-feedback id="input-data_logger-feedback">Field cannot be empty</b-form-invalid-feedback>
            </div>
        </form>

    </b-modal>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalNewElectricMeters',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {
			form: {
				ce_number: '',
				plant:{
					plantId: ''
				},
				dataLogger: {
					loggerId: ''
				}
			},
			show: true
        }
    },
    computed: {
		ce_numberState() {
			return this.form.ce_number === '' ? false : true
		},
		plantIdState() {
			return this.form.plant.plantId === '' ? false : true
		},
		dataLoggerState() {
			return this.form.dataLogger.loggerId === '' ? false : true
		},
    },
    methods: {
        isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
            this.form.ce_number = '';
			this.form.plant.plantId = '';
			this.form.dataLogger.loggerId = '';
			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()			
			console.log(JSON.stringify(this.form));

			TechnicalMgmtService.regElectricMeter( JSON.stringify(this.form) ).then((response) => {
                console.log( response );   
            }).catch((err) => {
				console.log(err);
			});
 
            this.$nextTick(() => {
                this.$bvModal.hide('modal-addTechnical')
            })
        },
    },
}
</script>