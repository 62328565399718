<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">{{this.$t('invoices.Invoices')}}</b-breadcrumb-item>
					<b-breadcrumb-item active>{{this.$t('deals.Deals')}}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">

					<b-row>
						<b-col><h2 class="page-header">Deals</h2></b-col>
						<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right">
							<font-awesome-icon
								:icon="['fas', 'plus-square']"
								@click="$router.push('/forms/deals')"
								size="2xl"
								style="cursor: pointer; margin-left: 0.5em;"
								:title="$t('form.new')"
							/>
						</b-col>
					</b-row>	
				
					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">	
							<div>			
								<ag-grid-vue
									@grid-ready="onGridReady"
									style="width: 100%; height: 100%"
									class="ag-theme-alpine"
									:loadingCellRenderer="loadingCellRenderer"
									:columnDefs="columnDefs"
									rowModelType="serverSide"
									:cacheBlockSize="cacheBlockSize"
									:rowSelection="rowSelection"
									:pagination="true"
									:paginationPageSize="paginationPageSize"
									:animateRows="true"
									@first-data-rendered="onFirstDataRendered"	
									:defaultColDef="defaultColDef"
									@row-double-clicked="openDetails"
								/>
								<!--:rowModelType="rowModelType"-->
							</div>
						</b-col>	
					</b-row>

				</b-col>
			</b-row>
	</div>
</template>

<script>

import CommercialMgmtService from '../_services/CommercialMgmtService';
import { defaultStore } from '../_store/storage';

import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import moment from 'moment';

import {defaultColDef} from '../config/agGridDefaults';

export default {
	name: 'Deals',
	setup() {
		const store = defaultStore()
		return { store }
	},
	data() {
		return {
			plants: [],
			columnDefs: [
				{ headerName: this.$t('plants.Name'), field: 'plant_name',},
				{ headerName: this.$t('general.startDate'), field: 'start_date', sortable: true, filter: 'agDateColumnFilter', cellDataType: 'dateString', cellRenderer: ({ value }) => value && moment(value).format('L')},
				{ headerName: this.$t('general.endDate'), field: 'end_date', filter: 'agDateColumnFilter', cellDataType: 'dateString', cellRenderer: ({ value }) => value && moment(value).format('L')},
				{ headerName: this.$t('deals.type'), field: 'deal_type_name',},
				{ headerName: 'SAPR', field: 'plant_SAPR',},
				{ headerName: 'SPV', field: 'SPV_name',},
				{ headerName: 'Trader', field: 'trader_name',},
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: defaultColDef,
			rowData: null,
			loadingCellRenderer: null,
			rowModelType: null,
			cacheBlockSize: null,
		}
	},
	components: {
		AgGridVue,
		/* eslint-disable vue/no-unused-components */
	},
	methods: {
		plantName(params) {
			for (const [key, value] of Object.entries(this.plants)) {
				if(value.plantId === params.value) {
					return 'ID: '+key + '; Name: ' + value.name;
				}
			} 
		},
		openDetails({data}) {
			this.$router.push({path: `/forms/deals/${data.id}`});
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onGridReady(params) {
			this.gridApi = params.api;
			this.gridColumnApi = params.columnApi;
			params.api.sizeColumnsToFit();
			const dataSource = {
				getRows: async params => {
					try {
						const response = await CommercialMgmtService.getDeals(params.request)
						params.success({
							rowData: response.data.data,
							rowCount: response.data.count,
						});
					}
					catch (e) {
						console.error('error while fetching deals data', e);
						params.fail();
					}
				}
			};
			this.gridApi = params.api;
			this.gridApi.setServerSideDatasource(dataSource);
			this.columnApi = params.columnApi;
		},
	},
	async created() {
		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;
		this.loadingCellRenderer = 'CustomLoadingCellRenderer';
		this.cacheBlockSize = 100;
	}
}
</script>
