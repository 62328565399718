<template>
    <div>
        <font-awesome-icon
            v-if="params.isSelect"
            :icon="['fas', 'square-check']"
            @click="callAction('select')"
            size="lg"
            style="cursor: pointer; margin-left: 0.5em;"
            :title="$t('form.select')"
        />
        <font-awesome-icon
            v-if="params.isEdit"
            :icon="['fas', 'pen-to-square']"
            @click="callAction('edit')"
            size="lg"
            style="cursor: pointer; margin-left: 0.5em;"
            :title="$t('form.edit')"
        />
        <font-awesome-icon
            v-if="params.isDelete"
            :icon="['fas', 'trash-alt']"
            @click="callAction('delete')"
            size="lg"
            style="cursor: pointer; margin-left: 0.5em;"
            :title="$t('form.delete')"
        />
    </div>
</template>

<script>
export default {
    name: 'GridActions',
    methods: {
        callAction (action) {
            this.params.context.parent.onGridAction(action, this.params.node.data);
        },
    },
};
</script>