<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content p-0">
		<!-- BEGIN profile -->
		<div class="profile">
			<!-- BEGIN profile-header -->
			<div class="profile-header">
				<div class="profile-header-cover"></div>

				<div class="profile-header-content">
					<div class="profile-header-img">
						<img src="/assets/img/user/Portrait_Placeholder.png" alt="" />
					</div>

				</div>
			</div>
			<!-- END profile-header -->

			<!-- BEGIN profile-container -->
			<div class="profile-container">
				<!-- BEGIN profile-sidebar -->
				<div class="profile-sidebar">
					<div class="desktop-sticky-top">
						<!-- profile info -->
						<h4>{{ form.userFullname }}</h4>
						<div class="fw-600 mb-3 text-muted mt-n2">@{{ form.userRole }}</div>
						<hr class="mb-4" />
					</div>
				</div>
				<!-- END profile-sidebar -->
			</div>
			<!-- END profile-container -->
		</div>
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<!-- BEGIN col-10 -->
				<b-col xl="6">
					<b-row>

						<div class="row mt-2">
							<label for="update_user_name" class="col-md-2 col-form-label">UPN</label>
							<div class="col-md-8 mb-3">
								<b-form-input id="update_user_name" v-model="form.upn" :state="updateUpnState"
									aria-describedby="input-update_upn-feedback" placeholder="description"
									trim></b-form-input>
								<b-form-invalid-feedback id="input-update_upn-feedback">{{ $t('general.FieldNotEmpty')
								}}</b-form-invalid-feedback>
							</div>
						</div>

						<div class="row mt-2">
							<label for="update_user_fullname" class="col-md-2 col-form-label">{{ $t('profile.Fullname')
							}}</label>
							<div class="col-md-8">
								<b-form-input id="update_user_fullname" v-model="form.userFullname"
									:state="updateUserFullnameState" aria-describedby="input-update_user_fullname-feedback"
									placeholder="Full name" trim></b-form-input>
								<b-form-invalid-feedback id="input-update_user_fullname-feedback">{{ $t('general.FieldNotEmpty') }}</b-form-invalid-feedback>
							</div>
							<div class="col-md-2">
								<button class="btn btn-primary btn-lg d-block w-200 fw-500 mb-3"
									@click="updateFullName()">{{
									$t('general.Update') }}</button>
							</div>
						</div>

						<div class="row mt-2">
							<label for="update_user_email" class="col-md-2 col-form-label">E-Mail</label>
							<div class="col-md-8">
								<b-form-input id="update_user_email" v-model="form.userEmail" :state="updateUserEmailState"
									aria-describedby="input-update_user_email-feedback" placeholder="email"
									trim></b-form-input>
								<b-form-invalid-feedback id="input-update_user_email-feedback">{{ $t('general.FieldNotEmpty') }}</b-form-invalid-feedback>
							</div>
							<div class="col-md-2">
								<button class="btn btn-primary btn-lg d-block w-200 fw-500 mb-3" @click="updateEmail()">{{
									$t('general.Update') }}</button>
							</div>
						</div>

						<div class="row mt-2">
							<label for="update_user_password" class="col-md-2 col-form-label"></label>
							<div class="col-md-4">
								<button class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3"
									v-b-modal.modal-changePassword>{{ $t('profile.ChangePW') }}</button>
							</div>
						</div>

					</b-row>

				</b-col>
				<b-col xl="6">

				</b-col>
			</b-row>

			<hr class="mb-4" />

			<b-row>
				<b-col>
					<h2 class="page-header">Permissions</h2>
				</b-col>
				<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right"><a
						v-b-modal.modal-newPermission><i class="fas fa-2xl fa-fw me-2 fa-plus-square"></i></a></b-col>
			</b-row>

			<b-row>

				<b-col xl="12">
					<div style="height: 100%">
						<ag-grid-vue @grid-ready="onGridReady" style="width: 100%; height: 100%" class="ag-theme-alpine"
							:columnDefs="columnDefs" :rowData="dtable" :rowSelection="rowSelection" :pagination="true"
							:paginationPageSize="paginationPageSize" @first-data-rendered="onFirstDataRendered" />
					</div>
				</b-col>

			</b-row>

			<b-row>
				<hr class="mt-4 mb-4" />
			</b-row>

		</b-container>
		<!-- END profile -->
		<!-- BEGIN modals -->
		<ModalNewPermission />
		<ModalChangePassword />
		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";


import ModalNewPermission from '../components/ModalNewPermission';
import ModalChangePassword from '../components/ModalChangePassword';

import BtnCellRenderer from '../components/ag-grid/btnActionsCellRenderer.vue';
import TextCellEditor from '../components/ag-grid/textCellEditor.vue';
import CheckCellEditor from '../components/ag-grid/checkCellEditor.vue';
import authService from '../_services/auth.service';

import { defaultStore } from '../_store/storage';

export default {
	name: 'UserProfile',
	setup() {
		const store = defaultStore()
		return { store }
	},
	components: {
		AgGridVue,
		ModalNewPermission,
		ModalChangePassword,
		/* eslint-disable vue/no-unused-components */

		authService,
		btnCellRenderer: BtnCellRenderer,
		textCellEditor: TextCellEditor,
		checkCellEditor: CheckCellEditor,
	},
	data() {
		return {
			dtable: [],
			template: `<input type="number" v-model="value" ref="input" style="width: 100%" />`,
			form: {
				userid: '',
				upn: '',
				userFullname: '',
				userEmail: '',
				userPassword: '',
				userRole: ''
			},
			columnDefs: [
				{ headerName: 'Role', field: 'role', minWidth: 100, rowGroup: true, hide: true, maxWidth: 300, },
				{ headerName: 'Method', field: 'method', sortable: true, filter: true, minWidth: 200, maxWidth: 500, suppressSizeToFit: true, },
				{ headerName: 'Actions', pinned: 'right', minWidth: 150, maxWidth: 200, }
				//{ headerName: 'Path', field: 'path', editable: true, cellEditor: 'textCellEditor', },
				//{ headerName: 'Superadmin', field: '', editable: true, cellEditor: 'textCellEditor',  },
				/*{ headerName: 'Admin', field: '', editable: true, cellEditorSelector: () => {
						return {
							component: 'agRichSelectCellEditor',
							params: {
								values: ['Male', 'Female'],
							},
							popup: true,
						};
					}, 
				},*/
				/*{ headerName: 'Electrician', field: '', editable: true, cellEditor: 'textCellEditor',},
				{ headerName: 'Accountant', field: '', editable: true, cellEditor: 'textCellEditor', },*/
				/*{ headerName: 'actions', field: 'routes.path', minWidth:200, cellRenderer: 'btnCellRenderer', cellRendererParams: {
						clicked: function(field) {
							alert(`${field} was clicked`);
						}
					},
				}*/
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: {
				editable: true,
				resizable: true,
				flex: 1,
				minWidth: 100
			},
			rowData: null,
		}
	},
	mounted() {
		let loggedIn = JSON.parse(localStorage.getItem('user'));
		console.log(loggedIn);
		if (loggedIn != null) {
			this.form.userid = loggedIn.id || '';
			this.form.userFullname = loggedIn.name || loggedIn.fullname || '';
			/*if (loggedIn.name != null || loggedIn.name != '') {
				this.form.userFullname = loggedIn.name
			}*/
			this.form.upn = loggedIn.upn || loggedIn.username || '';
			/*if (loggedIn.upn != null || loggedIn.upn != '') {
				this.form.upn = loggedIn.upn
			}*/
			if (loggedIn.roles != null || loggedIn.roles != '') {
				this.form.userRole = loggedIn.roles[0]
			}
			if (loggedIn.email != null && loggedIn.email != '' && loggedIn.email != undefined) {
				this.form.userEmail = loggedIn.email
			}
			if (loggedIn.password != null && loggedIn.password != '' && loggedIn.password != undefined) {
				this.form.userPassword = loggedIn.password
			}
			console.log(this.form);
		}
	},
	computed: {
		updateUpnState() {
			return this.form.upn === '' ? false : true
		},
		updateUserFullnameState() {
			return this.form.userFullname === '' ? false : true
		},
		updateUserEmailState() {
			return this.form.userEmail === '' ? false : true
		},
		updateUserPasswordState() {
			return this.form.userPassword === '' ? false : true
		},
	},
	methods: {
		updateFullName() {
			let form = [
				{
					op: 'replace',
					path: '/fullname',
					value: ''
				},
			];
			form[0].value = this.form.userFullname;

			authService.changeUserData(this.form.userid, form).then((response) => {
				console.log(response.data);
			}).catch((err) => {
				console.log(err);
			});
		},
		updateEmail() {
			let form = [
				{
					op: 'replace',
					path: '/email',
					value: ''
				},
			];
			form[0].value = this.form.userEmail;

			authService.changeUserData(this.form.userid, form).then((response) => {
				console.log(response.data);
			}).catch((err) => {
				console.log(err);
			});

		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onSelectionChanged() {
			var selectedRows = this.gridApi.getSelectedRows();
			var selectedRowsString = '';
			var maxToShow = 5;
			selectedRows.forEach(function (selectedRow, index) {
				if (index >= maxToShow) {
					return;
				}
				if (index > 0) {
					selectedRowsString += ', ';
				}
				selectedRowsString += selectedRow.path;
			});
			if (selectedRows.length > maxToShow) {
				var othersCount = selectedRows.length - maxToShow;
				selectedRowsString +=
					' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
			}
			console.log(selectedRowsString);
			//this.openDetails(selectedRowsString);
			//document.querySelector('#selectedRows').innerHTML = selectedRowsString;
		},
		onGridReady(params) {
			console.log('grid ready');
			this.gridApi = params.api;
			this.columnApi = params.columnApi;
		},
		getRoles() {
			authService.getRoles().then((response) => {

				/* eslint-disable no-unused-vars */
				for (const [key, value] of Object.entries(response.data)) {
					let role = value.name;
					for (const [key, value] of Object.entries(value.permission)) {
						let array = value;
						array['role'] = role;
						this.dtable.push(array);
					}
				}

				//console.log(this.dtable);
			}).catch((err) => {
				console.log(err);
			});
		}
	},
	async created() {
		//this.routes = this.$router.options.routes;		

		this.rowSelection = 'multiple';
		this.paginationPageSize = 10;

		await this.getRoles();
	}
}
</script>