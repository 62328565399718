<template>
    <b-modal id="modal-monthlyInvoicesQuery" centered ref="modal" title="Search invoice" ok-title="SEARCH" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk" :ok-disabled="isDisabled">

		<form ref="form" @submit.stop.prevent="handleSubmit">
			
			<div class="row mt-2">
				<label for="fond_id" class="col-md-2 col-form-label">Fond</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="fond_id" v-model="form.fond" :options="fonds" :state ="fondState" aria-describedby="input-fond-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-fond-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="sapr" class="col-md-2 col-form-label">SAPR</label>
                <div class="col-md-10">
					<b-form-input id="sapr" v-model="form.sapr" :state="saprState" aria-describedby="input-sapr-feedback" placeholder="sapr" trim></b-form-input>
                    <b-form-invalid-feedback id="input-sapr-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="year" class="col-md-2 col-form-label">Year and month</label>
                <div class="col-md-10">
					<b-form-input id="year" type="month" min="2018-03" value="" v-model="form.rawDate" :state="rawDateState" aria-describedby="input-year-feedback" placeholder="year" trim></b-form-input>
					<b-form-invalid-feedback id="input-year-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>		

        </form>

    </b-modal>
</template>

<script>

import CommercialMgmtService from '../_services/CommercialMgmtService';
//import MonthlyInvoices from '../pages/CmngmInvoices';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalNewDeal',
	setup() {
		const store = defaultStore()
		return { store}
	},
	component: {
		//MonthlyInvoices
	},
    data() {
        return {
			form: {
				rawDate: '',
				year: '',
				month: '',
				sapr: '',
				fond: '',
			},
			show: true,
			fonds: [
				{ value: null, text: 'Please select fond' },
			],
        }
    },
    computed: {
		isDisabled: function(){
			return !this.rawDateState || !this.saprState || !this.fondState;
		},
		rawDateState() {
			return this.form.rawDate === '' ? false : true
		},
		saprState() {
			return this.form.sapr === '' ? false : true
		},
		fondState() {
			return this.form.fond === '' ? false : true
		},
    },
    methods: {
        isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
			this.form.rawDate = '';
            this.form.year = '';
			this.form.month = '';
			this.form.sapr = '';
			this.form.fond = '';
			this.$nextTick(() => {
				this.show = true
			});	
        },
		dateDisabled(ymd, date) {
			const weekday = date.getDay();
			return weekday >= 1 && weekday <= 5;
		},
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()						

			this.form.month = this.form.rawDate.split('-')[1];			
			this.form.year = this.form.rawDate.split('-')[0];

			CommercialMgmtService.getMonthlyInvoices( JSON.stringify(this.form) ).then((response) => {
                console.log( response.data );
				this.$parent.gridApi.setRowData( response.data )
            }).catch((err) => {
				console.log(err);
			});

            this.$nextTick(() => {
                this.$bvModal.hide('modal-monthlyInvoicesQuery');				
            })
        },
		getModalData() {
			CommercialMgmtService.getFonds( JSON.stringify(this.form) ).then((response) => {
				for (const [key, value] of Object.entries(response.data)) {
					this.fonds.push( { value: value.fondId, text: value.name, index: key } );
				} 
            }).catch((err) => {
				console.log(err);
			});
		}
    },
	created() {
		this.getModalData();
	}
}
</script>