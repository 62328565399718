import axios from 'axios';

function isLocal() {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
}

export * from '../../../edm-server/auth/constants.js';

const HOST = isLocal() ? 'localhost:3000' : 'edm.psaierenergies.it';
const PROTOCOL = isLocal() ? 'http' : 'https';

const API_URL = `${PROTOCOL}://${HOST}`;
const AUTH_URL = API_URL + '/auth';

const URLS_TO_ADD_AUTH_HEADER = [
  'https://edm.psaierenergies.it',
  'http://localhost:3000',
];

let user = getLoggedInUser();

axios.interceptors.request.use((config) => {
  if (!user) {
    return config;
  }
  // security: we only append auth headers to own url
  if (config.baseURL && !URLS_TO_ADD_AUTH_HEADER.find(u => config.baseURL.startsWith(u))) {
    return config;
  }
  if (!config.baseURL && !URLS_TO_ADD_AUTH_HEADER.find(u => config.url.startsWith(u))) {
    return config;
  }
  if (config.headers && !config.headers.authorization) {
    config.headers.authorization = user.sessionData.sessionID;
  }
  else {
    config.headers = {
      authorization: user.sessionData.sessionID,
    };
  }
  return config;
});

function getLoggedInUser() {
  let user = localStorage.getItem('user');
  if (!user) {
    return null;
  }
  user = JSON.parse(user);
  if (!user.sessionData) {
    localStorage.removeItem('user');
    return null;
  }
  let sessionData = user.sessionData;
  sessionData.sessionEnd = new Date(sessionData.sessionEnd);
  if (sessionData.sessionEnd < new Date()) {
    console.warn('session expired');
    localStorage.removeItem('user');
    return null;
  }
  return user;
}

class AuthService {
  getLoggedInUser() { return getLoggedInUser() }
  async login(name, password) {
    return await axios.post(AUTH_URL+'/login', { name, password }).then(response => {
      user = {
        name,
        ...response.data,
      };
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    });
  }
  async logout() {
    //auth/logout
    try {
      return await axios.post(AUTH_URL + '/logout');    
    }
    finally {
      user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('roles');
    }
  }
  register(user) {
    return axios.post(API_URL + '/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

  //
  getPermissions() {
    return axios.get(API_URL+'/permissions', {
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
      }
    })
  }

  getRoles() {
    return axios.get(API_URL+'/roles', {
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
      }
    })
  }

  //select or update role with relative permissions:
  saveRolePermission(id, data) {
    console.log(id, data)
    return axios.patch(API_URL+'/roles/'+id, data, {
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
      }
    })
  }

  changeUserData(id, data) {
    console.log(id, data);
    return axios.patch(API_URL+'/users/'+id, data, {
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
      }
    })
  }

  getPlantsByUser(id) {
    return axios.get(API_URL+'/users/'+id, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
        },
        responseType: "json",
    })      
}

}
export default new AuthService();