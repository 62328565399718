<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/dashboard/plants">Dashboard</b-breadcrumb-item>
					<b-breadcrumb-item :to="{path: '/dashboard/plant/'+id}">{{plant.name}}</b-breadcrumb-item>
					<b-breadcrumb-item active>Technical activity</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">			
	
					<h1 class="page-header">
						Insert <small>new technical activity</small>
					</h1>
					
					<hr class="mb-4" />			
					
					<b-card :header="plant.name +' '+plant.type+' '+plant.city">
						<b-card-text class="card-text mb-3"></b-card-text>			
						
						<b-form @submit.prevent="getFormValues" @reset="onReset" v-if="show">
							<b-list-group class="border-0" flush>
								<div>
									<b-form-datepicker id="deadline" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"  v-model="form.deadline" :state="deadlineState" aria-describedby="input-deadline-feedback" placeholder="deadline" trim></b-form-datepicker>
									<b-form-invalid-feedback id="input-deadline-feedback">Field cannot be empty</b-form-invalid-feedback>
								</div>
								<div>
									<b-form-select class="mt-2 form-select" size="lg" id="priority" v-model="form.priority" :options="priority_options" :state ="priorityState" aria-describedby="input-priority-feedback"></b-form-select>
										<b-form-invalid-feedback id="input-priority-feedback">Select an option</b-form-invalid-feedback>
								</div>
								<div>
									<b-form-input class="mt-2" id="responsible" v-model="form.responsible" :state="responsibleState" aria-describedby="input-responsible-feedback" placeholder="responsible" trim></b-form-input>
										<b-form-invalid-feedback class="mb-2" id="input-responsible-feedback">Field cannot be empty</b-form-invalid-feedback>
								</div>
								<div>
									<b-form-select class="mt-2 form-select" size="lg" id="status" v-model="form.status" :options="status_options" :state ="statusState" aria-describedby="input-status-feedback"></b-form-select>
										<b-form-invalid-feedback id="input-status-feedback">Select an option</b-form-invalid-feedback>
								</div>
								<div>
									<b-form-input class="mt-2" id="description" v-model="form.description" :state="descriptionState" aria-describedby="input-description-feedback" placeholder="description" trim></b-form-input>
										<b-form-invalid-feedback class="mb-2" id="input-description-feedback">Field cannot be empty</b-form-invalid-feedback>
								</div>
								<div>
									<b-form-input class="mt-2" id="todo" v-model="form.todo" :state="todoState" aria-describedby="input-todo-feedback" placeholder="todo" trim></b-form-input>
										<b-form-invalid-feedback class="mb-2" id="input-todo-feedback">Field cannot be empty</b-form-invalid-feedback>
								</div>
							</b-list-group>

							<hr class="mb-4" />

							<b-button-group>
								<b-button type="submit" variant="success" size="lg">SAVE</b-button>
								<b-button type="reset" variant="warning" size="lg">RESET</b-button>
							</b-button-group>

						</b-form>

					</b-card>
										
				</b-col>
			</b-row>
			
		</b-container>
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';

export default {
    name: 'PlantDetails',
    data(){
        return {
            plant: [],
			priority_options: [
				{ value: null, text: 'Please select an option' },
				{ value: 1, text: 'High' },
				{ value: 2, text: 'Medium' },
				{ value: 3, text: 'Low' }
			],
			status_options: [
				{ value: null, text: 'Please select an option' },
				{ value: 1, text: 'Done' },
				{ value: 2, text: 'Progress' },
				{ value: 3, text: 'Pending' }
			],
			form: {
				deadline: '',
				priority: null,
				responsible: '',
				status: null,
				description: '',
				todo: ''
			},
			show: true
        }
    },
	props: {
		id: {
			type: Number,
			required: true,
		},
	},
	computed: {
		deadlineState() {
			return this.form.deadline.length > 1 ? true : false
		},
		priorityState() {
			return this.form.priority === null ? false : true
		},
		responsibleState() {
			return this.form.responsible.length > 1 ? true : false
		},
		statusState() {
			return this.form.status === null ? false : true
		},
		descriptionState() {
			return this.form.description.length > 10 ? true : false
		},
		todoState() {
			return this.form.todo.length > 10 ? true : false
		},
    },
    methods: {
		// Validator function
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getPlantDetails(){
			console.log( 'Get plant details for: '+this.id );

            TechnicalMgmtService.getPlantDetails(this.id).then((response) => {
                this.plant = response.data;   
            }).catch((err) => {
				console.log(err);
				this.plant = [{"plantId":"ND","name":"ND","type":"ND","city":"ND","coeffProduction":"ND","voltage":"ND","ceNumber":"ND","pmax":"ND","sapr":"ND","pod":"ND"}];
			});
        },
		getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));

			TechnicalMgmtService.regTechnical(JSON.stringify(this.form)).then((response) => {
                console.log( response );   
            }).catch((err) => {
				console.log(err);
			});
		},
		onReset(event) {
			event.preventDefault();
			// Reset our form values
			this.form.deadline = '';
			this.form.priority = null;
			this.form.responsible = '';
			this.form.status = null;
			this.form.description = '';
			this.form.todo = '';
			this.show = false
			this.$nextTick(() => {
				this.show = true
			});			
		}
    },
    created() {
        this.getPlantDetails();
    }
}
</script>
