
<script>
import { i18n } from '@/plugins/i18n.js';
import authService, { CUSTOMER_ENTITIES_AUTHORIZATION, ROLE_NAME_ADMIN, ROLE_NAME_CUSTOMER } from '../_services/auth.service';

const user = authService.getLoggedInUser();

let userMenuPoints = [];
const allMenuPoints = [
	{
		text: i18n.t('general.TechnicalManagement'),
		is_header: true
	},
	{ url: '/grids/silver-funds', text: i18n.t('general.Funds'), name: 'silver_funds' },
	{ url: '/grids/silver-spvs', text: i18n.t('general.SPVs'), name: 'silver_SPVs' },
	{ url: '/grids/silver-plants', text: i18n.t('plants.Plants'), name: 'silver_plants' },
	{ url: '/grids/silver-deals', text: i18n.t('deals.Deals'), name: 'silver_deals' },
	{ url: '/grids/silver-plant-failures', text: i18n.t('general.Failures'), name: 'silver_plant_failures' },
	{ url: '/grids/silver-meters', text: i18n.t('meters.meters'), name: 'silver_meters' },
	{ url: '/grids/silver-datalogger-devices', text: i18n.t('dataloggers.dataloggers'), name: 'silver_datalogger_devices' },
	{ url: '/grids/silver-trader', text: 'Traders', name: 'silver_trader' },
	{ url: '/invoices', text: i18n.t('invoices.Invoices'), name: 'invoice' },
	{ url: '/grids/users', text: i18n.t('general.users'), name: 'users' },
	// {
	// 	text: i18n.t('general.TechnicalManagement'),
	// 	is_header: true
	// },
	// { url: '/dashboard/plants', text: i18n.t('plants.Plants') },
	// // { url: '/dashboard/tasks', text: i18n.t('tasks.Tasks')},
	// // { url: '/dashboard/scheduled-activities', text: i18n.t('general.ScheduledActivities')},
	// { url: '/monitoring/electric-meters', text: 'Electric Meters' },
	// { url: '/monitoring/data-loggers', text: 'Data Loggers' },
	// {
	// 	url: '/documents/',
	// 	text: i18n.t('general.Documents'),
	// 	children: [
	// 		{ url: '/documents/general-data', text: 'General data' },
	// 		{ url: '/documents/security', text: 'Security' },			
	// 		{ url: '/documents/stacking', text: 'Stacking' },
	// 		{ url: '/documents/arera', text: 'ARERA' },
	// 		{ url: '/documents/insurance', text: 'Insurance' },
	// 		{ url: '/documents/domiciliation', text: 'Domiciliation' },
	// 		/*{ url: '/documents/network', text: 'Network manager' },
	// 		{ url: '/documents/gse', text: 'GSE' },
	// 		{ url: '/documents/hse', text: 'HSE' },
	// 		{ url: '/documents/intercompany-agreement', text: 'Intercompany agreement' },
	// 		{ url: '/documents/risk-management', text: 'Risk Management' },
	// 		{ url: '/documents/tasi', text: 'TASI' },
	// 		{ url: '/documents/utf', text: 'UTF' },
	// 		{ url: '/documents/electricity-sale', text: 'Electricity sale' },*/
	// 		{ url: '/documents/om/', text: 'O&M', children: [
	// 			{ url: '/documents/om/availabilty', text: 'Availability' },
	// 			{ url: '/documents/om/load-graphs', text: 'Load graphs' },
	// 			{ url: '/documents/om/maintenance', text: 'Maintenance' },
	// 			{ url: '/documents/om/reports', text: 'Reports' },
	// 			{ url: '/documents/om/site-visit', text: 'Site visit' },
	// 			{ url: '/documents/om/tlc', text: 'TLC' },
	// 		] },			
	// 	]
	// },	
	// {
	// 	is_divider: true
	// },
	// {
	// 	text: i18n.t('general.CommercialManagement'),
	// 	is_header: true
	// },
	// { url: '/invoices/monthly-invoices', text: i18n.t('invoices.Invoices')},
	// { url: '/invoices/deals-managing', text: i18n.t('deals.Deals') },
	// { url: '/invoices/funds', text: i18n.t('general.Funds') },
	// { url: '/invoices/spvs', text: i18n.t('general.SPVs') },
	// { url: '/plant-failures/failures-managing', text: i18n.t('general.Failures')}
	// // {
	// // 	url: '/market-data/',
	// // 	text:  i18n.t('general.MarketData'),
	// // 	children: [
	// // 		{ url: '/market-data/gme-prices', text: i18n.t('market.GMEPrices') }
	// // 	]
	// // }
];

/*
{
	is_divider: true
},
{
	text: 'Examples',
	is_header: true
},
{
	url: '/widgets',
	icon: 'fa fa-qrcode',
	text: 'Widgets'
},
{
	url: '/ui/',
	icon: 'fa fa-heart',
	text: 'UI Kits',
	children: [
		{ url: '/ui/bootstrap', text: 'Bootstrap' },
		{ url: '/ui/buttons', text: 'Buttons' },
		{ url: '/ui/card', text: 'Card' },
		{ url: '/ui/icons', text: 'Icons' },
		{ url: '/ui/modal-notifications', text: 'Modal & Notifications' },
		{ url: '/ui/typography', text: 'Typography' },
		{ url: '/ui/tabs-accordions', text: 'Tabs & Accordions' }
	]
},
{
	url: '/form/',
	icon: 'fa fa-file-alt',
	text: 'Forms',
	children: [
		{ url: '/form/elements', text: 'Form Elements' },
		{ url: '/form/plugins', text: 'Form Plugins' },
		{ url: '/form/wizards', text: 'Wizards' }
	]
},
{
	url: '/table/',
	icon: 'fa fa-table',
	text: 'Tables',
	children: [
		{ url: '/table/elements', text: 'Table Elements' },
		{ url: '/table/plugins', text: 'Table Plugins' }
	]
},
{
	url: '/chart/',
	icon: 'fa fa-chart-bar',
	text: 'Charts',
	children: [
		{ url: '/chart/chart-js', text: 'Chart.js' },
		{ url: '/chart/chart-apex', text: 'Apexcharts.js' }
	]
},
{
	url: '/map',
	icon: 'fa fa-map-marker-alt',
	text: 'Map'
},
{
	url: '/layout',
	icon: 'fa fa-code-branch',
	text: 'Layout',
	children: [
		{ url: '/layout/starter-page', text: 'Starter Page' },
		{ url: '/layout/fixed-footer', text: 'Fixed Footer' },
		{ url: '/layout/full-height', text: 'Full Height' },
		{ url: '/layout/full-width', text: 'Full Width' },
		{ url: '/layout/boxed-layout', text: 'Boxed Layout' },
		{ url: '/layout/minified-sidebar', text: 'Minified Sidebar' }
	]
},
{
	url: '/page/',
	icon: 'fa fa-globe',
	text: 'Pages',
	children: [
		{ url: '/page/scrum-board', text: 'Scrum Board' },
		{ url: '/page/product', text: 'Products' },
		{ url: '/page/order', text: 'Orders' },
		{ url: '/page/gallery', text: 'Gallery' },
		{ url: '/page/search-results', text: 'Search Results' },
		{ url: '/page/coming-soon', text: 'Coming Soon Page' },
		{ url: '/page/error', text: 'Error Page' },
		{ url: '/user/login', text: 'Login' },
		{ url: '/page/register', text: 'Register'}
	]
},
{
	url: '/profile',
	icon: 'fa fa-user-circle',
	text: 'Profile'
},
{
	url: '/calendar',
	icon: 'fa fa-calendar',
	text: 'Calendar'
},
{
	url: '/settings',
	icon: 'fa fa-cog',
	text: 'Settings'
},
{
	url: '/helper',
	icon: 'fa fa-question-circle',
	text: 'Helper'
}*/

if (user) {
	const userRoles = user.userRoleInfo;
	const userRoleNames = userRoles.map(role => role.name);
	if (userRoleNames.includes(ROLE_NAME_ADMIN)) {
		userMenuPoints = allMenuPoints;
	} else if (userRoleNames.includes(ROLE_NAME_CUSTOMER)) {
		const customerEntities = CUSTOMER_ENTITIES_AUTHORIZATION.map(entity => entity.entity);
		userMenuPoints = allMenuPoints.filter(point => !point.name || customerEntities.includes(point.name));
	}
}

export default userMenuPoints;
</script>
