import { defineStore } from "pinia";

export const useFormStates = defineStore('form', {
    state: () => ({
        forms: [],
    }),
    getters: {
        getForm: (state) => {
            return (entityName, id) => {
                if (!id) {
                    id = 'new';
                }
                const form = state.forms.find(form => form.entityName === entityName && form.id === id);
                return form;
            }
        },
    },
    actions: {
        setForm (entityName, id, data) {
            if (!id) {
                id = 'new';
            }
            const form = this.getForm(entityName, id);
            if (form) {
                form.data = data;
                return;
            }
            this.forms.push({
                entityName,
                id,
                data,
            });
        },
        deleteForm (entityName, id) {
            const formIndex = this.forms.findIndex(f => f.entityName === entityName && f.id === id);
            this.forms.splice(formIndex, 1);
        },
    }
});
