<template>
    <b-modal id="modalMonthlyInvoicesIssue" centered size="md" :visible="visible" title="Issue Invoice" ok-title="Ok" ok-variant="success" cancel-title="Cancel" cancel-variant="danger" @show="show" @hidden="hide" @ok="handleOk">

        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="row mt-2 justify-content-between">
                <label for="numberInput" class="col-sm-2 col-form-label">Number:</label>
                <div class="col-md-8">
                    <b-form-input id="numberInput" class="form-input" v-model="form.number"></b-form-input>
                </div>
            </div>

            <div class="row mt-2 justify-content-between">
                <label for="dateSelect" class="col-sm-2 col-form-label">Date:</label>
                <div class="col-md-8">
                    <b-form-input id="dateSelect" class="form-date" :disabled="true" v-model="form.date" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-input>
                </div>
            </div>

            <div class="row mt-2 justify-content-between">
                <label for="dateDueSelect" class="col-sm-2 col-form-label">Date Due:</label>
                <div class="col-md-8">
                    <b-form-input id="dateDueSelect" class="form-date" :disabled="true" v-model="form.dateDue" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-input>
                </div>
            </div>

        </form>

    </b-modal>
</template>

<script>

import CommercialMgmtService from '../_services/CommercialMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalMonthlyInvoicesIssue',
    model: {
        prop: 'item',
        event: 'input',
    },
    props: {
        item: {
            default: null,
        },
    },
    computed: {
        visible() {
            return this.item !== null;
        }
    },
    setup() {
        const store = defaultStore();
        return { store };
    },
    data() {
        return {
            form: {
                number: '',
                date: '',
                dateDue: ''
            }
        }
    },
    methods: {
        getDateString(ts) {
            return String(ts.getDate()).padStart(2, "0") + "-" + String(ts.getMonth() + 1).padStart(2, "0") + "-" + ts.getFullYear();
        },
        async getDeal(dealId) {
            let deal = {};

            await CommercialMgmtService.getDealDetails(dealId).then((response) => {
                deal = response.data;
            });

            return deal;
        },
        async getPaymentCondition(paymentConditionId) {
            let paymentCondition = {};

            await CommercialMgmtService.getPaymentConditionDetails(paymentConditionId).then((response) => {
                paymentCondition = response.data;
            });

            return paymentCondition;
        },
        async show() {
            let deal = await this.getDeal(this.item.deal_id);
            let paymentCondition = await this.getPaymentCondition(deal.idCondPay);
            let code = paymentCondition.code;

            let dt = new Date();
            let dts = this.$parent.getDateStringDDMMYYYY(dt);
            let dtd = dt;
            let tokens = code.trim().toLowerCase().split("+");

            if (tokens[0] != "r" && tokens[0] != "p") {
                throw "Invalid Payment Condition Code";
            }
            if (!tokens[1].includes("gg") && !tokens[1].includes("mm")) {
                throw "Invalid Payment Condition Code";
            }
            if (tokens[0] == "p") {
                dtd = new Date(deal.year, deal.month, 1);
            }
            if (tokens[1].includes("gg")) {
                dtd.setDate(dtd.getDate() + parseInt(tokens[1].replace("gg", "")));
            }
            else if (tokens[1].includes("mm")) {
                dtd.setMonth(dtd.getMonth() + parseInt(tokens[1].replace("mm", "")));
            }

            let dtds = this.$parent.getDateStringDDMMYYYY(dt);

            this.form.number = '';
            this.form.date = dts;
            this.form.dateDue = dtds;
        },
        hide() {
            this.form.number = '';
            this.form.date = '';
            this.form.dateDue = '';
            this.$emit('input', null);
        },
        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (this.form.number == "") {
                this.$alert("Please enter a number for the new invoice", "Error", "error");
            }
            else {
                await this.$parent.issueInvoice(this.item, this.form);
                this.hide();
            }
        },
    }
}
</script>