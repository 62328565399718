function isLocal() {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
}

const HOST = isLocal() ? 'localhost:3000' : 'edm.psaierenergies.it';
const PROTOCOL = isLocal() ? 'http' : 'https';
const SERVICE_PATH_PREFIX_TECHNICAL_MANAGEMENT = 'services/technical-management';

export const API_BASE_URL = `${PROTOCOL}://${HOST}/${SERVICE_PATH_PREFIX_TECHNICAL_MANAGEMENT}`;
export const API_V2_BASE_URL = `${PROTOCOL}://${HOST}/api/v2`;

export function defaultGridSort(gridRequest) {
    if (gridRequest.sortModel.length === 0) {
        gridRequest.sortModel.push({
            colId: 'id',
            sort: 'DESC',
        });
    }
    return gridRequest;
}

export function getAPIV2BaseRoute() {
    return API_V2_BASE_URL;
}

export function getAPIObjectURL(entityName, id) {
    let baseURL = `${API_V2_BASE_URL}/${entityName.toLowerCase().replace(/_/g, '-')}`;
    if (id) {
      baseURL += `/${id}`;
    }
    return baseURL;
}

class TechnicalMgmtService {
}

export default new TechnicalMgmtService();