<template>
    <b-modal id="modal-addElectricMeters" centered ref="modal" title="Add electric meter" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">

		<b-row>
			<b-col><h5 class="page-header">connect electric meter to datalogger</h5></b-col>
		</b-row>	
	
		<hr class="mb-4" />

		<b-row>
			<b-col>
				<label>Filter by Number:</label>
				<input class="form-control" v-model="filters.ceNumber.value"/>
			</b-col>
			
			<b-col>
				<label>Filter by Plant:</label>
				<input class="form-control" v-model="filters.plantId.value"/>
			</b-col>
			<b-col>
				<label>Filter by Logger:</label>
				<input class="form-control" v-model="filters.loggerId.value"/>
			</b-col>
		</b-row>
		
		<table class="table table-striped">
			<thead slot="head">
				<th>Ce Number</th>
				<th>Plant Id</th>
				<th>Logger Id</th>
			</thead>
			<tbody slot="body" slot-scope="{displayData}">
				<tr v-for="row in displayData" :key="row.guid">
					<td> {{ row.ceNumber }}</td>
					<td> {{ row.plantId }}</td>
					<td> {{ row.loggerId }} </td>
					<td> <a @click="openDetails(row.plantId)"><i class="fas fa-lg fa-fw me-2 fa-plus"></i></a> </td>
				</tr>
			</tbody>
		</table>

		<smart-pagination
			:currentPage.sync="currentPage"
			:totalPages="totalPages"
			:maxPageLinks="maxPageLinks"
		/>


    </b-modal>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalAddElectricMeters',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {
			celist: [{ "ceId": "ND", "ceNumber": "ND", "plantId": "ND", "loggerId": "ND" }],
			currentPage: 1,
			totalPages: 0,
			maxPageLinks: 5,
			filters: {
				ceNumber: { value: '', keys: ['ceNumber'] },
				plantId: { value: '', keys: ['plantId'] },
				loggerId: { value: '', keys: ['loggerId'] }
			}
        }
    },
    computed: {
		
    },
    methods: {
        getElectricMeters() {
			console.log('Get electric meters list:');
			this.store.setPlantID(null);

			TechnicalMgmtService.getElectricMeters().then((response) => {
				if(response.data.length != 0) {
					const result = response.data.filter(item => item.loggerId === null)/*.sort((a, b) => b.count - a.count);*/;
					result.length > 0?this.celist = result:null;
				}							
			}).catch((err) => {
				console.log(err);
			});
		},
		resetModal() {
			
			this.$nextTick(() => {
				this.show = true
			});	
        },
		handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

            this.$nextTick(() => {
                this.$bvModal.hide('modal-addScheduled')
            })
        },
    },
	created() {
		this.getElectricMeters();
	}
}
</script>