<template>
    <b-modal id="modal-newScheduled" centered ref="modal" title="New Scheduled activity" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk" :ok-disabled="isDisabled">
        <form ref="form" @submit.stop.prevent="handleSubmit">
			
			<div class="row mt-2">
				<label for="plant" class="col-md-2 col-form-label">Plant</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="plant" v-model="form.plant.plantId" :options="plants" :state ="plantState" aria-describedby="input-reason-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-reason-feedback">Select plant</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="responsible" class="col-md-2 col-form-label">Responsible</label>
				<div class="col-md-10">
					<b-form-input id="responsible" v-model="form.responsible" :state="responsibleState" aria-describedby="input-responsible-feedback" placeholder="responsible" trim></b-form-input>
					<b-form-invalid-feedback id="input-responsible-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>

			<div class="row mt-2">
				<label for="programmed" class="col-md-2 col-form-label">Date programmed</label>
				<div class="col-md-10">
					<b-form-datepicker id="programmed" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.startDate" :state="programmedState" aria-describedby="input-programmed-feedback" placeholder="programmed" trim></b-form-datepicker>
					<b-form-invalid-feedback id="input-programmed-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="effective" class="col-md-2 col-form-label">Date effective</label>
				<div class="col-md-10">
					<b-form-datepicker class="mt-2" id="effective" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.effective" placeholder="effective" trim></b-form-datepicker>
					<!--b-form-invalid-feedback id="input-effective-feedback">Field cannot be empty</b-form-invalid-feedback-->
				</div>
			</div>

			<div class="row mt-2">
				<label for="supplier" class="col-md-2 col-form-label">Supplier</label>
				<div class="col-md-10">
					<b-form-input class="mt-2" id="supplier" v-model="form.supplier" placeholder="supplier" trim></b-form-input>
					<!--b-form-invalid-feedback id="input-supplier-feedback">Field cannot be empty</b-form-invalid-feedback-->
				</div>
			</div>

			<div class="row mt-2">
				<label for="ref_person" class="col-md-2 col-form-label">Reference person</label>
				<div class="col-md-10">
					<b-form-input class="mt-2" id="ref_person" v-model="form.ref_person" placeholder="reference person" trim></b-form-input>
					<!--b-form-invalid-feedback id="input-ref_person-feedback">Field cannot be empty</b-form-invalid-feedback-->
				</div>
            </div>
                        
			<div class="row mt-2">
				<label for="description" class="col-md-2 col-form-label">Description</label>
				<div class="col-md-10">
					<b-form-input class="mt-2" id="description" v-model="form.taskDescription" :state="descriptionState" aria-describedby="input-description-feedback" placeholder="description" trim></b-form-input>
					<b-form-invalid-feedback id="input-description-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>
            
			<div class="row mt-2">
				<label for="todo" class="col-md-2 col-form-label">To do</label>
				<div class="col-md-10">
					<b-form-input id="todo" v-model="form.toDo" :state="todoState" aria-describedby="input-todo-feedback" placeholder="todo" trim></b-form-input>
					<b-form-invalid-feedback id="input-todo-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>

			<div class="row mt-2">
				<label for="progress" class="col-md-2 col-form-label">Progress</label>
				<div class="col-md-10">
					<b-form-input class="mt-2" type="number" min="0" max="100" step="10" id="progress" v-model="form.progress" placeholder="progress in %" :state ="progressState" aria-describedby="input-progress-feedback" trim></b-form-input>
					<b-form-invalid-feedback id="input-progress-feedback">Must be between 0 and 100</b-form-invalid-feedback>
				</div>
            </div>

			<div class="row mt-2">
				<label for="status" class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="status" v-model="form.status" :options="status_options"></b-form-select>
				</div>
			</div>

			<div class="row mt-2">
				<label for="comments" class="col-md-2 col-form-label">Comments</label>
				<div class="col-md-10">
					<b-form-textarea class="mt-2" id="comments" v-model="form.comments" placeholder="insert your comments" trim></b-form-textarea>
				</div>
            </div>

        </form>
    </b-modal>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';
import moment from 'moment';

export default {
    name: 'ModalNewScheduled',
	setup() {
		const store = defaultStore()
		return { store }
	},
    data() {
        return {		
			status_options: [
				{ value: null, text: 'Please select an option' },
				{ value: 1, text: 'Done' },
				{ value: 2, text: 'Progress' },
				{ value: 3, text: 'Pending' }
			],
			form: {
				effective: '',
				startDate: '',
				supplier: '',
				ref_person: '',
				responsible: '',
				taskDescription: '',
				plant: { plantId: this.store.plantID },
				toDo: '',
				progress: 0,
				status: 3,
				comments: '',
			},
			show: true,
			plants: [
				{ value: null, text: 'Please select plant' },
			]
        }
    },
    computed: {
		isDisabled: function(){
			return !this.plantState || !this.responsibleState || !this.programmedState || !this.descriptionState || !this.todoState
		},
		plantState() {
			return this.form.plant.plantId > 0 ? true : false
		},
		responsibleState() {
			return this.form.responsible === '' ? false : true
		},
		programmedState() {
			return this.form.startDate.length > 1 ? true : false
		},
		descriptionState() {
			return this.form.taskDescription === '' ? false : true
		},
		todoState() {
			return this.form.toDo === '' ? false : true
		},
		progressState() {
			return this.form.progress < 0 && this.form.progress > 100 ? false : true
		},
    },
    methods: {
        /* MODAL */
        // Validator function
		shorten(params) {
			return moment(String(params)).format('DD/MM/YYYY');
		},
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
			this.form.startDate = '';
            this.form.deadline = '';
			this.form.responsible = '';
			this.form.taskDescription = '';
			this.form.toDo = '';
			this.show = false
			
			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

			//const today = new Date();
			//const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
			this.form.startDate = this.form.startDate+'T00:00:00';
			this.form.deadline = this.form.deadline+'T00:00:00';
			
			console.log(JSON.stringify(this.form));

			TechnicalMgmtService.regScheduled( JSON.stringify(this.form) ).then((response) => {
                //console.log( response.status );   
				if( response.status == 201 ) {
					//get technical after register
					TechnicalMgmtService.getScheduled().then((response) => {
						console.log(response);
						this.$parent.gridApi.setRowData( response.data )
					}).catch((err) => {
						console.log(err);
						this.technical = [{ "deadline": "2022-06-20 02:00:00.000", "priority": "ND", "responsible": "ND", "status": "ND", "toDo": "ND" }];
					});
				}				 
            }).catch((err) => {
				console.log(err);
			});
 
            this.$nextTick(() => {
                this.$bvModal.hide('modal-newScheduled')
            })
        },
		getModalData() {
			TechnicalMgmtService.getPlants( JSON.stringify(this.form) ).then((response) => {
				let array = this.sortedArray(response.data);

				
				for (const [key, value] of Object.entries(array)) {
					this.plants.push( { value: value.plantId, text: value.name, index: key } );
				} 
            }).catch((err) => {
				console.log(err);
			});
		},
		sortedArray(arrays) {
			function compare(a, b) {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			}
			return arrays.sort(compare);
		}	
    },
	created() {
		this.getModalData();
	}
}
</script>