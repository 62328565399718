<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<b-breadcrumb>
					<b-breadcrumb-item href="/monitoring/electric-meters">ElectricMeters</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ electricMeter.id }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">
					<h1 class="page-header">
						Electric Meter
					</h1>
					<hr class="mb-4" />
				</b-col>
				<b-col xl="6">
					<b-card :header="'Serial: '+electricMeter.serialNumber ">
						<b-card-text class="card-text mb-3"></b-card-text>

						<b-list-group class="border-0" flush>
							<b-list-group-item><small>Ident: </small>{{ electricMeter.ident }}</b-list-group-item>
							<b-list-group-item><small>Last read: </small>{{ electricMeter.readTs }}</b-list-group-item>
							<b-list-group-item><small>Plant name: </small>{{ plantDto.plantName }}</b-list-group-item>
							<b-list-group-item><small>Sapr: </small>{{ plantDto.sapr }}</b-list-group-item>
							
						</b-list-group>

					</b-card>

				</b-col>

				<b-col xl="6"></b-col>

			</b-row>

			<hr class="mb-4" />

		</b-container>
		<!-- BEGIN modals -->

		<!-- END modals -->
	</div>
	<!-- END #content -->
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import CommercialMgmtService from '../_services/CommercialMgmtService';

import { defaultStore } from '../_store/storage';
import moment from 'moment';

export default {	
	name: 'MonitoringElectricMeterDetails',
	setup() {
		const store = defaultStore()
		return { store }
	},	
	components: {

	},
	data() {
		return {
			electricMeter: [],
			plantDto: [],
			series: [],
			form: {
				start: '',
				end: ''
			},
			chartOptions: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight'
				},
				title: {
					text: 'Production kW/h',
					align: 'left'
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						opacity: 0.5
					},
				},
				noData: {
					text: 'No data.'
				},
				xaxis: {
					type: 'datetime',
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						showDuplicates: false,
						datetimeFormatter: {
							year: 'yyyy',
							month: "MMM 'yy",
							day: 'dd MMM',
							hour: 'HH:mm',
						},
					}
				}
			},
		}
	},
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	mounted() {
		//this.getProductionUpnr();
	},
	created() {
		//this.store.setCeID(this.id);
		this.getElectricMeterDetails();
		this.getProductionUpnr();
	},
	methods: {
		setDataLineChart() {
			
		},
		getElectricMeterDetails() {
			// Trovo il valore impostato nella form precedente
			//console.log(this.store.plantID)
			//console.log('Get Electric Meter details for: ' + this.id);

			TechnicalMgmtService.getElectricMeterDetails(this.id).then((response) => {
				console.log( response.data );
				this.electricMeter = response.data;
				this.plantDto = response.data.plantDto;				 
			}).catch((err) => {
				console.log(err);
			});
		},
		getProductionUpnr( params ) {

			this.series = [];
			this.chartOptions.xaxis.categories.splice(0);

			if(params == undefined) return;

			this.form.start = moment(params.start).format('DD.MM.YYYY');
			this.form.end = moment(params.end).format('DD.MM.YYYY');					

			CommercialMgmtService.getProductionUPNR( params ).then((response) => {				

				let data = [];

				for (const [key, value] of Object.entries(response.data)) {
					this.key = key;
					
					if( this.series[value.version-1] === undefined ) {
						this.series[value.version-1] = { name: 'version: '+value.version, data: [] };	
					}			
					this.series[value.version-1].data.push(value.value);
					this.chartOptions.xaxis.categories.push( value.ts );
				}
				
				for (let i = 0; i < this.series.length; i++) {						
					data.push( { data: this.series[i].data, name: this.series[i].name  } );
				}

				this.$refs.realtimeChart.updateSeries( data , false, true);

            }).catch((err) => {
				console.log(err);
			});
		}
	}
}
</script>
