<template>
    <b-modal id="modal-changePassword" centered ref="modal" :title=" this.$t('profile.ChangePW') " :ok-title="this.$t('general.Save')" :ok-disabled="okdisable" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            
			<div class="row mt-2">
				<label for="oldpass" class="col-md-2 col-form-label">{{ $t('profile.password.OldPassword') }}</label>
				<div class="col-md-10">
					<b-form-input type="password" id="oldpass" v-model="password.old" :state="oldPasswordState" aria-describedby="input-oldpass-feedback"  trim></b-form-input>
					<b-form-invalid-feedback id="input-oldpass-feedback">{{ $t('general.FieldNotEmpty') }}</b-form-invalid-feedback>
				</div>
            </div>
			
			<div class="row mt-2">
				<label for="newpass" class="col-md-2 col-form-label">{{ $t('profile.password.NewPassword') }}</label>
                <div class="col-md-10">
					<b-form-input type="password" id="newpass" v-model="password.new" :state="newPasswordState" aria-describedby="input-newpass-feedback"  trim></b-form-input>
                    <b-form-invalid-feedback id="input-newpass-feedback">{{ $t('general.FieldNotEmpty') }}</b-form-invalid-feedback>
				</div>
			</div> 

			<div class="row mt-2">
				<label for="confirmpass" class="col-md-2 col-form-label">{{ $t('profile.password.ConfirmPassword') }}</label>
                <div class="col-md-10">
					<b-form-input type="password" id="confirmpass" v-model="password.confirm" :watch="confirmPasswordState" :state="confirmPasswordState" aria-describedby="input-confirmpass-feedback" trim></b-form-input>
                    <b-form-invalid-feedback id="input-confirmpass-feedback">{{ $t('profile.password.NotMatching') }}</b-form-invalid-feedback>
				</div>
			</div> 
			
        </form>
		
    </b-modal>
</template>

<script>

//import authService from '../_services/auth.service';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalchangePassword',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {
			password: {
				old: '',
				new: '',
				confirm: ''
			},
			form: [
				{
					op: 'replace',
					path: '/password',
					value: ''
				},
			],
			show: true,
			okdisable: true,
        }
    },
    computed: {
		oldPasswordState() {
			return this.password.old === '' ? false : true
		},
		newPasswordState() {
			return this.password.new === '' ? false : true
		},
		confirmPasswordState() {
			return this.password.confirm === '' ? false : this.password.confirm === this.password.new ? true : false;
		},
    },
	watch: { 
		confirmPasswordState() {
			if(this.password.confirm === '' && this.password.confirm === this.password.new) {
				this.okdisable = false;
			} else {
				this.okdisable = true;
			}
		},
	},
    methods: {
        /* MODAL */
        // Validator function
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
            this.form[0].value = '';
			this.password.old = '';
			this.password.new = '';
			this.password.confirm = '';

			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

			if(this.password.confirm === this.password.new) {
				this.form[0].value = this.password.confirm;
				console.log(JSON.stringify(this.form));

				//changePassword()

			} else {
				console.log('')
			}

            this.$nextTick(() => {
                this.$bvModal.hide('modal-newPermission');
            })
        },
    },
	created() {

	}
}
</script>