<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container style="max-width:none;">
            <!-- BEGIN row -->
            <b-row class="justify-content-center">
                <b-breadcrumb>
                    <b-breadcrumb-item href="#">{{ $t('invoices.Invoices') }}</b-breadcrumb-item>
                </b-breadcrumb>
                <!-- BEGIN col-10 -->
                <b-col xl="12">

                    <b-row>
                        <b-col>
                            <h2 class="page-header">{{ $t('invoices.Invoices') }}</h2>
                            <div class="mx-3">
                                <select class="form-select-sm mx-2" v-on:change="onPageSizeChanged()"
                                    id="page-size">
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <option value="all">{{ $t('general.All') }}</option>
                                    <!-- <option value="1000">{{ paginationGetRowCount() }}</option> -->
                                </select>
                                <button type="button" class="btn btn-outline-primary mx-2 btn-sm"
                                    @click="exportDetailSelection()" :disabled="isMassiveRowCount">{{
                                        $t('invoices.ExportDetails') }}</button>

                                <button type="button" class="btn btn-outline-primary mx-2 btn-sm" 
                                @click="issueInvoiceSelection()" :disabled="isSelection">{{ $t('invoices.IssueInvoices') }}</button>

                                <button type="button" class="btn btn-outline-primary mx-2 btn-sm"
                                    @click="exportXMLSelection()" :disabled="isMassiveRowCount">{{
                                        $t('invoices.DownloadXML')
                                    }}</button>

                                <button type="button" class="btn btn-outline-primary mx-2 btn-sm"
                                    @click="exportPDFSelection()" :disabled="isMassiveRowCount">{{
                                        $t('invoices.DownloadPDF')
                                    }}</button>

                                <button type="button" class="btn btn-outline-primary mx-2 btn-sm" @click="publishInvoiceSelection()" 
                                :disabled="isSelection">{{
                                 $t('invoices.Publish')}}
                                </button>
                            </div>
                        </b-col>
                        <b-col md="1" sm="6" xs="3" class="mb-1 text-truncate" style="text-align: right">
                            <a v-b-modal.modal-monthlyInvoicesGenerate><i class="fas fa-lg fa-fw me-2 fa-plus"></i></a>
                            <a v-b-modal.modal-monthlyInvoicesQuery><i
                                    class="fas fa-lg fa-fw me-2 fa-search"></i></a></b-col>
                    </b-row>

                    <hr class="mb-4" />

                    <b-row>
                        <b-col xl="12">
                            <div style="height: 100%">
                                <ag-grid-vue @grid-ready="onGridReady" style="width: 100%; height: 700px"
                                    class="ag-theme-alpine" :columnDefs="columnDefs" :rowData="invoices"
                                    :context="context" :rowMultiSelectWithClick="true" rowSelection="multiple"
                                    suppressRowClickSelection="true" @first-data-rendered="onFirstDataRendered"
                                    :pagination="true" :defaultColDef="defaultColDef" :statusBar="statusBar"
                                    :paginationPageSize="paginationPageSize" @selection-changed="onSelectionChanged"
                                    @grid-size-changed="resizeColumns"
                                    />

                            </div>
                        </b-col>
                    </b-row>

                </b-col>
            </b-row>
        </b-container>
        <!-- BEGIN modals -->
        <ModalMonthlyInvoicesQuery />
        <ModalProductionStatsQuery />
        <ModalMonthlyInvoicesGenerate />
        <ModalMonthlyInvoicesIssue v-model="currentItem" />
        <InvoiceActionIcons v-show="false" />
        <!-- END modals -->
    </div>
</template>

<script>
/**
 * per aggiornare i dati:
 * https://github.com/Psaier-Energies/python/tree/main/psaier_edm
 */
import CommercialMgmtService from '../_services/CommercialMgmtService';
import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import MailService from '../_services/MailService';
import { defaultStore } from '../_store/storage';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

import ModalMonthlyInvoicesQuery from '../components/ModalMonthlyInvoicesQuery';
import ModalProductionStatsQuery from '../components/ModalProductionStatsQuery';
import ModalMonthlyInvoicesGenerate from '../components/ModalMonthlyInvoicesGenerate';
import InvoiceActionIcons from "../components/ag-grid/InvoiceActionIcons";
import ModalMonthlyInvoicesIssue from '../components/ModalMonthlyInvoicesIssue';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import moment from 'moment';

export default {
    mounted() {
        // window.addEventListener('resize', this.resizeColumns);
        // this.gridOptions.api.addEventListener('selectionChanged', this.onSelectionChanged);
		// this.gridOptions.api.addEventListener('gridSizeChanged', this.resizeColumns);
    },

    name: 'Invoices',
    setup() {
        document.title = 'Invoices';
        const store = defaultStore()
        return { store }
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('user')), invoices: [],
            //datatable
            currentItem: null,
            currentPage: 1,
            totalPages: 0,
            maxPageLinks: 5,
            //column filter
            filters: {
                year: { value: '', keys: ['year'] },
                month: { value: '', keys: ['month'] },
                sapr: { value: '', keys: ['sapr'] },
                fond_name: { value: '', keys: ['fond_name'] },
            },
            columnDefs: [
                {
                    field: 'fond_name', headerName: this.$t('general.Fund'), headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true
                },
                { field: 'spv_name', headerName: 'SPV', filter: true, },
                { field: 'plant_name', headerName: this.$t('plants.Plant'), },
                { field: 'sapr', headerName: 'SAPR', },
                { field: 'year', headerName: this.$t('general.Year'), },
                { field: 'month', headerName: this.$t('general.Month'), },
                { field: 'quantity', headerName: this.$t('invoices.Quantity'), },
                {
                    field: 'amount', headerName: this.$t('invoices.Amount'), valueFormatter: params => params.data.amount.toLocaleString('en') + '€',
                },
                // { field: 'status', headerName: 'Status', filter: true, sortable: true },
                { field: 'number', headerName: this.$t('general.Number'), },
                { field: 'date', headerName: this.$t('general.Date'), },
                { field: 'date_due', headerName: this.$t('invoices.DueDate'), },
                // { field: 'invoice_id', headerName: this.$t( 'invoices.InvoiceID' ), hide: true },
                // { field: 'deal_id', headerName: this.$t( 'Deal ID' ), hide: true },
                { headerName: this.$t('tasks.State'), cellRenderer: 'InvoiceActionIcons', colId: 'params', }
            ],

            gridApi: null,
            columnApi: null,
            defaultColDef: {
                suppressMovable: true,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
            },
            rowData: null,
            paginationPageSize: null,
            selectedRowCount: 0,
        }
    },
    components: {
        AgGridVue,
        InvoiceActionIcons,
        ModalMonthlyInvoicesQuery,
        ModalProductionStatsQuery,
        ModalMonthlyInvoicesGenerate,
        ModalMonthlyInvoicesIssue,
    },
    computed: {
        isMassiveRowCount() { // If many rows are collected don't allow certain actions like download
            return this.selectedRowCount > 10 || this.selectedRowCount == 0;
        },
        isSelection() {
            return this.selectedRowCount == 0
        },
    },
    beforeMount() {
        this.getInvoicesByUser();
        this.context = { componentParent: this };
    },

    methods: {
        onSelectionChanged() {
            this.selectedRowCount = this.gridApi.getSelectedNodes().length;
        },
        resizeColumns() {
            // Adapt column size when viewport or the grid size changes
            this.gridApi.sizeColumnsToFit({
                // defaultMinWidth: 100,
                columnLimits: [
                    { key: 'spv_name', minWidth: 200 },
                    { key: 'fond_name', minWidth: 150 },
                    { key: 'year', maxWidth: 100 },
                    { key: 'month', maxWidth: 100 }
                ],
            });
        },
        onPageSizeChanged() {
            var value = document.getElementById('page-size').value;
            if (value === 'all') {
                value = this.gridApi.paginationGetRowCount()
            }
            this.gridApi.paginationSetPageSize(Number(value));
            console.log()
        },
        createDate(year, month, day, hour, minute, second) {
            var ts = new Date();
            ts.setFullYear(year, month - 1, day);
            ts.setHours(hour);
            ts.setMinutes(minute);
            ts.setSeconds(second);
            ts.setMilliseconds(0);
            return ts;
        },
        /**
         * timestamp: change to moment
         */
        getCurrentTimeStamp() {
            return moment().format('YYYYMMDDHHmmss');
            /*
            let ts = new Date();
            console.log( ts.getFullYear() + String(ts.getMonth() + 1).padStart(2, "0") + String(ts.getDate()).padStart(2, "0") + String(ts.getHours()).padStart(2, "0") + String(ts.getMinutes()).padStart(2, "0") + String(ts.getSeconds()).padStart(2, "0") );

            return ts.getFullYear() + String(ts.getMonth() + 1).padStart(2, "0") + String(ts.getDate()).padStart(2, "0") + String(ts.getHours()).padStart(2, "0") + String(ts.getMinutes()).padStart(2, "0") + String(ts.getSeconds()).padStart(2, "0");
            */
        },
        /**
         * ISO (T) timestamp: change to moment 
         */
        getISODateString(ts) {
            return moment(ts).format('YYYY-MM-DDTHH:mm:ss');
            //return ts.getFullYear() + "-" + String(ts.getMonth() + 1).padStart(2, "0") + "-" + String(ts.getDate()).padStart(2, "0") + "T" + String(ts.getHours()).padStart(2, "0") + ":" + String(ts.getMinutes()).padStart(2, "0") + ":" + String(ts.getSeconds()).padStart(2, "0");
        },
        /**
         * various formats: to use moment
         */
        getDateString(ts) {
            return moment(ts).format('YYYY-MM-DD HH:mm:ss');
            //return ts.getFullYear() + "-" + String(ts.getMonth() + 1).padStart(2, "0") + "-" + String(ts.getDate()).padStart(2, "0") + " " + String(ts.getHours()).padStart(2, "0") + ":" + String(ts.getMinutes()).padStart(2, "0") + ":" + String(ts.getSeconds()).padStart(2, "0");
        },
        getDateStringDDMMYYYY(ts) {
            return moment(ts).format('DD-MM-YYYY');
            //return String(ts.getDate()).padStart(2, "0") + "-" + String(ts.getMonth() + 1).padStart(2, "0") + "-" + ts.getFullYear();
        },
        getDateStringYYYYMMDD(ts) {
            return moment(ts).format('YYYY-MM-DD');
            //return ts.getFullYear() + "-" + String(ts.getMonth() + 1).padStart(2, "0") + "-" + String(ts.getDate()).padStart(2, "0");
        },
        /**
         * get easter monday: to use moment
         */
        getEasterMonday(ts) {
            var y = ts.getFullYear();
            var a = y % 19;
            var b = y % 4;
            var c = y % 7;
            var M = 24;
            var N = 5;
            var d = (19 * a + M) % 30;
            var e = (2 * b + 4 * c + 6 * d + N) % 7;

            var easter = 0;
            if (d + e == 35) {
                easter = 50;
            } else if (d == 28 && e == 6 && ((11 * M + 11) % 30) < 19) {
                easter = 49;
            } else {
                easter = 22 + d + e;
            }

            var t = this.createDate(y, 3, 1, 0, 0, 0);
            t.setDate(t.getDate() + easter);
            return t;
        },
        /** 
         * Italian holidays
        */
        isHoliday(ts) {
            var t = this.createDate(ts.getFullYear(), ts.getMonth() + 1, ts.getDate(), 0, 0, 0);
            var holidays = [];
            holidays.push(this.createDate(t.getFullYear(), 1, 1, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 1, 6, 0, 0, 0));
            holidays.push(this.getEasterMonday(t));
            holidays.push(this.createDate(t.getFullYear(), 4, 25, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 5, 1, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 6, 2, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 8, 15, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 11, 1, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 12, 8, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 12, 25, 0, 0, 0));
            holidays.push(this.createDate(t.getFullYear(), 12, 26, 0, 0, 0));

            for (var i = 0; i < holidays.length; i++) {
                if (holidays[i].getTime() == t.getTime()) return true;
            }
            return false;
        },
        /**
         * calculation of F1 F2 F3
         */
        getFascia(ts) {
            var fascia = 0;
            /**
             * time now
             */
            var time = ts.getHours() + String(ts.getMinutes()).padStart(2, "0");

            /**
             * if holiday
             */
            if (this.isHoliday(ts) == true) {
                fascia = 3;
            } else {
                //if sunday
                if (ts.getDay() == 0) {
                    fascia = 3;
                    //if saturday
                } else if (ts.getDay() == 6) {
                    fascia = 3;
                    //from 7.15 to 23.00 
                    if (time >= 715 && time <= 2300) {
                        fascia = 2;
                    }
                    //from 7.15 to 8.00 or from 19.15 to 23.00 business days
                } else if ((time >= 715 && time <= 800) || (time >= 1915 && time <= 2300)) {
                    fascia = 2;
                    //from 23.15 to 7.00 night business days
                } else if (time >= 2315 || time <= 700) {
                    fascia = 3;
                }
            }

            //from 8.00 to 19.00 business days
            if (fascia == 0) fascia = 1;

            return fascia;
        },
        /**
         * check with Christof
         * saturday and sunday always peak 0
         * else if from 7.00 to 19.00 peak is 1
         */
        getPeak(ts) {
            var peak = 0;
            var time = ts.getHours() + String(ts.getMinutes()).padStart(2, "0");

            if (ts.getDay() != 0 && ts.getDay() != 6 && time >= 700 && time <= 1900) {
                peak = 1;
            }

            return peak;
        },
        /**
         * expectedValueCount: how many hours there are from dateStart and dateEnd, should be 744 max for 31 days month.
         * detailType: standard/deposit/recalc
         * negate (boolean): substruct from invoice if it is recalc or deposit
         * version: requested version or max version if not setted
         * */
        async getProductionData(meters, dateStart, dateEnd, expectedValueCount, detailType, negate, version = -1) {
            let data = {};
            data.meterData = [];
            //for each meter in meters
            for (let m = 0; m < meters.length; m++) {
                let form = {};
                form.meter_id = meters[m].id;
                form.year = dateEnd.getFullYear();
                form.month = dateEnd.getMonth() + 1;
                let max_version = version > -1 ? version : 1;

                if (version == -1) {
                    //production for month
                    let productionDataMonth = [];
                    if (detailType.name.trim().toLowerCase() == "onaccount") {
                        //if deposit retrive data from e-distribuzione else from Terna
                        //takes a sum of requested version
                        await CommercialMgmtService.getProductionDISTMonth(form).then((response) => {
                            productionDataMonth = response.data;
                        })
                        //retrive data from Terna
                    } else {
                        await CommercialMgmtService.getProductionUPNRMonth(form).then((response) => {
                            productionDataMonth = response.data;
                        })
                    }

                    //error
                    if (productionDataMonth.length <= 0) {
                        console.log("Production Month", form);
                        throw "PRODUCTION MONTH INVALID VALUE COUNT";
                    }

                    //sets a max version available in the DB
                    for (let d = 0; d < productionDataMonth.length; d++) {
                        if (productionDataMonth[d].version > max_version) {
                            max_version = productionDataMonth[d].version;
                        }
                    }
                }

                //reset form
                this.$delete(form);
                form.start = this.getISODateString(dateStart);
                form.end = this.getISODateString(dateEnd);
                form.meter_id = meters[m].id;
                form.version = max_version;

                //data for time period
                //richiesta profilo orario con la versione settata in precedenza: max_version
                //dati e-distribuzione
                let productionData = [];
                if (detailType.name.trim().toLowerCase() == "onaccount") {
                    await CommercialMgmtService.getProductionDIST(form).then((response) => {
                        productionData = response.data;
                    })
                    //dati Terna (se non è acconto)
                } else {
                    await CommercialMgmtService.getProductionUPNR(form).then((response) => {
                        productionData = response.data;
                    })
                }

                //error
                if (productionData.length != expectedValueCount) {
                    console.log("PRODUCTION", form, productionData);
                    throw "PRODUCTION INVALID VALUE COUNT " + productionData.length + " of " + expectedValueCount;
                }

                //data for each meter
                data.meterData.push({
                    meterId: meters[m].id,
                    version: max_version,
                    quantity: 0,
                    amount: 0,
                    quantities: []
                });

                for (let d = 0; d < productionData.length; d++) {
                    //converte in mwh ed eventualmente in valore negativo se netage è true
                    data.meterData[m].quantities.push(productionData[d].value / 1000 * (negate ? -1 : 1));
                }
            }

            //altre variabili dell'oggetto data
            data.deal = {};
            data.quantity = 0;
            data.amount = 0;
            data.detailType = detailType;
            data.pricesNation = [];
            data.pricesLocal = [];
            data.prices = [];
            data.quantities = [];
            data.amounts = [];
            data.formula = "";
            data.dates = [];
            data.fasce = [];
            data.peaks = [];

            return data;
        },
        //areaId: pun; csud... definiti nel deal è tabella DB: area
        //expectedValueCount: come definito già sopra, devono corrispondere
        async getPrices(dateStart, dateEnd, areaId, expectedValueCount) {
            let form = {};

            form.start = this.getISODateString(dateStart);
            form.end = this.getISODateString(dateEnd);
            form.area_id = areaId;

            let prices = [];
            await CommercialMgmtService.getPrices(form).then((response) => {
                prices = response.data;
            })

            //error
            if (prices.length != expectedValueCount) {
                console.log("PRICES", prices);
                throw "PRICES INVALID VALUE COUNT " + prices.length + "/" + expectedValueCount;
            }
            let priceValues = [];
            for (let d = 0; d < prices.length; d++) {
                priceValues.push(prices[d].value);
            }
            return priceValues;
        },

        /**
         * 
         * prende tutti i dati per una fattura (ogni deal, una fattura)
         * oggetto deal
         * oggetto invoce
         * 
         */
        async getInvoiceData(deal, invoice) {
            //convertire in moment
            let daysOfMonth = new Date(deal.year, deal.month, 0).getDate();
            let expectedValueCount = daysOfMonth * 24 + (deal.month == 3 ? -1 : 0) + (deal.month == 10 ? 1 : 0);
            let dateStart = this.createDate(deal.year, deal.month, 1, 0, 0, 0);
            let dateEnd = this.createDate(deal.year, deal.month + 1 == 12 ? 0 : deal.month + 1, 1, 0, 0, 0);

            //calcola la data del cambiamento dell'ora dei mesi marzo e ottobre
            let tsMar = this.createDate(dateEnd.getFullYear(), 4, 1, 2, 0, 0);
            tsMar.setDate(tsMar.getDate() - ((tsMar.getDay() == 0) ? 7 : tsMar.getDay()));
            let tsOct = this.createDate(dateEnd.getFullYear(), 11, 1, 0, 0, 0);
            tsOct.setDate(tsOct.getDate() - ((tsOct.getDay() == 0) ? 7 : tsOct.getDay()));


            if (dateStart > tsMar && dateStart < tsOct) {
                dateStart.setHours(dateStart.getHours() - 1);
            }
            if (dateEnd > tsMar && dateEnd < tsOct) {
                dateEnd.setHours(dateEnd.getHours() - 2);
            }
            else {
                dateEnd.setHours(dateEnd.getHours() - 1);
            }

            //ricava le aree dal DB per la zona del deal
            let areas = await this.getAreas();
            let areaLocal = -1;
            let areaNation = -1;
            for (let a = 0; a < areas.length; a++) {
                if (areas[a].code === deal.zone) {
                    areaLocal = areas[a].id;
                }
                if (areas[a].code === "PUN") {
                    areaNation = areas[a].id;
                }
            }
            if (areaLocal < 0) {
                throw "Local area for Deal '" + deal.zone + "' not found.";
            }
            if (areaNation < 0) {
                throw "PUN area missing.";
            }

            //array di meters
            let meters = await this.getMetersByPlantId(deal.plantDto.plantId);
            if (meters.length <= 0) {
                throw "No meters for plant '" + deal.plantDto.plantId + "' found.";
            }

            //tipo fattura: standard/acconto/ricalcolo
            let invoiceDetailTypes = (await CommercialMgmtService.getInvoiceDetailTypes()).data;
            let idtStandard = {};
            let idtAccount = {};
            let idtCorrection = {};
            for (let idt = 0; idt < invoiceDetailTypes.length; idt++) {
                if (invoiceDetailTypes[idt].name.trim().toLowerCase() == "standard") {
                    idtStandard = invoiceDetailTypes[idt];
                }
                else if (invoiceDetailTypes[idt].name.trim().toLowerCase() == "onaccount") {
                    idtAccount = invoiceDetailTypes[idt];
                }
                else if (invoiceDetailTypes[idt].name.trim().toLowerCase() == "correction") {
                    idtCorrection = invoiceDetailTypes[idt];
                }
            }
            if (idtStandard == null || idtAccount == null || idtCorrection == 0) {
                throw "Missing invoice detail types";
            }

            //
            let dates = [];
            let fasce = [];
            let peaks = [];
            for (let d = 1; d <= daysOfMonth; d++) {
                for (let h = 1; h <= 24; h++) {
                    let ts = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), d, h);

                    //cambio ora alle 2 AM quindi fa skip
                    //per marzo invece di 744 sono 743 perhè abbiamo un ora in meno
                    //e per ottobre sono 745
                    if (ts.getTime() == tsMar.getTime() && h == 2) {
                        console.log("Skipping", ts);
                    }
                    else {
                        dates.push(ts);
                        fasce.push(this.getFascia(ts));
                        peaks.push(this.getPeak(ts));
                    }
                }
            }

            let versionStandard = -1;
            let versionStandardPrev = -1;
            let versionAccountPrev = -1;

            //if invoice exists and published
            if (invoice != null && invoice.status != 0) {
                //read versions
                //if version is changed we should recalc
                let invoiceDetail = (await CommercialMgmtService.getInvoiceDetailByInvoiceId(invoice.invoiceId)).data;
                for (let id = 0; id < invoiceDetail.length; id++) {
                    //of on account
                    if (deal.deposit == 1) {
                        if (invoiceDetail[id].invoiceDetailTypeId == idtAccount.id && invoiceDetail[id].year == deal.year && invoiceDetail[id].month == deal.month) {
                            versionStandard = invoiceDetail[id].version;
                        }
                        else if (invoiceDetail[id].invoiceDetailTypeId == idtStandard.id && (invoiceDetail[id].year != deal.year || invoiceDetail[id].month != deal.month)) {
                            versionStandardPrev = invoiceDetail[id].version;
                        }
                        else if (invoiceDetail[id].invoiceDetailTypeId == idtAccount.id && (invoiceDetail[id].year != deal.year || invoiceDetail[id].month != deal.month)) {
                            versionAccountPrev = invoiceDetail[id].version;
                        }
                    }
                    else {
                        if (invoiceDetail[id].invoiceDetailTypeId == idtStandard.id && invoiceDetail[id].year == deal.year && invoiceDetail[id].month == deal.month) {
                            versionStandard = invoiceDetail[id].version;
                        }
                    }
                }
            }

            let data = [];
            /**
             * se è in acconto
             * prende i dati del deal precedente
             * e calcola la differenza tra i due
             * e calcola nuovo importo
             * 
             * se il deal ha il flag "in acconto" si usano i dati di e-distribution
             * 
             * e-distribuzione = temporaneo
             * il cliente paga l'imponibile di Terna
             * 
             */

            //fa tutto quello che facciamo già in precedenza
            //ma per il mese precedente e anche attuale
            //due dati di e-distribuzione (quello attuale e quello precedente)
            //più quello del mese precedente di Terna
            if (deal.deposit == 1) {
                let dealPrev = (await CommercialMgmtService.getDealByPlantIdAndYearAndMonth(deal.plantDto.plantId, deal.month == 1 ? deal.year - 1 : deal.year, deal.month == 1 ? 12 : deal.month)).data;
                let daysOfMonthPrev = new Date(dealPrev.year, dealPrev.month, 0).getDate();
                let expectedValueCountPrev = daysOfMonthPrev * 24 + (dealPrev.month == 3 ? -1 : 0) + (dealPrev.month == 10 ? 1 : 0);
                let dateStartPrev = this.createDate(dealPrev.year, dealPrev.month, 1, 0, 0, 0);
                let dateEndPrev = this.createDate(dealPrev.year, dealPrev.month + 1 == 12 ? 0 : dealPrev.month + 1, 1, 0, 0, 0);

                let tsMarPrev = this.createDate(dateEndPrev.getFullYear(), 4, 1, 2, 0, 0);
                tsMarPrev.setDate(tsMarPrev.getDate() - ((tsMarPrev.getDay() == 0) ? 7 : tsMarPrev.getDay()));
                let tsOctPrev = this.createDate(dateEndPrev.getFullYear(), 11, 1, 0, 0, 0);
                tsOctPrev.setDate(tsOctPrev.getDate() - ((tsOctPrev.getDay() == 0) ? 7 : tsOctPrev.getDay()));

                if (dateStartPrev > tsMarPrev && dateStartPrev < tsOctPrev) {
                    dateStartPrev.setHours(dateStartPrev.getHours() - 1);
                }
                if (dateEndPrev > tsMarPrev && dateEndPrev < tsOctPrev) {
                    dateEndPrev.setHours(dateEndPrev.getHours() - 2);
                }
                else {
                    dateEndPrev.setHours(dateEndPrev.getHours() - 1);
                }

                let datesPrev = [];
                let fascePrev = [];
                let peaksPrev = [];
                for (let d = 1; d <= daysOfMonthPrev; d++) {
                    for (let h = 1; h <= 24; h++) {
                        let ts = new Date(dateEndPrev.getFullYear(), dateEndPrev.getMonth(), d, h);
                        if (ts.getTime() == tsMarPrev.getTime() && h == 2) {
                            console.log("Skipping", ts);
                        }
                        else {
                            datesPrev.push(ts);
                            fascePrev.push(this.getFascia(ts));
                            peaksPrev.push(this.getPeak(ts));
                        }
                    }
                }

                let areaLocalPrev = -1;
                let areaNationPrev = -1;
                for (let a = 0; a < areas.length; a++) {
                    if (areas[a].code === dealPrev.zone) {
                        areaLocalPrev = areas[a].id;
                    }
                    if (areas[a].code === "PUN") {
                        areaNationPrev = areas[a].id;
                    }
                }
                if (areaLocalPrev < 0) {
                    throw "Local area for Deal '" + dealPrev.zone + "' not found.";
                }
                if (areaNationPrev < 0) {
                    throw "PUN area missing.";
                }

                let pricesNation = await this.getPrices(dateStart, dateEnd, areaNation, expectedValueCount);
                let pricesLocal = await this.getPrices(dateStart, dateEnd, areaLocal, expectedValueCount);
                let pricesNationPrev = await this.getPrices(dateStartPrev, dateEndPrev, areaNationPrev, expectedValueCountPrev);
                let pricesLocalPrev = await this.getPrices(dateStartPrev, dateEndPrev, areaLocalPrev, expectedValueCountPrev);

                data.push(await this.getProductionData(meters, dateStart, dateEnd, expectedValueCount, idtAccount, false, versionStandard));
                data[data.length - 1].pricesNation = pricesNation;
                data[data.length - 1].pricesLocal = pricesLocal;
                data[data.length - 1].dates = dates;
                data[data.length - 1].fasce = fasce;
                data[data.length - 1].peaks = peaks;
                data[data.length - 1].deal = deal;

                data.push(await this.getProductionData(meters, dateStartPrev, dateEndPrev, expectedValueCountPrev, idtStandard, false, versionStandardPrev));
                data[data.length - 1].pricesNation = pricesNationPrev;
                data[data.length - 1].pricesLocal = pricesLocalPrev;
                data[data.length - 1].dates = datesPrev;
                data[data.length - 1].fasce = fascePrev;
                data[data.length - 1].peaks = peaksPrev;
                data[data.length - 1].deal = dealPrev;

                data.push(await this.getProductionData(meters, dateStartPrev, dateEndPrev, expectedValueCountPrev, idtAccount, true, versionAccountPrev));
                data[data.length - 1].pricesNation = pricesNationPrev;
                data[data.length - 1].pricesLocal = pricesLocalPrev;
                data[data.length - 1].dates = datesPrev;
                data[data.length - 1].fasce = fascePrev;
                data[data.length - 1].peaks = peaksPrev;
                data[data.length - 1].deal = dealPrev;
            }
            //dati attual di Terna e nient'altro (standard)
            else {
                data.push(await this.getProductionData(meters, dateStart, dateEnd, expectedValueCount, idtStandard, false, versionStandard));
                data[data.length - 1].pricesNation = await this.getPrices(dateStart, dateEnd, areaNation, expectedValueCount);
                data[data.length - 1].pricesLocal = await this.getPrices(dateStart, dateEnd, areaLocal, expectedValueCount);
                data[data.length - 1].dates = dates;
                data[data.length - 1].fasce = fasce;
                data[data.length - 1].peaks = peaks;
                data[data.length - 1].deal = deal;
            }

            //entra solo quando puo rigenerare la fattura
            //è stato aggiunto il correctionData il quale non esiste nel portale vecchio
            //per ogni deal che deve ricalcolare prende tutti i dati che serve per fare il ricalcolo
            if (invoice == null || invoice.status == 0) {
                //prende fuori anno mese e versioni i quali sono da correggere
                //
                let correctionData = (await CommercialMgmtService.getDealCorrectionData(deal.plantDto.plantId)).data;
                if (correctionData) {
                    for (let cd = 0; cd < correctionData.length; cd++) {
                        console.log("Correction data: ", correctionData[cd]);

                        //deal che è da ricalcolare
                        let dealCorr = (await CommercialMgmtService.getDealByPlantIdAndYearAndMonth(correctionData[cd].plantId, correctionData[cd].year, correctionData[cd].month)).data;
                        let daysOfMonthCorr = new Date(dealCorr.year, dealCorr.month, 0).getDate();
                        let expectedValueCountCorr = daysOfMonthCorr * 24 + (dealCorr.month == 3 ? -1 : 0) + (dealCorr.month == 10 ? 1 : 0);
                        let dateStartCorr = this.createDate(dealCorr.year, dealCorr.month, 1, 0, 0, 0);
                        let dateEndCorr = this.createDate(dealCorr.year, dealCorr.month + 1 == 12 ? 0 : dealCorr.month + 1, 1, 0, 0, 0);

                        let tsMarCorr = this.createDate(dateEndCorr.getFullYear(), 4, 1, 2, 0, 0);
                        tsMarCorr.setDate(tsMarCorr.getDate() - ((tsMarCorr.getDay() == 0) ? 7 : tsMarCorr.getDay()));
                        let tsOctCorr = this.createDate(dateEndCorr.getFullYear(), 11, 1, 0, 0, 0);
                        tsOctCorr.setDate(tsOctCorr.getDate() - ((tsOctCorr.getDay() == 0) ? 7 : tsOctCorr.getDay()));

                        if (dateStartCorr > tsMarCorr && dateStartCorr < tsOctCorr) {
                            dateStartCorr.setHours(dateStartCorr.getHours() - 1);
                        }
                        if (dateEndCorr > tsMarCorr && dateEndCorr < tsOctCorr) {
                            dateEndCorr.setHours(dateEndCorr.getHours() - 2);
                        }
                        else {
                            dateEndCorr.setHours(dateEndCorr.getHours() - 1);
                        }

                        let datesCorr = [];
                        let fasceCorr = [];
                        let peaksCorr = [];
                        for (let d = 1; d <= daysOfMonthCorr; d++) {
                            for (let h = 1; h <= 24; h++) {
                                let ts = new Date(dateEndCorr.getFullYear(), dateEndCorr.getMonth(), d, h);
                                if (ts.getTime() == tsMarCorr.getTime() && h == 2) {
                                    console.log("Skipping", ts);
                                }
                                else {
                                    datesCorr.push(ts);
                                    fasceCorr.push(this.getFascia(ts));
                                    peaksCorr.push(this.getPeak(ts));
                                }
                            }
                        }

                        let areaLocalCorr = -1;
                        let areaNationCorr = -1;
                        for (let a = 0; a < areas.length; a++) {
                            if (areas[a].code === dealCorr.zone) {
                                areaLocalCorr = areas[a].id;
                            }
                            if (areas[a].code === "PUN") {
                                areaNationCorr = areas[a].id;
                            }
                        }
                        if (areaLocalCorr < 0) {
                            throw "Local area for Deal '" + dealCorr.zone + "' not found.";
                        }
                        if (areaNationCorr < 0) {
                            throw "PUN area missing.";
                        }

                        let pricesNationCorr = await this.getPrices(dateStartCorr, dateEndCorr, areaNationCorr, expectedValueCountCorr);
                        let pricesLocalCorr = await this.getPrices(dateStartCorr, dateEndCorr, areaLocalCorr, expectedValueCountCorr);

                        //la differenza è quella che viene messa sulla fattura
                        //data in valore negativo (true)
                        data.push(await this.getProductionData(meters, dateStartCorr, dateEndCorr, expectedValueCountCorr, idtStandard, true, correctionData[cd].versionOld));
                        data[data.length - 1].pricesNation = pricesNationCorr;
                        data[data.length - 1].pricesLocal = pricesLocalCorr;
                        data[data.length - 1].dates = datesCorr;
                        data[data.length - 1].fasce = fasceCorr;
                        data[data.length - 1].peaks = peaksCorr;
                        data[data.length - 1].deal = dealCorr;

                        //data in valore positivo (false)
                        data.push(await this.getProductionData(meters, dateStartCorr, dateEndCorr, expectedValueCountCorr, idtCorrection, false, correctionData[cd].versionNew));
                        data[data.length - 1].pricesNation = pricesNationCorr;
                        data[data.length - 1].pricesLocal = pricesLocalCorr;
                        data[data.length - 1].dates = datesCorr;
                        data[data.length - 1].fasce = fasceCorr;
                        data[data.length - 1].peaks = peaksCorr;
                        data[data.length - 1].deal = dealCorr;
                    }
                }
            }

            return data;
        },

        async getAreas() {
            let areas = {};

            await CommercialMgmtService.getAreas().then((response) => {
                areas = response.data;
            });

            return areas;
        },
        async getMetersByPlantId(plantId) {
            let meters = {};

            await CommercialMgmtService.getMetersByPlantId(plantId).then((response) => {
                meters = response.data;
            });

            return meters;
        },
        async getDeal(dealId) {
            let deal = {};

            await CommercialMgmtService.getDealDetails(dealId).then((response) => {
                deal = response.data;
            });

            return deal;
        },
        async getInvoice(dealId, year, month) {
            let invoice = {};

            await CommercialMgmtService.getInvoice(dealId, year, month).then((response) => {
                invoice = response.data;
            });

            return invoice;
        },
        async getInvoiceByDealId(dealId) {
            let invoice = {};

            await CommercialMgmtService.getInvoiceByDealId(dealId).then((response) => {
                invoice = response.data;
            });

            return invoice;
        },
        async getPlant(plantId) {
            let plant = {};

            await TechnicalMgmtService.getPlantDetails(plantId).then((response) => {
                plant = response.data;
            });

            return plant;
        },
        async getSPV(spvId) {
            let spv = {};

            await CommercialMgmtService.getSPVDetails(spvId).then((response) => {
                spv = response.data;
            });

            return spv;
        },
        async getFond(fondId) {
            let fond = {};

            await CommercialMgmtService.getFondDetails(fondId).then((response) => {
                fond = response.data;
            });

            return fond;
        },
        async getTrader(traderId) {
            let trader = {};

            await CommercialMgmtService.getTraderDetails(traderId).then((response) => {
                trader = response.data;
            });

            return trader;
        },
        async getPaymentCondition(paymentConditionId) {
            let paymentCondition = {};

            await CommercialMgmtService.getPaymentConditionDetails(paymentConditionId).then((response) => {
                paymentCondition = response.data;
            });

            return paymentCondition;
        },
        async calculate(deal, invoice) {
            let data = await this.getInvoiceData(deal, invoice);
            // console.log("DATA: ", data);

            let quantity = 0;
            let amount = 0;

            for (let i = 0; i < data.length; i++) {
                if (data[i].deal.zone != null && data[i].deal.fee != null && data[i].deal.pzone != null && data[i].deal.f1 != null && data[i].deal.f2 != null && data[i].deal.f3 != null && data[i].deal.fixVar != null) {
                    data[i].formula = "((1 - FIX_VAR(" + data[i].deal.fixVar + ")) * (PZ(" + data[i].deal.pzone + ") * P_ZONALE + FIX(" + data[i].deal.fee + ")) * VAR(" + data[i].deal.var + ")) + CCT(" + (data[i].deal.cct != null ? data[i].deal.cct : "0") + ") * (P_PUN - P_ZONALE) + FIX_VAR(" + data[i].deal.fixVar + ") * (F1(" + data[i].deal.f1 + ") or F2(" + data[i].deal.f2 + ") or F3(" + data[i].deal.f3 + "))";

                    for (let d = 0; d < data[i].dates.length; d++) {
                        data[i].prices[d] = (1 - data[i].deal.fixVar) * (data[i].pricesLocal[d] * data[i].deal.pzone + data[i].deal.fee) * data[i].deal.var + (data[i].deal.cct != null ? data[i].deal.cct : 0) * (data[i].pricesNation[d] - data[i].pricesLocal[d]) + data[i].deal.fixVar * ((data[i].fasce[d] == 1 ? data[i].deal.f1 : 0) + (data[i].fasce[d] == 2 ? data[i].deal.f2 : 0) + (data[i].fasce[d] == 3 ? data[i].deal.f3 : 0));
                    }
                }
                else if (data[i].deal.zone != null && data[i].deal.fee != null && data[i].deal.pzone != null && data[i].deal.peak != null && data[i].deal.offPeak != null && data[i].deal.popVar != null) {
                    data[i].formula = "((1 - POP_VAR(" + data[i].deal.popVar + ")) * (PZ(" + data[i].deal.pzone + ") * P_ZONALE + FIX(" + data[i].deal.fee + ")) * VAR(" + data[i].deal.var + ")) + CCT(" + (data[i].deal.cct != null ? data[i].deal.cct : "0") + ") * (P_PUN - P_ZONALE) + POP_VAR(" + data[i].deal.popVar + ") * (PEAK(" + data[i].deal.peak + ") or OFF_PEAK(" + data[i].deal.offPeak + "))";

                    for (let d = 0; d < data[i].dates.length; d++) {
                        data[i].prices[d] = (1 - data[i].deal.popVar) * (data[i].pricesLocal[d] * data[i].deal.pzone + data[i].deal.fee) * data[i].deal.var + (data[i].deal.cct != null ? data[i].deal.cct : 0) * (data[i].pricesNation[d] - data[i].pricesLocal[d]) + data[i].deal.popVar * ((data[i].peaks[d] == 1 ? data[i].deal.peak : 0) + (data[i].peaks[d] == 0 ? data[i].deal.offPeak : 0));
                    }
                }
                else if (data[i].deal.zone != null && data[i].deal.fee != null && data[i].deal.pzone != null) {
                    data[i].formula = "(PZ(" + data[i].deal.pzone + ") * P_ZONALE + FIX(" + data[i].deal.fee + ")) * VAR(" + data[i].deal.var + ") + CCT(" + (data[i].deal.cct != null ? data[i].deal.cct : "0") + ") * (P_PUN - P_ZONALE)";

                    for (let d = 0; d < data[i].dates.length; d++) {
                        data[i].prices[d] = (data[i].pricesLocal[d] * data[i].deal.pzone + data[i].deal.fee) * data[i].deal.var + (data[i].deal.cct != null ? data[i].deal.cct : 0) * (data[i].pricesNation[d] - data[i].pricesLocal[d]);
                    }
                }
                else if (data[i].deal.zone != null && data[i].deal.f1 != null && data[i].deal.f2 != null && data[i].deal.f3 != null) {
                    data[i].formula = "(F1(" + data[i].deal.f1 + ") or F2(" + data[i].deal.f2 + ") or F3(" + data[i].deal.f3 + ")) * VAR(" + data[i].deal.var + ") + CCT(" + (data[i].deal.cct != null ? data[i].deal.cct : "0") + ") * (P_PUN - P_ZONALE)";

                    for (let d = 0; d < data[i].dates.length; d++) {
                        data[i].prices[d] = ((data[i].fasce[d] == 1 ? data[i].deal.f1 : 0) + (data[i].fasce[d] == 2 ? data[i].deal.f2 : 0) + (data[i].fasce[d] == 3 ? data[i].deal.f3 : 0)) * data[i].deal.var + (data[i].deal.cct != null ? data[i].deal.cct : 0) * (data[i].pricesNation[d] - data[i].pricesLocal[d]);
                    }
                }
                else if (data[i].deal.zone != null && data[i].deal.peak != null && data[i].deal.offPeak != null) {
                    data[i].formula = "(PEAK(" + data[i].deal.peak + ") or OFF_PEAK(" + data[i].deal.offPeak + ")) * VAR(" + data[i].deal.var + ") + CCT(" + (data[i].deal.cct != null ? data[i].deal.cct : "0") + ") * (P_PUN - P_ZONALE)";

                    for (let d = 0; d < data[i].dates.length; d++) {
                        data[i].prices[d] = ((data[i].peaks[d] == 1 ? data[i].deal.peak : 0) + (data[i].peaks[d] == 0 ? data[i].deal.offPeak : 0)) * data[i].deal.var + (data[i].deal.cct != null ? data[i].deal.cct : 0) * (data[i].pricesNation[d] - data[i].pricesLocal[d]);
                    }
                }
                else {
                    throw "FORMULA NOT DEFINED FOR DEAL" + deal;
                }
                //calcola l'imponibile (prezzo calcolato per quantità)
                for (let d = 0; d < data[i].dates.length; d++) {
                    for (let m = 0; m < data[i].meterData.length; m++) {
                        data[i].meterData[m].quantity += data[i].meterData[m].quantities[d];
                        data[i].meterData[m].amount += data[i].meterData[m].quantities[d] * data[i].prices[d];
                        if (!data[i].quantities[d]) {
                            data[i].quantities[d] = data[i].meterData[m].quantities[d];
                        }
                        else {
                            data[i].quantities[d] += data[i].meterData[m].quantities[d];
                        }
                    }
                    data[i].amounts[d] = data[i].quantities[d] * data[i].prices[d];
                    data[i].quantity += data[i].quantities[d];
                    data[i].amount += data[i].amounts[d];
                }

                quantity += data[i].quantity;
                amount += data[i].amount;

                // format
                for (let d = 0; d < data[i].dates.length; d++) {
                    for (let m = 0; m < data[i].meterData.length; m++) {
                        data[i].meterData[m].quantity = Number(data[i].meterData[m].quantity.toFixed(3));
                        data[i].meterData[m].amount = Number(data[i].meterData[m].amount.toFixed(2));
                        // data[i].quantities[d] = Number(data[i].quantities[d].toFixed(6));
                    }
                    data[i].quantity = Number(data[i].quantity.toFixed(3));
                    // data[i].amounts[d] = Number(data[i].amounts[d].toFixed(6));
                    data[i].amount = Number(data[i].amount.toFixed(2));
                }
            }

            quantity = Number(quantity.toFixed(3));
            amount = Number(amount.toFixed(2));

            console.log("INVOICE DATA", data);

            return {
                quantity: quantity,
                amount: amount,
                detail: data
            };
        },
        //invoice calculation
        async calculateInvoice(deal_id) {
            console.log("calculateInvoice for deal_id: ", deal_id);
            try {
                let invoice = await this.getInvoiceByDealId(deal_id);
                let deal = await this.getDeal(deal_id);
                let data = await this.calculate(deal, invoice);

                if (invoice != null && invoice.status == 0) {
                    let invoiceData = [
                        {
                            "op": "replace",
                            "path": "/quantity",
                            "value": data.quantity
                        },
                        {
                            "op": "replace",
                            "path": "/amount",
                            "value": data.amount
                        }
                    ];

                    await CommercialMgmtService.updateInvoice(invoice.invoiceId, invoiceData);

                    for (let i = 0; i < data.detail.length; i++) {
                        for (let m = 0; m < data.detail[i].meterData.length; m++) {
                            let invoiceDetail = (await CommercialMgmtService.getInvoiceDetailByInvoiceIdAndMeterIdAndInvoiceDetailTypeIdAndYearAndMonth(invoice.invoiceId, data.detail[i].meterData[m].meterId, data.detail[i].detailType.id, data.detail[i].deal.year, data.detail[i].deal.month)).data;

                            let invoiceDetailData = [
                                {
                                    "op": "replace",
                                    "path": "/invoiceDetailTypeId",
                                    "value": data.detail[i].detailType.id
                                },
                                {
                                    "op": "replace",
                                    "path": "/meterId",
                                    "value": data.detail[i].meterData[m].meterId
                                },
                                {
                                    "op": "replace",
                                    "path": "/version",
                                    "value": data.detail[i].meterData[m].version
                                },
                                {
                                    "op": "replace",
                                    "path": "/year",
                                    "value": data.detail[i].deal.year
                                },
                                {
                                    "op": "replace",
                                    "path": "/month",
                                    "value": data.detail[i].deal.month
                                },
                                {
                                    "op": "replace",
                                    "path": "/quantity",
                                    "value": data.detail[i].meterData[m].quantity
                                },
                                {
                                    "op": "replace",
                                    "path": "/amount",
                                    "value": data.detail[i].meterData[m].amount
                                }
                            ];
                            await CommercialMgmtService.updateInvoiceDetail(invoiceDetail.id, invoiceDetailData);
                        }
                    }
                }
                else if (invoice == null) {
                    let invoiceData = {
                        "sapr": deal.sapr,
                        "year": deal.year,
                        "month": deal.month,
                        "depositId": 0,
                        "status": 0,
                        "quantity": data.quantity,
                        "amount": data.amount,
                        "sdiProgressive": 0,
                        "userId": this.user.id,
                        "dealId": deal_id
                    };

                    await CommercialMgmtService.addInvoice(invoiceData);
                    let invoice = await this.getInvoiceByDealId(deal_id);

                    for (let i = 0; i < data.detail.length; i++) {
                        for (let m = 0; m < data.detail[i].meterData.length; m++) {
                            let invoiceDetailData = {
                                "invoiceId": invoice.invoiceId,
                                "invoiceDetailTypeId": data.detail[i].detailType.id,
                                "meterId": data.detail[i].meterData[m].meterId,
                                "version": data.detail[i].meterData[m].version,
                                "year": data.detail[i].deal.year,
                                "month": data.detail[i].deal.month,
                                "quantity": data.detail[i].meterData[m].quantity,
                                "amount": data.detail[i].meterData[m].amount
                            };
                            await CommercialMgmtService.addInvoiceDetail(invoiceDetailData);
                        }
                    }
                }

                this.getInvoicesByUser();
            }
            catch (error) {
                return [false, error];
            }
            return [true, 'Success'];
        },
        exportDetailSelection() {
            var selectedNodes = this.gridApi.getSelectedNodes();
            if (selectedNodes.length < 10){
            selectedNodes.forEach((node) => {
                this.exportDetail(node);
            })
        }
        },
        async exportDetail(node) {
            console.log("exportDetail for invoice_id:", this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
            try {
                let deal = await this.getDeal(this.gridApi.getRowNode(node.rowIndex).data.deal_id);
                let invoice = await this.getInvoice(this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
                let data = await this.calculate(deal, invoice);
                let plant = await this.getPlant(deal.plantDto.plantId);
                let spv = await this.getSPV(plant.spvId);
                let wb = XLSX.utils.book_new();

                for (let i = 0; i < data.detail.length; i++) {
                    let sheet_data = [
                        ["Fond", spv.fond.name],
                        ["SPV", spv.name],
                        ["Plant", plant.name],
                        ["SAPR", plant.sapr],
                        ["Zona", data.detail[i].deal.zone],
                        ["", ""],
                        ["Quantity (MWh)", Number(data.detail[i].quantity)],
                        ["Amount (€)", Number(data.detail[i].amount)],
                        ["Price formula", data.detail[i].formula],
                        ["", ""],
                        ["Date", "F1/F2/F3", "peak/off-peak", "Quantity (MWh)", "P_PUN (€/MWh)", "P_Zonale (€/MWh)", "Price (€/MWh)", "Amount (€)"]
                    ];

                    for (let d = 0; d < data.detail[i].dates.length; d++) {
                        sheet_data.push([
                            this.getDateString(data.detail[i].dates[d]),
                            "F" + data.detail[i].fasce[d],
                            data.detail[i].peaks[d] == 1 ? "peak" : "off-peak",
                            Number(data.detail[i].quantities[d]),
                            Number(data.detail[i].pricesNation[d]),
                            Number(data.detail[i].pricesLocal[d]),
                            Number(data.detail[i].prices[d]),
                            Number(data.detail[i].amounts[d])
                        ]);
                    }
                    let ws = XLSX.utils.aoa_to_sheet(sheet_data);
                    XLSX.utils.book_append_sheet(wb, ws, "Detail_" + (data.detail[i].detailType.name.toUpperCase()) + "_" + data.detail[i].deal.year + "_" + data.detail[i].deal.month);
                }

                XLSX.writeFileXLSX(wb, "Detail_" + this.getCurrentTimeStamp() + ".xlsx");
            }
            catch (error) {
                this.$alert("Exporting invoice detail failed with error: " + error, "Error", "error");
            }
        },
        publishInvoiceSelection() {
            var selectedNodes = this.gridApi.getSelectedNodes();
            selectedNodes.forEach((node) => {
                this.exportDetail(node);
            })
        },
        async publishInvoice(node) {
            console.log("publishInvoice for invoice_id:", this.gridApi.getRowNode(node.rowIndex).data.invoice_id);

            for (const [key, value] of Object.entries(this.selectedRows)) {
                console.log(key, value.invoice_id)

                /*let invoice = await this.getInvoice(value.invoice_id);
                let deal = await this.getDeal(invoice.dealId);
                let plant = await this.getPlant(deal.plantDto.plantId);
                let spv = await this.getSPV(plant.spvId);*/

                //console.log(invoice, deal, plant, spv);

                try {
                    let invoice = await this.getInvoice(value.invoice_id);
                    let deal = await this.getDeal(invoice.dealId);
                    let plant = await this.getPlant(deal.plantDto.plantId);
                    let spv = await this.getSPV(plant.spvId);

                    if (invoice != null && invoice.status == 0) {
                        let invoiceData = [
                            {
                                "op": "replace",
                                "path": "/status",
                                "value": 1
                            },
                            {
                                "op": "replace",
                                "path": "/sdiProgressive",
                                "value": spv.sdiProgressive + 1
                            }
                        ];

                        await CommercialMgmtService.updateInvoice(invoice.invoiceId, invoiceData);

                        let spvData = [
                            {
                                "op": "replace",
                                "path": "/sdiProgressive",
                                "value": spv.sdiProgressive + 1
                            }
                        ];

                        await CommercialMgmtService.updateSPV(spv.spvId, spvData);

                        this.getInvoicesByUser();

                        let mailInfo = {
                            subject: "Psaier-EDM TEST - New invoices available",
                            //recipients: ["trade@psaierenergies.it", "maxim.zenin@psaierenergies.it", "andreas.stedile@psaierenergies.it"]
                            recipients: ["maxim.zenin@psaierenergies.it", "andreas.stedile@psaierenergies.it"]
                        };
                        mailInfo.message = "<div>Dear Sir or Madam</div><br>";
                        mailInfo.message += "<div>THIS IS A TEST MESSAGE</div>"
                        mailInfo.message += "<div>new invoices are available on <a href='https://edm.psaierenergies.it/login'>Psaier EDM</a>.</div><br>";
                        mailInfo.message += "<div>" + invoice.year + "-" + String(invoice.month).padStart(2, "0") + "&emsp;&emsp;" + plant.name + "&emsp;&emsp;" + plant.sapr + "</div><br><br>";
                        mailInfo.message += "<div>Best regards</div><br>";
                        mailInfo.message += "<div><b>psaier.energies Ltd</b></div>";
                        mailInfo.message += "<div>Julius Durst Street, 6</div>";
                        mailInfo.message += "<div>39042 Brixen Italy</div><br>";
                        mailInfo.message += "<div>+39 0472 275 300 - Phone</div>";
                        mailInfo.message += "<div>+39 0472 275 310 - Fax</div><br>";
                        mailInfo.message += "<div>Confidentiality notice: This message and its attachement are addressed to the persons above and may contain confidential information.";
                        mailInfo.message += "<div>More infomation on: <a href='http://www.psaierenergies.it/privacy/'>Privacy</a></div><br>";
                        mailInfo.message += "<div>This email was generated automatically, please do not reply.</div>";

                        await MailService.sendMail(mailInfo);

                        this.$alert("Invoice successfully published.", "Information", "info");
                    }
                }
                catch (error) {
                    this.$alert("Invoice publishing failed with error: " + error, "Error", "error");
                }


            }

            /*try {
                let invoice = await this.getInvoice(this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
                let deal = await this.getDeal(invoice.dealId);
                let plant = await this.getPlant(deal.plantDto.plantId);
                let spv = await this.getSPV(plant.spvId);

                if (invoice != null && invoice.status == 0) {
                    let invoiceData = [
                        {
                            "op": "replace",
                            "path": "/status",
                            "value": 1
                        },
                        {
                            "op": "replace",
                            "path": "/sdiProgressive",
                            "value": spv.sdiProgressive + 1
                        }
                    ];

                    await CommercialMgmtService.updateInvoice(invoice.invoiceId, invoiceData);

                    let spvData = [
                        {
                            "op": "replace",
                            "path": "/sdiProgressive",
                            "value": spv.sdiProgressive + 1
                        }
                    ];

                    await CommercialMgmtService.updateSPV(spv.spvId, spvData);

                    this.getInvoicesByUser();

                    let mailInfo = {
                        subject: "Psaier-EDM TEST - New invoices available",
                        //recipients: ["trade@psaierenergies.it", "maxim.zenin@psaierenergies.it", "andreas.stedile@psaierenergies.it"]
                        recipients: ["maxim.zenin@psaierenergies.it", "andreas.stedile@psaierenergies.it"]
                    };
                    mailInfo.message = "<div>Dear Sir or Madam</div><br>";
                    mailInfo.message += "<div>THIS IS A TEST MESSAGE</div>"
                    mailInfo.message += "<div>new invoices are available on <a href='https://edm.psaierenergies.it/login'>Psaier EDM</a>.</div><br>";
                    mailInfo.message += "<div>" + invoice.year + "-" + String(invoice.month).padStart(2, "0") + "&emsp;&emsp;" + plant.name + "&emsp;&emsp;" + plant.sapr + "</div><br><br>";
                    mailInfo.message += "<div>Best regards</div><br>";
                    mailInfo.message += "<div><b>psaier.energies Ltd</b></div>";
                    mailInfo.message += "<div>Julius Durst Street, 6</div>";
                    mailInfo.message += "<div>39042 Brixen Italy</div><br>";
                    mailInfo.message += "<div>+39 0472 275 300 - Phone</div>";
                    mailInfo.message += "<div>+39 0472 275 310 - Fax</div><br>";
                    mailInfo.message += "<div>Confidentiality notice: This message and its attachement are addressed to the persons above and may contain confidential information.";
                    mailInfo.message += "<div>More infomation on: <a href='http://www.psaierenergies.it/privacy/'>Privacy</a></div><br>";
                    mailInfo.message += "<div>This email was generated automatically, please do not reply.</div>";

                    await MailService.sendMail(mailInfo);

                    this.$alert("Invoice successfully published.", "Information", "info");
                }
            }
            catch (error) {
                this.$alert("Invoice publishing failed with error: " + error, "Error", "error");
            }*/
        },
        showIssueInvoice(node) {
            this.currentItem = this.gridApi.getRowNode(node.rowIndex).data;
        },
        issueInvoiceSelection() {
            var selecteNodes = this.gridApi.getSelectedNodes();
            selecteNodes.forEach((node) => {
                this.issueInvoice(node);
            })
        },
        async issueInvoice(node_data, form_data) {
            console.log("issueInvoice for invoice_id:", node_data.invoice_id);
            try {
                let invoice = await this.getInvoice(node_data.invoice_id);
                let date = new Date(form_data.date.slice(6, 10), parseInt(form_data.date.slice(3, 5)) - 1, form_data.date.slice(0, 2));
                let dateDue = new Date(form_data.dateDue.slice(6, 10), parseInt(form_data.dateDue.slice(3, 5)) - 1, form_data.dateDue.slice(0, 2));
                if (invoice != null && invoice.status == 1) {
                    let invoiceData = [
                        {
                            "op": "replace",
                            "path": "/status",
                            "value": 2
                        },
                        {
                            "op": "replace",
                            "path": "/number",
                            "value": form_data.number
                        },
                        {
                            "op": "replace",
                            "path": "/date",
                            "value": date
                        },
                        {
                            "op": "replace",
                            "path": "/dateDue",
                            "value": dateDue
                        }
                    ];

                    await CommercialMgmtService.updateInvoice(invoice.invoiceId, invoiceData);

                    this.getInvoicesByUser();

                    this.$alert("Invoice issued successfully.", "Information", "info");
                }
            }
            catch (error) {
                this.$alert("Invoice issuing failed with error: " + error, "Error", "error");
            }
        },
        exportXMLSelection() {
            var selecteNodes = this.gridApi.getSelectedNodes();
            selecteNodes.forEach((node) => {
                this.exportXMLSelection(node);
            })
        },
        async exportXML(node) {
            console.log("exportXML for invoice_id:", this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
            try {
                let invoice = await this.getInvoice(this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
                let invoiceDetail = (await CommercialMgmtService.getInvoiceDetailByInvoiceId(invoice.invoiceId)).data;
                let invoiceDetailTypes = (await CommercialMgmtService.getInvoiceDetailTypes()).data;
                let deal = await this.getDeal(invoice.dealId);
                let plant = await this.getPlant(deal.plantDto.plantId);
                let spv = await this.getSPV(plant.spvId);
                let trader = await this.getTrader(deal.traderId);

                var xml = '<?xml version="1.0" encoding="utf-8" ?>\n';
                xml += '<p:FatturaElettronica versione="FPR12" xmlns:ds="http://www.w3.org/2000/09/xmldsig#" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2 fatturaordinaria_v1.2.xsd" xmlns:p="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2">\n';
                xml += '	<FatturaElettronicaHeader>\n';
                xml += '		<DatiTrasmissione>\n';
                xml += '			<IdTrasmittente>\n';
                xml += '				<IdPaese>' + trader.country + '</IdPaese>\n';
                xml += '				<IdCodice>' + trader.vat_number_lv_1.replace("IT", "") + '</IdCodice>\n';
                xml += '			</IdTrasmittente>\n';
                xml += '			<ProgressivoInvio>' + String(invoice.sdiProgressive).padStart(5, "0") + '</ProgressivoInvio>\n';
                xml += '			<FormatoTrasmissione>FPR12</FormatoTrasmissione>\n';
                xml += '			<CodiceDestinatario>' + trader.destination_code + '</CodiceDestinatario>\n';
                xml += '		</DatiTrasmissione>\n';
                xml += '		<CedentePrestatore>\n';
                xml += '			<DatiAnagrafici>\n';
                xml += '				<IdFiscaleIVA>\n';
                xml += '					<IdPaese>' + spv.country + '</IdPaese>\n';
                xml += '					<IdCodice>' + spv.vatNumberLv1.replace("IT", "") + '</IdCodice>\n';
                xml += '				</IdFiscaleIVA>\n';
                xml += '				<CodiceFiscale>' + (spv.vatNumberLv2 == null ? spv.vatNumberLv1.replace("IT", "") : spv.vatNumberLv2.replace("IT", "")) + '</CodiceFiscale>\n';
                xml += '				<Anagrafica>\n';
                xml += '					<Denominazione>' + spv.name + '</Denominazione>\n';
                xml += '				</Anagrafica>\n';
                xml += '				<RegimeFiscale>RF01</RegimeFiscale>\n';
                xml += '			</DatiAnagrafici>\n';
                xml += '			<Sede>\n';
                xml += '				<Indirizzo>' + spv.street + '</Indirizzo>\n';
                xml += '				<NumeroCivico>' + spv.streetNumber + '</NumeroCivico>\n';
                xml += '				<CAP>' + spv.postalCode + '</CAP>\n';
                xml += '				<Comune>' + spv.administrativeAreaLv1 + '</Comune>\n';
                xml += '				<Provincia>' + spv.administrativeAreaLv2 + '</Provincia>\n';
                xml += '				<Nazione>' + spv.country + '</Nazione>\n';
                xml += '			</Sede>\n';
                xml += '		</CedentePrestatore>\n';
                xml += '		<CessionarioCommittente>\n';
                xml += '			<DatiAnagrafici>\n';
                xml += '				<IdFiscaleIVA>\n';
                xml += '					<IdPaese>' + trader.country + '</IdPaese>\n';
                xml += '					<IdCodice>' + trader.vat_number_lv_1.replace("IT", "") + '</IdCodice>\n';
                xml += '				</IdFiscaleIVA>\n';
                xml += '				<CodiceFiscale>' + (trader.vat_number_lv_2 == null ? trader.vat_number_lv_1.replace("IT", "") : trader.vat_number_lv_2.replace("IT", "")) + '</CodiceFiscale>\n';
                xml += '				<Anagrafica>\n';
                xml += '					<Denominazione>' + trader.name + '</Denominazione>\n';
                xml += '				</Anagrafica>\n';
                xml += '			</DatiAnagrafici>\n';
                xml += '			<Sede>\n';
                xml += '				<Indirizzo>' + trader.street + '</Indirizzo>\n';
                xml += '				<NumeroCivico>' + trader.street_number + '</NumeroCivico>\n';
                xml += '				<CAP>' + trader.postal_code + '</CAP>\n';
                xml += '				<Comune>' + trader.administrative_area_lv_1 + '</Comune>\n';
                xml += '				<Provincia>' + trader.administrative_area_lv_2 + '</Provincia>\n';
                xml += '				<Nazione>' + trader.country + '</Nazione>\n';
                xml += '			</Sede>\n';
                xml += '		</CessionarioCommittente>\n';
                xml += '	</FatturaElettronicaHeader>\n';
                xml += '	<FatturaElettronicaBody>\n';
                xml += '		<DatiGenerali>\n';
                xml += '			<DatiGeneraliDocumento>\n';
                xml += '				<TipoDocumento>TD01</TipoDocumento>\n';
                xml += '				<Divisa>EUR</Divisa>\n';
                xml += '				<Data>' + this.getDateStringYYYYMMDD(new Date(invoice.date)) + '</Data>\n';
                xml += '				<Numero>' + invoice.number + '</Numero>\n';
                xml += '				<ImportoTotaleDocumento>' + invoice.amount + '</ImportoTotaleDocumento>\n';
                xml += '				<Causale>Electricity Sale, ' + plant.name + '</Causale>\n';
                xml += '			</DatiGeneraliDocumento>\n';
                xml += '		</DatiGenerali>\n';
                xml += '		<DatiBeniServizi>\n';
                for (let id = 0; id < invoiceDetail.length; id++) {
                    let invoiceDetailTypeName = "";
                    for (let idt = 0; idt < invoiceDetailTypes.length; idt++) {
                        if (invoiceDetail[id].invoiceDetailTypeId == invoiceDetailTypes[idt].id) invoiceDetailTypeName = invoiceDetailTypes[idt].name;
                    }
                    xml += '			<DettaglioLinee>\n';
                    xml += '				<NumeroLinea>' + String(id + 1) + '</NumeroLinea>\n';
                    xml += '				<Descrizione>' + invoiceDetailTypeName + ', ' + plant.name + ', ' + String(invoiceDetail[id].month).padStart(2, "0") + '/' + invoiceDetail[id].year + '</Descrizione>\n';
                    xml += '				<Quantita>' + Math.abs(invoiceDetail[id].quantity) + '</Quantita>\n';
                    xml += '				<UnitaMisura>MWh</UnitaMisura>\n';
                    xml += '				<PrezzoUnitario>' + (invoiceDetail[id].amount / invoiceDetail[id].quantity * (invoiceDetail[id].amount < 0 ? -1 : 1)).toFixed(6) + '</PrezzoUnitario>\n';
                    xml += '				<PrezzoTotale>' + invoiceDetail[id].amount + '</PrezzoTotale>\n';
                    xml += '				<AliquotaIVA>0.00</AliquotaIVA>\n';
                    xml += '				<Natura>N6.8</Natura>\n';
                    xml += '			</DettaglioLinee>\n';
                }
                xml += '			<DatiRiepilogo>\n';
                xml += '				<AliquotaIVA>0.00</AliquotaIVA>\n';
                xml += '				<Natura>N6.8</Natura>\n';
                xml += '				<ImponibileImporto>' + invoice.amount + '</ImponibileImporto>\n';
                xml += '				<Imposta>0.00</Imposta>\n';
                xml += '			</DatiRiepilogo>\n';
                xml += '		</DatiBeniServizi>\n';
                xml += '		<DatiPagamento>\n';
                xml += '			<CondizioniPagamento>TP01</CondizioniPagamento>\n';
                xml += '			<DettaglioPagamento>\n';
                xml += '				<ModalitaPagamento>MP05</ModalitaPagamento>\n';
                xml += '				<DataScadenzaPagamento>' + this.getDateStringYYYYMMDD(new Date(invoice.dateDue)) + '</DataScadenzaPagamento>\n';
                xml += '				<ImportoPagamento>' + invoice.amount + '</ImportoPagamento>\n';
                xml += '				<IBAN>' + deal.iban + '</IBAN>\n';
                xml += '			</DettaglioPagamento>\n';
                xml += '		</DatiPagamento>\n';
                xml += '	</FatturaElettronicaBody>\n';
                xml += '</p:FatturaElettronica>';
                xml = xml.replace("&", "&amp;")

                var pom = document.createElement("a");
                var bb = new Blob([xml], { type: "application/xml" });
                pom.setAttribute("href", window.URL.createObjectURL(bb));
                pom.setAttribute('download', spv.vatNumberLv1.replace("IT", "") + "_" + String(invoice.sdiProgressive).padStart(5, "0") + ".xml");
                pom.dataset.downloadurl = ['application/xml', pom.download, pom.href].join(':');
                pom.draggable = true;
                pom.classList.add('dragout');

                pom.click();
            }
            catch (error) {
                this.$alert("Exporting invoice xml failed with error: " + error, "Error", "error");
            }
        },
        exportPDFSelection() {
            var selecteNodes = this.gridApi.getSelectedNodes();
            selecteNodes.forEach((node) => {
                this.exportPDF(node);
            })
        },
        async exportPDF(node) {
            console.log("exportPDF for invoice_id:", this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
            try {
                let invoice = await this.getInvoice(this.gridApi.getRowNode(node.rowIndex).data.invoice_id);
                let invoiceDetail = (await CommercialMgmtService.getInvoiceDetailByInvoiceId(invoice.invoiceId)).data;
                let invoiceDetailTypes = (await CommercialMgmtService.getInvoiceDetailTypes()).data;
                let deal = await this.getDeal(invoice.dealId);
                let plant = await this.getPlant(deal.plantDto.plantId);
                let spv = await this.getSPV(plant.spvId);
                let trader = await this.getTrader(deal.traderId);

                let x = 15;
                let y = 15;
                var doc = new jsPDF();
                let xc = (doc.internal.pageSize.width || doc.internal.pageSize.getWidth()) / 2;
                doc.setFont('times', 'bold');
                doc.setFontSize(14);
                doc.text(spv.name, x, y);
                doc.setFont('times', 'normal');
                doc.setFontSize(12);
                doc.text(spv.street + ' ' + spv.streetNumber, x, y += 8);
                doc.text(spv.postalCode + ' ' + spv.administrativeAreaLv1 + ' (' + spv.administrativeAreaLv2 + ' - ' + spv.country + ')', x, y += 5);
                doc.text('VAT code: ' + spv.vatNumberLv1, x, y += 5);
                doc.text('Fiscal code: ' + (spv.vatNumberLv2 == null ? spv.vatNumberLv1 : spv.vatNumberLv2), x, y += 5);

                doc.text('Attn.', x += 180, y += 15, 'right');
                doc.setFont('times', 'bold');
                doc.setFontSize(14);
                doc.text(trader.name, x, y += 8, 'right');
                doc.setFont('times', 'normal');
                doc.setFontSize(12);
                doc.text(trader.street + ' ' + trader.street_number, x, y += 8, 'right');
                doc.text(trader.postal_code + ' ' + trader.administrative_area_lv_1 + ' (' + trader.administrative_area_lv_2 + ' - ' + trader.county + ')', x, y += 5, 'right');
                doc.text('VAT code: ' + trader.vat_number_lv_1, x, y += 5, 'right');
                doc.text('Fiscal code: ' + (trader.vat_number_lv_2 == null ? trader.vat_number_lv_1 : trader.vat_number_lv_2), x, y += 5, 'right');

                doc.setFont('times', 'bold');
                doc.setFontSize(14);
                doc.text('Invoice N° ' + invoice.number + ' of ' + this.getDateStringDDMMYYYY(new Date(invoice.date)), x -= 180, y += 20);
                doc.setFont('times', 'normal');
                doc.setFontSize(12);
                doc.text('Reference period from ' + this.getDateStringDDMMYYYY(new Date(invoice.year, invoice.month - 1, 1)) + ' to ' + this.getDateStringDDMMYYYY(new Date(invoice.year, invoice.month, 0)), x, y += 8);

                doc.setFontSize(10);
                doc.setFillColor(60, 180, 240);
                doc.rect(x, y += 8, 180, 7, 'FD');
                doc.setFont('times', 'bold');
                doc.text('Plant name', xc, y += 5, 'center');

                doc.setFont('times', 'normal');
                doc.rect(x, y += 2, 180, 7, 'S');
                doc.text(plant.name, xc, y += 5, 'center');

                doc.setFont('times', 'bold');
                doc.setFillColor(60, 180, 240);
                doc.rect(x, y += 2, 120, 7, 'FD');
                doc.text('Contract', xc - 30, y += 5, 'center');

                doc.setFillColor(60, 180, 240);
                doc.rect(x + 120, y -= 5, 60, 7, 'FD');
                doc.text('Plant SAPR Code', xc + 60, y += 5, 'center');

                doc.setFont('times', 'normal');
                doc.rect(x, y += 2, 120, 7, 'S');
                doc.text(deal.deals, xc - 30, y += 5, 'center');

                doc.rect(x + 120, y -= 5, 60, 7, 'S');
                doc.text(plant.sapr, xc + 60, y += 5, 'center');

                doc.setFont('times', 'bold');
                doc.setFontSize(8);
                doc.setFillColor(60, 180, 240);
                doc.rect(x, y += 15, 50, 7, 'FD');
                doc.text('Description', x + 2, y += 5, 'left');

                doc.setFillColor(60, 180, 240);
                doc.rect(x + 50, y -= 5, 25, 7, 'FD');
                doc.text('Quantity (MWh)', x + 73, y += 5, 'right');

                doc.setFillColor(60, 180, 240);
                doc.rect(x + 75, y -= 5, 25, 7, 'FD');
                doc.text('Price (€/MWh)', x + 98, y += 5, 'right');

                doc.setFillColor(60, 180, 240);
                doc.rect(x + 100, y -= 5, 25, 7, 'FD');
                doc.text('Amount (€)', x + 123, y += 5, 'right');

                doc.setFillColor(60, 180, 240);
                doc.rect(x + 125, y -= 5, 25, 7, 'FD');
                doc.text('VAT rate (%)', x + 148, y += 5, 'right');

                doc.setFillColor(60, 180, 240);
                doc.rect(x + 150, y -= 5, 30, 7, 'FD');
                doc.text('VAT Amount (€)', x + 178, y += 5, 'right');


                for (let id = 0; id < invoiceDetail.length; id++) {
                    let invoiceDetailTypeName = "";
                    for (let idt = 0; idt < invoiceDetailTypes.length; idt++) {
                        if (invoiceDetail[id].invoiceDetailTypeId == invoiceDetailTypes[idt].id) invoiceDetailTypeName = invoiceDetailTypes[idt].name;
                    }

                    doc.setFont('times', 'normal');
                    doc.rect(x, y += 2, 50, 7, 'S');
                    doc.text(invoiceDetailTypeName + ', ' + String(invoiceDetail[id].month).padStart(2, "0") + '/' + invoiceDetail[id].year, x + 2, y += 5, 'left');

                    doc.rect(x + 50, y -= 5, 25, 7, 'S');
                    doc.text(String(Math.abs(invoiceDetail[id].quantity)), x + 73, y += 5, 'right');

                    doc.rect(x + 75, y -= 5, 25, 7, 'S');
                    doc.text(String((invoiceDetail[id].amount / invoiceDetail[id].quantity * (invoiceDetail[id].amount < 0 ? -1 : 1)).toFixed(6)), x + 98, y += 5, 'right');

                    doc.rect(x + 100, y -= 5, 25, 7, 'S');
                    doc.text(String(invoiceDetail[id].amount), x + 123, y += 5, 'right');

                    doc.rect(x + 125, y -= 5, 25, 7, 'S');
                    doc.text('10% *', x + 148, y += 5, 'right');

                    doc.rect(x + 150, y -= 5, 30, 7, 'S');
                    doc.text('0,00', x + 178, y += 5, 'right');
                }

                doc.setFontSize(6);
                doc.text('* Reverse Charge: operation not subject toVAT Article 17, paragraph 6, Presidential Decree 633/72', x, y += 7, 'left');

                doc.setFont('times', 'bold');
                doc.setFontSize(10);
                doc.setFillColor(60, 180, 240);
                doc.rect(x + 100, y += 5, 35, 7, 'FD');
                doc.text('Total amount', x + 102, y += 5, 'left');

                doc.rect(x + 135, y -= 5, 45, 7, 'S');
                doc.text(String(invoice.amount) + ' EURO', x + 178, y += 5, 'right');

                doc.text('Terms of payment', x, y += 30, 'left');
                doc.setFont('times', 'normal');
                doc.text('Due date: ' + this.getDateStringDDMMYYYY(new Date(invoice.dateDue)), x, y += 7, 'left');
                doc.text(deal.iban, x, y += 5, 'left');

                doc.save(spv.vatNumberLv1.replace("IT", "") + "_" + String(invoice.sdiProgressive).padStart(5, "0") + ".pdf");
            }
            catch (error) {
                this.$alert("Exporting invoice xml failed with error: " + error, "Error", "error");
            }
        },
        getInvoicesByUser() {
            CommercialMgmtService.getInvoicesByUser(this.user.id).then((response) => {
                let iv = [];
                for (let d = 0; d < response.data.length; d++) {
                    // TEST FILTER
                    // if (response.data[d].sapr === "0143261") {
                    if (response.data[d].date) response.data[d].date = this.getDateStringDDMMYYYY(new Date(response.data[d].date));
                    if (response.data[d].date_due) response.data[d].date_due = this.getDateStringDDMMYYYY(new Date(response.data[d].date_due));
                    iv.push(response.data[d]);
                    // }
                }
                this.invoices = iv;
            }).catch((error) => {
                this.$alert("Failed loading invoice data from server: " + error, "Error", "error");
                this.invoices = [{ "plant_name": "ND", "sapr": "ND", "year": "ND", "month": "ND", "quantity": "ND", "amount": "ND", "published": "ND", "number": "ND", "date": "ND", "date_due": "ND", "fond_name": "ND", "spv_name": "ND", "invoice_id": "ND", "deal_id": "ND" }];
            });
        },
        columnsChanged() {
            this.gridApi.sizeColumnsToFit({
                // defaultMinWidth: 100,
                columnLimits: [
                    { key: 'spv_name', minWidth: 200 },
                    { key: 'fond_name', minWidth: 150 },
                    { key: 'year', maxWidth: 100 },
                    { key: 'month', maxWidth: 100 }
                ],
            });
        },
        onFirstDataRendered() {
            const allColumnIds = [];
            this.columnApi.getColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            // this.columnApi.autoSizeColumns(allColumnIds);
        },
        /*onSelectionChanged() {
            // var selectedRows = this.gridApi.getSelectedRows();
            // var selectedRowsString = '';
            // var maxToShow = 5;
            // selectedRows.forEach(function (selectedRow, index) {
            //     if (index >= maxToShow) {
            //         return;
            //     }
            //     if (index > 0) {
            //         selectedRowsString += ', ';
            //     }
            //     selectedRowsString += selectedRow.invoice_id;
            // });
            // if (selectedRows.length > maxToShow) {
            //     var othersCount = selectedRows.length - maxToShow;
            //     selectedRowsString +=
            //     ' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
            // }
            // console.log(selectedRowsString);
            //document.querySelector('#selectedRows').innerHTML = selectedRowsString;
        },*/
        onGridReady(params) {
            this.rowSelection = 'multiple';
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
            this.gridApi.sizeColumnsToFit({
                // defaultMinWidth: 100,
                columnLimits: [
                    { key: 'spv_name', minWidth: 200 },
                    { key: 'fond_name', minWidth: 150 },
                    { key: 'year', maxWidth: 100 },
                    { key: 'month', maxWidth: 100 }
                ],
            });
        },
        openDetails(id) {
            window.location.href = window.location.origin + "/invoices/" + id
        },
    }
}
</script>