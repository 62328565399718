<template>
<div id="content" class="app-content">
    <b-container>
        <b-row class="justify-content-center">
            <b-col xl="12">
                <h1 class="page-header">
                    {{ $t('general.Failures') }}
                </h1>
                <hr class="mb-4" />
            </b-col>
            <b-col xl="12">
            
                <b-card>
                    <b-card-text class="card-text mb-3"></b-card-text>

                    <b-list-group class="border-0" flush>
                        <b-form-group
                            :label="$t('plants.Plant')"
                        >
                            <b-form-select
                                v-model="formData.plant"
                                :options="plants"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group
                            :label="$t('general.startDate')">
                            <b-form-datepicker v-model="formData.date_start" :min="min" :locale="locale"></b-form-datepicker>
                            <b-form-timepicker v-model="formData.time_start" :locale="locale"></b-form-timepicker>
                        </b-form-group>
                        <b-form-group
                            :label="$t('general.endDate')">
                            <b-form-datepicker v-model="formData.date_end" :min="min" :locale="locale"></b-form-datepicker>
                            <b-form-timepicker v-model="formData.time_end" :locale="locale"></b-form-timepicker>
                        </b-form-group>
                        <b-form-group
                            :label="$t('failures.Setpoint')"
                        >
                            <b-form-input
                                v-model="formData.setpoint"
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                            ></b-form-input>
                        </b-form-group>
                    </b-list-group>
                    <b-form-group
                            :label="$t('failures.Reason')"
                        >
                            <b-form-select
                                v-model="formData.failure_reason"
                                :options="failureReasons"
                            ></b-form-select>
                    </b-form-group>
                    <div id="grid-form-buttons" class="mt-3">
                        <b-button
                            variant="primary"
                            @click="submit"
                            :disabled="isSaving"
                        >
                            {{ $t('form.submit') }}
                        </b-button>
                        <button
                            @click="$router.push('/grids/silver-plant-failures')"
                            class="btn btn-secondary"
                            type="button">
                            {{ $t('form.cancel') }}
                        </button>
                    </div>
                </b-card>
            </b-col>

        </b-row>

    </b-container>
</div>
</template>
<script>

import moment from 'moment';
import axios from 'axios';

import { getAPIObjectURL } from '../_services/TechnicalMgmtService';
import CommercialMgmtService from '../_services/CommercialMgmtService';

export default {	
    name: 'PlantFailuresForm',
    props: {
        id: Number,
    },
    data() {
        return {
            isSaving: true,
            formData: {
                date_start: moment().add(4, 'hours').format('YYYY-MM-DD'),
                time_start: moment().add(4, 'hours').format('HH:mm:ss'),
                date_end: moment().add(5, 'hours').format('YYYY-MM-DD'),
                time_end: moment().add(5, 'hours').format('HH:mm:ss'),
            },
            plants: [],
            locale: localStorage.getItem("customLocale"),
            failureReasons: [
                { "value": "TXT_FIRE", "text": this.$t('failures.TXT_FIRE') },
                { "value": "TXT_THEFT", "text": this.$t('failures.TXT_THEFT') },
                { "value": "TXT_POWER_FAILURE", "text": this.$t('failures.TXT_POWER_FAILURE') },
                { "value": "TXT_WEATHER", "text": this.$t('failures.TXT_WEATHER') },
                { "value": "TXT_INVERTER_PROBLEM", "text": this.$t('failures.TXT_INVERTER_PROBLEM') },
                { "value": "TXT_COMMUNICATION_PROBLEM", "text": this.$t('failures.TXT_COMMUNICATION_PROBLEM') },
                { "value": "TXT_OTHER", "text": this.$t('failures.TXT_OTHER') }
            ],
            min: moment().add(3, 'hours').format('YYYY-MM-DD'),
        };
    },
    async created() {
        const [plants, failuresResponse] = await Promise.all([
            CommercialMgmtService.plants(),
            this.id && axios.get(getAPIObjectURL('silver-plant-failures', this.id))
        ]);
        this.plants = plants.map(plant => {
            return {
                value: plant.id,
                text: plant.name,
                SAPR: plant.SAPR,
            };
        });
        if (!Number.isNaN(failuresResponse)) {
            const data = failuresResponse.data.data;
            const startDate = moment(data.date_start);
            const endDate = moment(data.date_end);
            this.formData = {
                plant: data.plant_id,
                date_start: startDate.format('YYYY-MM-DD'),
                time_start: startDate.format('HH:mm:ss'),
                date_end: endDate.format('YYYY-MM-DD'),
                time_end: endDate.format('HH:mm:ss'),
                setpoint: data.setpoint,
                failure_reason: data.failure_reason,
            };
        }
        else {
            this.formData.plant = this.plants[0]?.value;
        }
        this.isSaving = false;
    },
    methods: {
        async submit() {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            try {
                const plant = this.plants.find(plant => plant.value === this.formData.plant);
                const data = {
                    id: this.id,
                    SAPR: plant.SAPR,
                    date_start:  this.formData.date_start + ' ' + this.formData.time_start,
                    date_end: this.formData.date_end + ' ' + this.formData.time_end,
                    setpoint: this.formData.setpoint,
                    failure_reason: this.formData.failure_reason,
                };
                if (data.date_start > data.date_end) {
                    this.$alert(this.$t('failures.AfterStart'), null, 'error');
                    this.isSaving = false;
                    return;
                }
                const minStartDate = moment().add(3, 'hours').format('YYYY-MM-DD HH:mm:ss');
                if (data.date_start < minStartDate) {
                    this.$alert(this.$t('failures.ThreeHours'), null, 'error');
                    this.isSaving = false;
                    return;
                }
                const response = await axios.post(getAPIObjectURL('silver-plant-failures'), data);
                this.$alert(this.$t('form.success'));
                this.$router.push('/plant-failures/form/' + response.data.id);
            }
            catch (e) {
                this.$alert(this.$t('form.error'), null, 'error');
                console.error('error while submitting form', e);
            }
            this.isSaving = false;
        },
    },
}
</script>
<style scoped>
#grid-form-buttons {
    margin-right: 0.5em;
}
</style>