<template>
    <b-modal id="modal-newFile" centered ref="modal" title="Upload file" ok-title="SAVE" :ok-disabled="okdisable" ok-variant="success" cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            
			<!--div class="row mt-2">
				<label for="category" class="col-md-2 col-form-label">New password</label>
                <div class="col-md-10">
					<b-form-input type="text" id="category" v-model="password.new" :state="newPasswordState" aria-describedby="input-category-feedback" placeholder="select category" trim></b-form-input>
                    <b-form-invalid-feedback id="input-category-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div--> 

			<div class="row mt-2">
				<label class="col-md-2 col-form-label" for="uploadFile">Choose file</label>
				<div class="col-md-10">
					<input type="file" class="form-control" id="uploadFile" @change="handleFileUpload( $event )" :watch="fileState" :state="fileState" /> 
				</div>
			</div>
			
        </form>
    </b-modal>
</template>

<script>

import { defaultStore } from '../_store/storage';

import axios from 'axios'

export default {
    name: 'ModalNewFile',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {		
			folder: this.$parent.folderRoute,
			file: '',
			show: true,
			okdisable: true,
        }
    },
    computed: {
		fileState() {
			return this.file === '' ? false : true
		},
    },
	watch: { 
		fileState() {
			if(this.file) {
				this.okdisable = false;
			} else {
				this.okdisable = true;
			}
		},
	},
    methods: {
        /* MODAL */
        // Validator function
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
            this.file = '';

			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

			//console.log(this.file)
			//this.file = this.$refs.file.files[0];

			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('path', this.folder.fullPath);

			console.log( this.folder.fullPath )
			console.log( formData.get('path') )

			axios.post( 'https://edm.psaierenergies.it/files/upload', formData, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
					"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
				},
				responseType: "json",
			}).then(response => {
				if(response.status == 200) {
					this.$parent.getTree();
				}
			}).catch(err => {
				console.log(err.response);
			});

            this.$nextTick(() => {
                this.$bvModal.hide('modal-newFile')
            })
        },
		handleFileUpload( event ) {
			this.file = event.target.files[0];
		},
		getModalData() {
			
		}		
    },
	created() {
		
	}
}
</script>