/** @file only put non sensitive information in this file as it is shared with the frontend */

export const ROLE_NAME_ADMIN = 'admin';
export const ROLE_NAME_ELECTRICIAN = 'electrician';
export const ROLE_NAME_CUSTOMER = 'customer';

export const ROLES = [
  ROLE_NAME_ADMIN,
  ROLE_NAME_CUSTOMER,
  ROLE_NAME_ELECTRICIAN,
];

export const CUSTOMER_ENTITIES_AUTHORIZATION = [
  {
    entity: 'silver_funds',
    actions: ['read'],
  },
  {
    entity: 'silver_SPVs',
    actions: ['read'],
  },
  {
    entity: 'silver_plants',
    actions: ['read'],
  },
  {
    entity: 'invoice',
    actions: ['read'],
  },
  {
    entity: 'silver_plant_failures',
    actions: ['create', 'read', 'update'],
  },
];
