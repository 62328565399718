<template>
	<!-- BEGIN register -->
	<div class="register">
		<!-- BEGIN register-content -->
		<div class="register-content">
			<form v-on:submit="checkForm" name="register_form">
				<h1 class="text-center">Sign Up</h1>
				<p class="text-muted text-center">One Admin ID is all you need to access all the Admin services.</p>
				
				<div class="mb-3">
					<label class="form-label">Nome completo<span class="text-danger">*</span></label>
					<b-input size="lg" class="fs-15px" id="fullname" placeholder="e.g John Smith" @input="createUser" v-model="form.fullname" :state="fullnameState"></b-input>

					<b-form-invalid-feedback id="input-fullname-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>

				<div class="mb-3">
					<label class="form-label">Nome utente<span class="text-danger">*</span></label>
					<div class="input-group flex-nowrap">
						<span class="input-group-text" id="addon-wrapping">@</span>
						<b-input type="text" class="form-control" id="username" placeholder="username" v-model="form.uniqueName" :state="usernameState" ></b-input>
					</div>

					<b-form-invalid-feedback id="input-username-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>

				<div class="mb-3">
					<label class="form-label">Email <span class="text-danger">*</span></label>
					<b-input type="email" size="lg" class="fs-15px" placeholder="username@address.com" v-model="form.email" :state="emailState"></b-input>

					<b-form-invalid-feedback id="input-email-feedback">Field cannot be empty</b-form-invalid-feedback>	
				</div>				

				<div class="mb-3">
					<label class="form-label">Password <span class="text-danger">*</span></label>
					<div class="col-md-12">
						<div class="input-group flex-nowrap">
							<b-input type="text" class="form-control" id="password" placeholder="password" v-model="form.password" :state="passwordState"/>
							<button type="button" class="input-group-text btn btn-primary" @click="passGenerate">generate</button>
						</div>
						<b-form-invalid-feedback id="input-password-feedback">Field cannot be empty</b-form-invalid-feedback>
					</div>					
				</div>

				<div class="mb-3">
					<label class="form-label">Role <span class="text-danger">*</span></label>
					<b-form-select class="form-select" size="lg" id="roles" v-model="form.roles" :options="roles_opt" :state="roleState"></b-form-select>
					<b-form-invalid-feedback id="input-roles-feedback">Please select role</b-form-invalid-feedback>
				</div>

				<div class="mb-3">
					<label class="form-label">Language <span class="text-danger">*</span></label>
					<b-form-select class="form-select" size="lg" id="language" v-model="form.languageId" :options="lang_opt" :state="languageState"></b-form-select>
					<b-form-invalid-feedback id="input-language-feedback">Please select language</b-form-invalid-feedback>
				</div>
				<div class="mb-3">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" value="" id="customCheck1" />
						<label class="form-check-label" for="customCheck1">I have read and agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</label>
					</div>
				</div>
				
				<div class="mb-3">
					<button type="submit" class="btn btn-primary btn-lg fs-15px fw-500 d-block w-100" :disabled='isDisabled'>Sign Up</button>
				</div>

				<div class="text-muted text-center">
					Already have an Admin ID? <router-link to="/user/login">Sign In</router-link>
				</div>
			</form>
		</div>
		<!-- END register-content -->
	</div>
	<!-- END register -->
</template>

<script>
	import AppOptions from '../config/AppOptions.vue'
	import moment from 'moment'

	export default {
		props : {
			type: {
				type: String,
				default: 'text'
			},
			size: {
				type: String,
				default: '8'
			},
			characters: {
				type: String,
				default: 'a-z,A-Z,0-9'
			},
			placeholder: {
				type: String,
				default: 'Password'
			},
			auto: [String, Boolean],
		},
		data() {
			return {
				password: '',
				roles_opt: [
					{ value: 0, text: 'select role' },
					{ value: 1, text: 'admin' },
					{ value: 2, text: 'accountant' },
					{ value: 3, text: 'electrician' }
				],
				lang_opt: [
					{ value: 0, text: 'select language' },
					{ value: 1, text: 'ita' },
				],
				form: {
					fullname: null,
					roles: 0,
					languageId: 0,
					uniqueName: null,
					email: null,
					password: '',
					dateReg: null,
					isAdmin: 1,
					active: 1
				}
			}
		},
		computed: {
			isDisabled: function(){
				return  !this.languageState || !this.roleState || !this.passwordState || !this.emailState || !this.usernameState || !this.fullnameState
			},
			languageState() {
				return this.form.languageId > 0 ? true : false
			},
			roleState() {
				return this.form.roles > 0 ? true : false
			},
			passwordState() {
				return this.form.password === '' ? false : true
			},
			emailState() {
				return this.form.email === null ? false : true
			},
			usernameState() {
				return this.form.uniqueName === null ? false : true
			},
			fullnameState() {
				return this.form.fullname === null ? false : true
			},
		},
		mounted() {
			AppOptions.appWithoutSidebar = true;
			AppOptions.appWithoutHeader = true;
			AppOptions.appContentFullWidth = true;
		},
		beforeRouteLeave (to, from, next) {
			AppOptions.appWithoutSidebar = false;
			AppOptions.appWithoutHeader = false;
			AppOptions.appContentFullWidth = false;
			next();
		},
		methods: {
			createUser (e) {
				let res = e.toLowerCase().replace(/\s/g, ".");
				this.form.uniqueName = res;
			},
			checkForm: function(e) {
				e.preventDefault();

				this.form.dateReg = moment().format('YYYY-MM-DDTHH:mm:ss');

				console.log(JSON.stringify(this.form));
				//this.$router.push({ path: '/dashboard'})

			},
			passGenerate () {
				let charactersArray = this.characters.split(',');  
				let CharacterSet = '';
				let password = '';
				
				if( charactersArray.indexOf('a-z') >= 0) {
					CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
				}
				if( charactersArray.indexOf('A-Z') >= 0) {
					CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
				}
				if( charactersArray.indexOf('0-9') >= 0) {
					CharacterSet += '0123456789';
				}
				if( charactersArray.indexOf('#') >= 0) {
					CharacterSet += '![]{}()%&*$#^<>~@|';
				}
				
				for(let i=0; i < this.size; i++) {
					password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
				}
				this.form.password = password;
			}
			
		}
	}
</script>