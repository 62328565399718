<template>
	<div class="login">
		<!-- <div class="login-content" v-if="!isAuthenticated">
			<button @click="loginAdmin" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Login admin</button>
		</div> -->

		<div class="login-content" v-if="!isAuthenticated">			
			
			<form ref="form" @submit.stop.prevent="loginUser">
				<div class="row mt-2">
					<label for="uid" class="col-md-12 col-form-label">User ID</label>
					<div class="col-md-12">
						<b-form-input id="uid" v-model="form.name" aria-describedby="input-uid-feedback" placeholder="user" trim required autofocus></b-form-input>
						<b-form-invalid-feedback id="input-uid-feedback">Field cannot be empty</b-form-invalid-feedback>
					</div>
				</div>
				
				<div class="row mt-2">
					<label for="uip" class="col-md-12 col-form-label">Password</label>
					<div class="col-md-12">
						<b-form-input type="password" id="uip" v-model="form.password" aria-describedby="input-uip-feedback" placeholder="password" trim required></b-form-input>
						<b-form-invalid-feedback id="input-uip-feedback">Field cannot be empty</b-form-invalid-feedback>
					</div>
				</div>

				<div class="row mt-2">
					<button class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Login user</button>
				</div>
				
			</form>
		</div>
		<div class="login-content" v-else>
			<h2 class="text-center">Welcome {{ userName }}</h2>
			<button @click="loginAdmin" class="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Sign in</button>
		</div>
	</div>
</template>

<script>
import AppOptions from '../config/AppOptions.vue'
import { AuthenticationContext } from 'vue-adal'

import authService from '../_services/auth.service';

export default {
	name: 'UserLogin',
	data() {
        return {		
			form: {
				user: '',
				password: '',
			},
			show: true,
			landingPage: '/grids/silver-funds',
        }
    },
	computed: {
		isAuthenticated() {
			let isAuth = AuthenticationContext.isAuthenticated();
			if (isAuth != false) {
				let profile = AuthenticationContext.user.profile
				let roles = null
				localStorage.setItem('user', JSON.stringify(profile));
				if ('roles' in profile) {
					roles = profile.roles
				}
				localStorage.setItem('roles', JSON.stringify(roles))
			}
			return isAuth
		},
		userName() {
			if (this.isAuthenticated) {
				let profile = AuthenticationContext.user.profile
				if (profile.name != null || profile.name != '') {
					return profile.name
				} else if (profile.unique_name != null || profile.unique_name != '') {
					return profile.unique_name
				}
			}
			return ''
		}
	},
	mounted() {
		AppOptions.appWithoutSidebar = true;
		AppOptions.appWithoutHeader = true;
		AppOptions.appContentFullWidth = true;
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appWithoutSidebar = false;
		AppOptions.appWithoutHeader = false;
		AppOptions.appContentFullWidth = false;
		next();
	},
	methods: {
		loginAdmin: function () {
			if (!this.isAuthenticated) {
				AuthenticationContext.login()
			} else {
				this.$router.push({ path: this.landingPage })
			}
		},
		loginUser: function() {
			authService.login(this.form.name, this.form.password).then(() => {
				this.$router.push({ path: this.landingPage });
				window.location.reload();
            }).catch((err) => {
				if (err?.response?.data?.message?.startsWith('auth:')) {
					this.$alert(this.$t("login.wrongUsernameOrPassword"), "Error", "error");
					return;
				}
				if (err?.response?.status === 400 && err?.response?.data?.message) {
					this.$alert(err.response.data.message, "Error", "error");
					return;
				}
				this.$alert(err.message, "Error", "error");
			});

		},
		//@click="warn('Form cannot be submitted yet.', $event)"
		warn: function (message, event) {
			// now we have access to the native event
			if (event) {
				event.preventDefault()
			}
			alert(message)
		}
	}
}
</script>