<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<b-breadcrumb>
			<b-breadcrumb-item href="#">PAGES</b-breadcrumb-item>
			<b-breadcrumb-item active>SEARCH RESULTS</b-breadcrumb-item>
		</b-breadcrumb>
		
		<h1 class="page-header">
			Search Results <small>page header description goes here...</small>
		</h1>
		
		<!-- BEGIN search-result -->
		<div class="search-result">
			<!-- BEGIN search-input -->
			<div class="search-input">
				<form name="search_form">
					<b-link href="#" class="search-close" data-clear-form="#search">&times;</b-link>
					<b-input size="lg" value="MacBook"></b-input>
				</form>
			</div>
			<!-- END search-input -->
			
			<!-- BEGIN search-tab -->
			<div class="search-tab">
				<div class="search-tab-item"><b-link href="#" class="search-tab-link active">Explore</b-link></div>
				<div class="search-tab-item"><b-link href="#" class="search-tab-link">Accessories</b-link></div>
				<div class="search-tab-item"><b-link href="#" class="search-tab-link">Support</b-link></div>
				<div class="search-tab-item"><b-link href="#" class="search-tab-link">Store</b-link></div>
			</div>
			<!-- END search-tab -->
			
			<!-- BEGIN search-result -->
			<div class="search-result">
				<!-- BEGIN search-result-list -->
				<div class="search-result-list" v-if="items.length > 0">
					<div class="search-result-item" v-for="item in items" v-bind:key="item.id">
						<div class="search-result-media" v-if="item.img">
							<b-link v-bind:href="item.url"><img v-bind:src="item.img" alt="" /></b-link>
						</div>
						<div class="search-result-content">
							<h3><b-link v-bind:href="item.url">{{ item.title }}</b-link></h3>
							<p>{{ item.desc }}</p>
							<template v-for="(link, linkIndex) in item.link">
								<b-link v-bind:href="link.url" v-bind:key="linkIndex" class="me-4">{{ link.text }}</b-link>
							</template>
						</div>
					</div>
				</div>
				<div class="search-result-list" v-else>
					<div class="search-result-item">
						No record found
					</div>
				</div>
				<!-- END search-result-list -->
				
				<!-- BEGIN pagination -->
				<div class="text-center mt-4 mb-5">
					<b-pagination v-model="currentPage" v-on:input="changePagination" align="center" :total-rows="rows" :per-page="perPage"></b-pagination>
				</div>
				<!-- END pagination -->
			</div>
			<!-- END search-result -->
		</div>
		<!-- END search-result -->
	</div>
	<!-- END #content -->
</template>

<script>
  export default {
    data() {
      return {
        perPage: 5,
        currentPage: 1,
        items: [],
        allItems: [{
					id: 1,
					title: 'MacBook',
					desc: 'MacBook features sixth-generation Intel Core processors, improved graphics performance, and up to 10 hours of battery life.',
					url: '#',
					img: '/assets/img/product/product.png',
					link: [{
						url: '#',
						text: 'Learn more'
					},{
						url: '#',
						text: 'MacBook Buy'
					},{
						url: '#',
						text: 'MacBook Support'
					}]
				},{
					id: 2,
					title: 'MacBook - Apple',
					desc: 'The incredibly thin and light MacBook features sixth-generation processors, improved graphics performance, and up to 10 hours of battery life....',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook/'
					}]
				},{
					id: 3,
					title: 'MacBook Pro - macOS - Apple',
					desc: 'MacBook Pro - macOS - Apple macOS. It’s why there’s nothing else like a Mac. macOS is the operating system that powers every Mac. It lets you do things you simply can’t with other computers. That’s...',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook-pro/macos/'
					}]
				},{
					id: 4,
					title: 'MacBook Air - Design - Apple',
					desc: 'MacBook Air is incredibly thin and light. But it’s also powerful, durable, and enjoyable to use. With enough battery life to get you through the day....',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook-air/design/'
					}]
				},{
					id: 5,
					title: 'MacBook Air - Performance - Apple',
					desc: 'MacBook Air is packed with powerful components that give you smoother graphics, faster storage, and all-around serious performance....',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook-air/performance/'
					}]
				},{
					id: 6,
					title: 'MacBook Air - Technical Specifications - Apple',
					desc: 'View the technical specifications for MacBook Air, see what comes in the box, explore configuration options, and get a list of accessories....',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook-air/specs/'
					}]
				},{
					id: 7,
					title: 'MacBook - Design - Apple',
					desc: 'MacBook is the thinnest and lightest Mac notebook ever — each detail refined for maximum efficiency and portability.',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook/design/'
					}]
				},{
					id: 8,
					title: 'MacBook - Technical Specifications - Apple',
					desc: 'View the technical specifications for MacBook. See what comes in the box and get a list of accessories.',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook/specs/'
					}]
				},{
					id: 9,
					title: 'MacBook - Wireless - Apple',
					desc: 'MacBook is designed to make the most of the wireless world. Share files, browse the web, print, and more — without being tied down to a cable....',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook/wireless/'
					}]
				},{
					id: 10,
					title: 'MacBook - macOS - Apple',
					desc: 'MacBook - macOS - Apple macOS. It’s why there’s nothing else like a Mac. macOS is the operating system that powers every Mac. It lets you do things you simply can’t with other computers. That’s...',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/macbook/macos/'
					}]
				},{
					id: 11,
					title: 'Buy Mac Accessories - Apple',
					desc: 'inch MacBook Air and MacBook Pro - Saddle Brown $179.00 Available colors: Pro Display XDR - Standard glass $4,999.00 LG UltraFine 5K Display $1,299.95 61W USB-C Power Adapter $69.00 USB-C to USB Adapt…',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/shop/mac/mac-accessories'
					}]
				},{
					id: 12,
					title: 'AirPods (2nd generation) - Technical Specifications - Apple',
					desc: 'Plus11 iPhone 611 iPhone 6 Plus11 iPhone 5s11 iPod Models iPod touch (7th generation) iPod touch (6th generation)11 Mac Models11 MacBook (Retina, 12-inch, Early 2015–2017) MacBook Air (11-inch, Mid 20…',
					url: '#',
					link: [{
						url: '#',
						text: 'https://www.apple.com/airpods-2nd-generation/specs/'
					}]
				}]
      }
    },
		mounted: function() {
			this.items = this.allItems.slice((this.currentPage - 1), this.perPage);
		},
		methods: {
			changePagination(page) {
				this.items = this.allItems.slice(((page - 1) * this.perPage), (((page - 1) * this.perPage) + this.perPage));
			}
		},
    computed: {
			rows() {
				return this.allItems.length;
			}
    }
  }
</script>