<template>
	<div id="content" class="app-content">
				<b-breadcrumb>
					<b-breadcrumb-item href="#">Dashboard</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ $t('plants.Plants') }}</b-breadcrumb-item>
				</b-breadcrumb>
				<!-- BEGIN col-10 -->
				<b-col xl="12">

					<b-row>
						<b-col><h2 class="page-header">{{ $t('plants.Plants') }}</h2></b-col>
						<b-col md="6" sm="6" xs="6" class="mb-1 text-truncate" style="text-align: right">
							<font-awesome-icon
								:icon="['fas', 'plus-square']"
								@click="$router.push('/forms/plants')"
								size="2xl"
								style="cursor: pointer; margin-left: 0.5em;"
								:title="$t('form.new')"
							/>
						</b-col>
					</b-row>

					<hr class="mb-4" />

					<b-row>
						<b-col xl="12">	
							<div>			
								<ag-grid-vue
									@grid-ready="onGridReady"
									class="ag-theme-alpine"
									style="width: 100%;
									height: 100%"
									@row-double-clicked="openDetail"
									:columnDefs="columnDefs"
									:paginationPageSize="paginationPageSize"
									:pagination="true"
									@first-data-rendered="onFirstDataRendered"	
									rowModelType="serverSide"
									:cacheBlockSize="cacheBlockSize"
									@grid-size-changed="resizeColumns"
									:defaultColDef="defaultColDef"
								/>
							</div>
						</b-col>	
					</b-row>

				</b-col>
	</div>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
//import authService from '../_services/auth.service'

import { defaultStore } from '../_store/storage';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import {defaultColDef} from '../config/agGridDefaults';

export default {
    mounted() {
        // window.addEventListener('resize', this.resizeColumns);
		// this.gridOptions.api.addEventListener('gridSizeChanged', this.resizeColumns);
    },
	name: 'Plants',
	setup() {
		const store = defaultStore()
		return { store}
	},
	components: {
		AgGridVue
	},
	data() {
		return {
			plants: [],
			columnDefs: [
				{ headerName: this.$t('plants.Name'), field: 'name', sortable: true, minWidth: 100, maxWidth: 300, suppressSizeToFit: true, },
				{ headerName: this.$t('plants.Type'), field: 'plant_type_name', sortable: true, },
				{ headerName: 'SAPR', field: 'SAPR', sortable: true, },
				{ headerName: 'POD', field: 'POD', sortable: true, },
				{ headerName: 'IPEX unit code', field: 'IPEX_unit_code', sortable: true, },
				{ headerName: 'Trader', field: 'trader_name', sortable: true, },
			],
			gridApi: null,
			columnApi: null,
			rowSelection: null,
			defaultColDef: defaultColDef,
			rowData: null,
			user: JSON.parse(localStorage.getItem('user')),
		}
	},
	methods: {
        resizeColumns() {
            this.gridApi.sizeColumnsToFit();
        },
		getPlants() {
			this.store.setPlantID(null);
			
			TechnicalMgmtService.getPlants(this.userId).then((response) => {
				this.plants = response.data;
			}).catch((err) => {
				console.log(err);
				this.plants = [{ "plantId": "ND", "name": "ND", "type": "ND", "city": "ND", "coeffProduction": "ND", "voltage": "ND", "ceNumber": "ND", "pmax": "ND", "sapr": "ND", "pod": "ND", "IPEX_unit": "ND" }];
			});
		},
		openDetail({data}) {
			this.$router.push({path: '/forms/plants/' + data.id });
		},
		onFirstDataRendered(params) {
			params.api.sizeColumnsToFit();
		},
		onGridReady(params) {
			const dataSource = {
				getRows: async params => {
					try {
						const response = await TechnicalMgmtService.getPlantsV2(params.request)
						params.success({
							rowData: response.data.data,
							rowCount: response.data.count,
						});
					}
					catch (e) {
						console.error('error while fetching plant data', e);
						params.fail();
					}
				}
			};			
			this.gridApi = params.api;
			this.gridApi.setServerSideDatasource(dataSource);
			this.columnApi = params.columnApi;
		},
	},
	created() {
		//console.log( localStorage.getItem('user') );
		// this.getPlants();
		//this.getPlantsById();
		this.paginationPageSize = 10;
		this.cacheBlockSize = 100;
	}
}
</script>
