<template>
	<div id="header" class="app-header theme-blue bg-dark bg-gradient">

		<!-- BEGIN mobile-toggler -->
		<div class="mobile-toggler">
			<button type="button" class="menu-toggler" v-on:click="toggleAppSidebarMobile">
				<span class="bar"></span>
				<span class="bar"></span>
				<span class="bar"></span>
			</button>
		</div>
		<!-- END mobile-toggler -->

		<!-- BEGIN brand -->
		<div class="brand">
			<div class="desktop-toggler">
				<button type="button" class="menu-toggler" v-on:click="toggleAppSidebarMinify" style="color: white">
					<span class="bar"></span>
					<span class="bar"></span>
					<span class="bar"></span>
				</button>
			</div>

			<a href="/grids/silver-funds" class="brand-logo">
				<div>
					<img height="30" class="m-3" width=auto src="/assets/img/logo_psaier_header.png" />
				</div>
			</a>
		</div>
		<!-- END brand -->

		<!-- BEGIN menu -->
		<div class="menu">
			<!--form class="menu-search" name="header_search_form" v-on:submit="checkForm">
			<div class="menu-search-icon"><i class="fa fa-search"></i></div>
			<div class="menu-search-input">
				<input type="text" class="form-control" placeholder="Search menu..." />
			</div>
		</form-->

			<div class="menu-search"></div>

			<div class="menu-item" right d-flex>
				<button v-for="language in languages" :key="language.id" class="m-1 py-1 btn btn-outline-light btn-sm"
					:class="{ 'active': language.language == selectedLanguage }" @click="changeLanguage(language.language)">
					{{ language.language }}
				</button>
			</div>



			<!-- <b-dropdown v-show="notification" class="menu-item" right toggle-tag="a" variant="link" :no-caret="true"
				toggle-class="menu-link" menu-class="dropdown-notification">
				<template v-slot:button-content>
					<div class="menu-icon"><i class="fa fa-bell" style="color: white"></i></div>
					<div class="menu-label">{{ notificationData.length }}</div>
				</template>
				<h6 class="dropdown-header text-dark mb-1">Notifications</h6>
				<template v-if="notificationData && notificationData.length > 0">
					<a href="/invoices/monthly-invoices" class="dropdown-notification-item"
						v-for="(notification, index) in notificationData" v-bind:key="index">
						<div class="dropdown-notification-icon">
							<i v-if="notification.icon" v-bind:class="notification.icon"></i>
							<img v-if="notification.image" v-bind:src="notification.image" width="26" />
						</div>
						<div class="dropdown-notification-info">
							<div class="title">{{ notification.title }}</div>
							<div class="time">{{ notification.time }}</div>
						</div>
						<div class="dropdown-notification-arrow">
							<i class="fa fa-chevron-right" style="color: white"></i>
						</div>
					</a>
				</template>
				<template v-else>
					<div class="dropdown-notification-item">
						No record found
					</div>
				</template>
				<div class="p-2 text-center mb-n1">
					<a href="#" class="text-dark text-decoration-none">See all</a>
				</div>
			</b-dropdown> -->
			<!--user-->
			<b-dropdown class="menu-item" right toggle-tag="a" variant="link" :no-caret="true"
				toggle-class="menu-link border-0">
				<template v-slot:button-content>
					<div class="menu-img online">
						<i class="fas fa-lg fa-fw me-2 fa-user-circle" style="color: white"></i>
					</div>
					<div class="menu-text" style="color: white">{{ userName }}</div>
				</template>
				<!--b-dropdown-item link-class="d-flex align-items-center" href="#">
				Edit Profile <i class="fa fa-user-circle fa-fw ms-auto text-gray-400 fs-16px"></i>
			</b-dropdown-item>
			<b-dropdown-item link-class="d-flex align-items-center" href="#">
				Inbox <i class="fa fa-envelope fa-fw ms-auto text-gray-400 fs-16px"></i>
			</b-dropdown-item>
			<b-dropdown-item link-class="d-flex align-items-center" href="#">
				Calendar <i class="fa fa-calendar-alt fa-fw ms-auto text-gray-400 fs-16px"></i>
			</b-dropdown-item>
			<b-dropdown-item link-class="d-flex align-items-center" href="#">
				Setting <i class="fa fa-wrench fa-fw ms-auto text-gray-400 fs-16px"></i>
			</b-dropdown-item>
			<b-dropdown-divider /-->
				<!-- <b-dropdown-item link-class="d-flex align-items-center" href="/user/profile">
					Profile <i class="fa fa-user fa-fw ms-auto text-gray-400 fs-16px"></i>
				</b-dropdown-item> -->
				<b-dropdown-item link-class="d-flex align-items-center" @click="logout" href="#">
					Log Out <i class="fa fa-sign-out-alt fa-fw ms-auto text-gray-400 fs-16px"></i>
				</b-dropdown-item>
			</b-dropdown>
		</div>
		<!-- END menu -->
	</div>
</template>

<script>
import AppOptions from '../config/AppOptions.vue'
import { AuthenticationContext } from 'vue-adal'
import authService from '../_services/auth.service'

// import { i18n } from '@/plugins/i18n.js';

import moment from 'moment'

export default {
	name: 'Header',
	data() {
		return {
			appOptions: AppOptions,
			userName: '',
			notificationData: [{
				icon: 'fa fa-receipt fa-fw fa-lg text-success',
				title: 'New invoices generated',
				time: 'just now'
			}],
			notification: 0,
			languages: [
				{ id: 1, language: 'en' },
				{ id: 2, language: 'de' },
				{ id: 3, language: 'it' },
			],
			selectedLanguage: localStorage.getItem("customLocale")
		}
	},
	mounted() {
		let loggedIn = JSON.parse(localStorage.getItem('user'));
		//console.log(loggedIn)
		if (loggedIn != null) {
			if (loggedIn.name != null || loggedIn.name != '' || loggedIn.fullname != '') {
				this.userName = loggedIn.name || loggedIn.fullname;
				//console.log(this.userName)
			} else if (loggedIn.unique_name != null || loggedIn.unique_name != '') {
				this.userName = loggedIn.unique_name
			}
		}
		this.notification = JSON.parse(localStorage.getItem('notification'));
	},
	created() {
		moment.locale(this.selectedLanguage);
	},
	methods: {
		changeLanguage(language) {
			if (language != this.selectedLanguage) {
				localStorage.setItem('customLocale', language);
				window.location.reload();
			}
		},

		toggleAppSidebarMobile() {
			this.appOptions.appWithoutSidebar = !this.appOptions.appWithoutSidebar;
			this.appOptions.appContentFullWidth = !this.appOptions.appContentFullWidth;
		},
		toggleAppSidebarMinify() {
			this.appOptions.appWithoutSidebar = !this.appOptions.appWithoutSidebar;
			this.appOptions.appContentFullWidth = !this.appOptions.appContentFullWidth;
		},
		checkForm: function (e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' })
		},
		async logout() {
			try {
				if (AuthenticationContext.isAuthenticated()) {
					AuthenticationContext.logout();
				}
				else {
					await authService.logout();
				}
			}
			catch (e) {
				console.error(e);
			}
			finally {
				localStorage.removeItem('user');
				this.$router.push({ path: '/login' });
				window.location.reload();
			}
		},
	}
}
</script>