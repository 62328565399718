import Vue from 'vue'
import App from './App.vue'
import routes from './config/AppRoutes'
import azureConfigs from './config/AzureConfigs'
import { i18n } from './plugins/i18n'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Adal from 'vue-adal';

// VueStudio SCSS
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/styles.scss'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faUserSecret)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-047577 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( psaier.energies srl )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Psaier-EDM )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Psaier-EDM )_need_to_be_licensed___( Psaier-EDM )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 November 2024 )____[v2]_MTczMDkzNzYwMDAwMA==46b601d81a9f9d139fc5e2688df623c5");

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(Adal, {
  config: {
    tenant: azureConfigs.tenant,
    clientId: azureConfigs.clientId,
    redirectUri: azureConfigs.redirectUri,
    cacheLocation: azureConfigs.cacheLocation,
    postLogoutRedirectUri: ''
  },
  requireAuthOnInitialize: azureConfigs.requireAuthOnInitialize,
  router: router
})
Vue.use(VueSimpleAlert)

Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)

router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/user/login', '/user/logout'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  
  if (authRequired && !loggedIn ) {
    console.log('User not logged in!');
    return next('/user/login');
  }
  next();
})

import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

router.onReady(() => {
  console.log('Router ready')
});

new Vue({
  el: '#app',
  router,
  pinia,
  i18n,
  render: h => h(App),
});
