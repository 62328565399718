<template>
	<div id="content" class="app-content">
		<b-container>
			<b-row class="justify-content-center">
				<b-col xl="12">
					<h1 class="page-header">
						<a @click="$router.back()" style="cursor: pointer;">{{ formatter.entityName(entityName) }}</a>
					</h1>
					<hr class="mb-4" />
				</b-col>
				<b-col xl="12">
				
					<b-card>
						<b-card-text class="card-text mb-3"></b-card-text>

						<b-list-group class="border-0" flush>
							<Form
								:api-object-name="entityName"
								:api-object-id="id"
								:is-edit="isEdit"
							>
							</Form>
						</b-list-group>
					</b-card>
				</b-col>

			</b-row>

		</b-container>
	</div>
</template>

<script>

import Form from '../components/Form.vue';
import formatter from '../helpers/formatter';

export default {	
	name: 'FormPage',
	components: {
		Form,
	},
	props: {
		id: {
			type: Number
		},
		entityName: {
			type: String,
		},
		isEdit: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			formatter: formatter,
		};
	},
}
</script>
