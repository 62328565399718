<template>
	<b-modal id="modal-newFailure" centered ref="modal" :title="this.$t('failures.InsertNewFailure')" :ok-title="this.$t('general.Save')"
		ok-variant="success" cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk"
		:ok-disabled="isDisabled">

		<form ref="form" @submit.stop.prevent="handleSubmit">

			<div class="row mt-2">
				<label for="plant" class="col-md-2 col-form-label">{{ $t('plants.Plant') }}</label>
				<div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="plant" v-model="form.plant.plantId"
						:options="plants" :state="plantState" aria-describedby="input-reason-feedback"></b-form-select>
				</div>
			</div>

			<div class="row mt-2">
				<label for="reason" class="col-md-2 col-form-label">{{ $t('failures.Reason') }}</label>
				<div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="reason" v-model="form.reason.reasonId"
						:options="reasons" :state="reasonState" aria-describedby="input-reason-feedback"></b-form-select>
					<b-form-invalid-feedback id="input-reason-feedback"></b-form-invalid-feedback>
				</div>
			</div>
			<div class="row mt-2">
				<label for="setpoint" class="col-md-2 col-form-label">{{ $t('failures.Setpoint') }} in %</label>
				<div class="col-md-10">
					<b-form-input id="setpoint" type="number" min="0" max="100" v-model="form.setpoint"
						:state="setpointState" aria-describedby="input-voltage-feedback" trim></b-form-input>
					<b-form-invalid-feedback id="input-setpoint-feedback"></b-form-invalid-feedback>
				</div>
			</div>
			<div class="row mt-2">
				<label for="start" class="col-md-2 col-form-label">{{ $t('failures.StartDate') }}</label>
				<div class="col-md-5">
					<b-form-datepicker id="start"
						:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :min="minDate"
						v-model="form.startDate" :state="startState" aria-describedby="input-start-feedback" placeholder=""
						trim></b-form-datepicker>
					<b-form-invalid-feedback id="input-start-feedback"></b-form-invalid-feedback>
				</div>
				<div class="col-md-5">{{ $t('general.Hour') }}
					<b-form-select id="start_time" v-model="form.startTime" :state="startTimeState"
						aria-describedby="input-start-feedback" trim :options="allHours" ></b-form-select>
						<b-form-invalid-feedback id="input-end-feedback">{{ $t('failures.ThreeHours') }}</b-form-invalid-feedback>
				</div>
			</div>
			<div class="row mt-2">
				<label for="end" class="col-md-2 col-form-label">{{ $t('failures.EndDate') }}</label>
				<div class="col-md-5">
					<b-form-datepicker id="end" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
						:min="minDate" v-model="form.endDate" :state="endState" aria-describedby="input-end-feedback"
						placeholder="" trim></b-form-datepicker>
					<b-form-invalid-feedback id="input-end-feedback"></b-form-invalid-feedback>
				</div>
				<div class="col-md-5">{{ $t('general.Hour') }}
					<b-form-select id="end_time" v-model="form.endTime" :state="endTimeState"
						aria-describedby="input-end-feedback" :options="allHours" trim></b-form-select >
						<b-form-invalid-feedback id="input-end-feedback">{{ $t('failures.AfterStart') }}</b-form-invalid-feedback>
				</div>
			</div>

		</form>

	</b-modal>
</template>

<script>

import moment from 'moment';
import CmngmService from '../_services/CommercialMgmtService';
import { defaultStore } from '../_store/storage';

export default {
	name: 'ModalNewFailures',
	components: {

	},
	setup() {
		const store = defaultStore()
		return { store }
	},
	data() {
		var range = []
		for (var i = 0; i <= 24; i++){
			range.push(i);
		}
		return {
			allHours: range,
			minDate: moment().format(),
			form: {
				reason: { reasonId: 'null' },
				setpoint: '',
				startDate: '',
				endDate: '',
				startTime: '0',
				endTime: '0',
				plant: { plantId: null }
			},
			reasons: [
				{ value: null, text: '' },
			],
			plants: [
				{ value: null, text: '' },
			]
		}
	},
	computed: {
		isDisabled: function () {
			return !this.reasonState || !this.setpointState || !this.startState || !this.startTimeState || !this.endState || !this.endTimeState || !this.plantState;
		},
		reasonState() {
			return this.form.reason.reasonId === 'null' ? false : true
		},
		setpointState() {
			return this.form.setpoint > 0 && this.form.setpoint < 100 ? true : false
		},
		startState() {
			let now = moment().add(2, 'hours').format('L');
			let startDate = moment(this.form.startDate);

			return startDate.isSameOrAfter(now) ? true : false;
		},
		startTimeState() {
			let earliestAllowed = moment().add(3, 'hours');
			let startTime = moment(this.form.startDate + ' ' + this.form.startTime, 'YYYY-MM-DD HH');

			return startTime.isAfter(earliestAllowed) ? true : false;
		},
		endState() {
			let now = moment(this.form.startDate).format('L');
			let startDate = moment(this.form.endDate);
			//console.log(now, startDate, startDate.isSameOrAfter( now ) );

			return startDate.isSameOrAfter(now) ? true : false;
		},
		endTimeState() {
			let endTime = moment(this.form.endDate + ' ' + this.form.endTime , 'YYYY-MM-DD HH');
			let startTime = moment(this.form.startDate + ' ' + this.form.startTime , 'YYYY-MM-DD HH');
			//console.log(now, startTime, startTime.isAfter( now ) );

			return endTime.isAfter(startTime) ? true : false;
		},
		plantState() {
			return this.form.plant.plantId === 'null' ? false : true
		}
	},
	methods: {
		isRequired(value) {
			return value ? true : 'This field is required';
		},
		getFormValues(event) {
			event.preventDefault();
		},
		resetModal() {
			this.form.reason.reasonId = 'null';
			this.form.startDate = '';
			this.form.startTime = '';
			this.form.endDate = '';
			this.form.endTime = '';
			this.form.plant.plantId = 'null';
			this.form.setpoint = '';

			this.$nextTick(() => {
				this.show = true
			});
		},
		handleOk(bvModalEvent) {
			bvModalEvent.preventDefault()

			this.form.startDate = moment(this.form.startDate + ' ' + this.form.startTime, 'YYYY-MM-DD HH').toISOString();
			this.form.endDate = moment(this.form.endDate + ' ' + this.form.endTime, 'YYYY-MM-DD HH').toISOString();


			CmngmService.regNewFailure(JSON.stringify(this.form)).then((response) => {
				if (response.status == 201) {
					//get technical after register
					CmngmService.getFailures().then((response) => {
						console.log(response);
						this.$parent.gridApi.setRowData(response.data)
					}).catch((err) => {
						console.log(err);
					});
				}
			}).catch((err) => {
				console.log(err);
			});

			this.$nextTick(() => {
				this.$bvModal.hide('modal-newFailure')
			})
		},
		getModalData() {
			CmngmService.getFailureReasonsList(JSON.stringify(this.form)).then((response) => {
				for (const [key, value] of Object.entries(response.data)) {
					this.reasons.push({ value: value.reasonId, text: value.description, index: key });
				}
			}).catch((err) => {
				console.log(err);
			});
			CmngmService.getPlants(JSON.stringify(this.form)).then((response) => {
				let array = this.sortedArray(response.data);

				for (const [key, value] of Object.entries(array)) {
					this.plants.push({ value: value.plantId, text: value.name, index: key });
				}

			}).catch((err) => {
				console.log(err);
			});
		},
		sortedArray(arrays) {
			function compare(a, b) {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			}
			return arrays.sort(compare);
		}
	},
	created() {
		this.getModalData();
	}
}
</script>