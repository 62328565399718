<template>
    <div class="container">
        <!-- <font-awesome-icon class="px-2" icon="fa-file-excel" @click="exportDetail()" style="cursor: pointer;" v-b-tooltip.hover title="Export Detail" /> -->
        <i class="position-absolute top-50 start-50 translate-middle fa-regular fa-circle-dot text-primary centered-element"
            v-b-tooltip.hover v-show="(this.params.node.data.status == 0)" :title="$t('invoices.ToPublish')"></i>
        <i class="position-absolute top-50 start-50 translate-middle fa-solid fa-circle text-primary" v-b-tooltip.hover
            v-show="(this.params.node.data.status == 1)" :title="$t('invoices.Published')"></i>
        <i class="position-absolute top-50 start-50 translate-middle fa-solid fa-circle text-success" v-b-tooltip.hover
            v-show="(this.params.node.data.status == 2)" :title="$t('invoices.Confirmed')"></i>
        <!-- <span class="badge rounded-pill text-bg-primary">Primary</span> -->
    </div>
</template>

<script>
export default {
    name: 'InvoiceActionIcons',
    data: function () {
        return {
            params: {
                node: {
                    data: {
                        status: 0
                    }
                }
            }
        };
    },
    beforeMount() { },
    mounted() { },
    methods: {
        exportDetail() {
            this.params.context.componentParent.exportDetail(this.params.node);
        },
        publishInvoice() {
            this.params.context.componentParent.publishInvoice(this.params.node);
        },
        showIssueInvoice() {
            this.params.context.componentParent.showIssueInvoice(this.params.node);
        },
        exportXML() {
            this.params.context.componentParent.exportXML(this.params.node);
        },
        exportPDF() {
            this.params.context.componentParent.exportPDF(this.params.node);
        },
    },
};
</script>