<template>

    <b-col xl="12" class="mb-2">

        <b-card>
            <b-card-title>{{ $t('invoices.CreateInvoices') }}</b-card-title>
            <b-card-text class="card-text mb-3"></b-card-text>
            <b-list-group class="border-0" flush>
                <div class="row">
                    <div class="col">
                        <label for="fund">{{ $t('general.Fund') }}</label>
                        <input id="fund" class="form-control" v-model="form.fund" :disabled="isCreatingInvoices" />
                    </div>
                    <div class="col">
                        <label for="producer">SPV</label>
                        <input id="producer" class="form-control" v-model="form.producer"
                            :disabled="isCreatingInvoices" />
                    </div>
                    <div class="col">
                        <label for="sapr">SAPR</label>
                        <input id="sapr" class="form-control" v-model="form.sapr" :disabled="isCreatingInvoices" />
                    </div>
                    <div class="col">
                        <label for="month">{{ $t('general.Month') }}</label>
                        <b-form-select class="form-select" id="month" v-model="form.month" :options="months"
                            :disabled="isCreatingInvoices" />
                    </div>
                    <div class="col">
                        <label for="year">{{ $t('general.Year') }}</label>
                        <b-form-select class="form-select" id="year" v-model="form.year" :options="years"
                            :disabled="isCreatingInvoices" />
                    </div>
                </div>
            </b-list-group>
            <b-list-group class="border-0 mt-2" flush>
                <div class="row">
                    <div class="col-3">
                        <b-button @click="createInvoices" variant="primary"
                            :disabled="isCreatingInvoices || !invoicesCreatable || invoicesCreatable.count === 0">{{ invoicesCreatableButtonText }}</b-button>
                        <span v-if="isCreatingInvoices || isCountingInvoices"><i class="fa-solid fa-spinner fa-spin-pulse fa-2xl"
                                style="margin-left: 0.5em;"></i></span>
                    </div>
                    <div class="col">
                        <b-progress v-if="isCreatingInvoices" :max="invoicesCreatable.count">
                            <b-progress-bar :value="invoiceCreateProgress" :label="`${((invoiceCreateProgress / invoicesCreatable.count) * 100).toFixed(0)}%`" animated></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </b-list-group>
            <div class="mt-2">
            </div>
        </b-card>
    </b-col>

</template>
<script>
import api from '../_services/CommercialMgmtService.js';

function previousMonth() {
    let month = new Date().getMonth() + 1;
    if (month === 1) {
        month = 12;
    } else {
        month--;
    }
    return month;
}

function yearOfPreviousMonth() {
    let year = new Date().getFullYear();
    let month = previousMonth();
    if (month === 12) {
        year--;
    }
    return year;
}

export default {
    name: 'InvoiceCreate',
    data() {
        return {
            form: {
                fund: '',
                sapr: '',
                producer: '',
                month: previousMonth(),
                year: yearOfPreviousMonth(),
            },
            months: Array.from({ length: 12 }, (v, i) => i + 1),
            years: Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i),
            invoicesCreatable: null,
            invoiceCreateProgress: 0,
            isCountingInvoices: false,
            isCreatingInvoices: false,
            lastInvoiceCountTrigger: null,
        }
    },
    watch: {
        form: {
            handler() {
                this.invoicesCreatable = null;
                this.invoicesCount();
            },
            deep: true
        }
    },
    created() {
        this.invoicesCount();
    },
    computed: {
        invoicesCreatableButtonText() {
            return this.invoicesCreatable ? `${this.$t('invoices.Create')} (${this.invoicesCreatable.count})` : this.$t('invoices.Create');
        },
    },
    methods: {
		async invoicesCount() {
            const now = new Date().getTime();
            this.lastInvoiceCountTrigger = now;
            setTimeout(async () => {
                try {
                    if (this.lastInvoiceCountTrigger !== now) {
                        return;
                    }
                    this.isCountingInvoices = true;
                    const response = await api.invoicesCount(this.form);
                    if (this.lastInvoiceCountTrigger !== now) {
                        return;
                    }
                    this.invoicesCreatable = response.data;
                } catch (error) {
                    this.$alert("Error", "Error", "error");
                    console.error(error);
                }
                finally {
                    if (this.lastInvoiceCountTrigger === now) {
                        this.isCountingInvoices = false;
                    }
                }
            }, 1000);
		},
        async createInvoices() {
            let i = 0;
            this.invoiceCreateProgress = 0;
            this.isCreatingInvoices = true;
            let results = [];
            let errors = [];
            for (const sapr of this.invoicesCreatable.data) {
                i++;
                try {
                    const result = await api.invoicesCreate({ ...this.form, sapr });
                    results.push(result);
                    this.invoiceCreateProgress = i;
                    this.$emit('invoice-created');
                } catch (error) {
                    errors.push({
                        sapr,
                        error,
                    });
                }
            }
            if (errors.length > 0) {
                this.$alert(`Error for sapr: ${errors.map(e => e.sapr).join(', ')}`, "Error", "error");
                console.error(errors);
            }
            else {
                this.$alert(this.$t('invoices.CreateSuccess'), "Success", "success");
            }
            this.isCreatingInvoices = false;
        }
	},
};
</script>