<template>
    <b-modal id="modal-newTask" centered ref="modal" title="New task" ok-title="SAVE" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk" :ok-disabled="isDisabled">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            
			<div class="row mt-2">
				<label for="plant" class="col-md-2 col-form-label">Plant</label>
                <div class="col-md-10">
					<b-form-select class="form-select" size="lg" id="plant" v-model="form.plant.plantId" :options="plants" :state ="plantState" aria-describedby="input-reason-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-reason-feedback">Select plant</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="responsible" class="col-md-2 col-form-label">Responsible</label>
				<div class="col-md-10">
					<b-form-input id="responsible" v-model="form.responsible" :state="responsibleState" aria-describedby="input-responsible-feedback" placeholder="responsible" trim></b-form-input>
					<b-form-invalid-feedback id="input-responsible-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>

			<div class="row mt-2">
				<label for="deadline" class="col-md-2 col-form-label">Deadline</label>
				<div class="col-md-10">
					<b-form-datepicker id="deadline" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.deadline" placeholder="deadline" trim></b-form-datepicker>
					<b-form-invalid-feedback id="input-deadline-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="priority" class="col-md-2 col-form-label">Priority</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="priority" v-model="form.priority" :options="priority_options"></b-form-select>
					<b-form-invalid-feedback id="input-priority-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>
                        
			<div class="row mt-2">
				<label for="description" class="col-md-2 col-form-label">Description</label>
				<div class="col-md-10">
					<b-form-input class="mt-2" id="description" v-model="form.taskDescription" :state="descriptionState" aria-describedby="input-description-feedback" placeholder="description" trim></b-form-input>
					<b-form-invalid-feedback id="input-description-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>
            
			<div class="row mt-2">
				<label for="todo" class="col-md-2 col-form-label">To do</label>
				<div class="col-md-10">
					<b-form-input id="todo" v-model="form.toDo" :state="todoState" aria-describedby="input-todo-feedback" placeholder="todo" trim></b-form-input>
					<b-form-invalid-feedback id="input-todo-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
            </div>
			
			<div class="row mt-2">
				<label for="task" class="col-md-2 col-form-label">Task</label>
                <div class="col-md-10">
					<b-form-select class="form-select" size="lg" id="task" v-model="form.task" :options="task_options" :state ="taskState" aria-describedby="input-task-feedback"></b-form-select>
                    <b-form-invalid-feedback id="input-task-feedback">Select task</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="insert" class="col-md-2 col-form-label">Insert date</label>
				<div class="col-md-10">
					<b-form-datepicker id="insert" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="form.insertTs" trim readonly></b-form-datepicker>
				</div>
			</div>

			<div class="row mt-2">
				<label for="progress" class="col-md-2 col-form-label">Progress</label>
				<div class="col-md-10">
					<b-form-input class="mt-2" type="number" min="0" max="100" step="10" id="progress" v-model="form.progress" placeholder="progress in %" :state ="progressState" aria-describedby="input-progress-feedback" trim></b-form-input>
					<b-form-invalid-feedback id="input-progress-feedback">Must be between 0 and 100</b-form-invalid-feedback>
				</div>
            </div>

			<div class="row mt-2">
				<label for="status" class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
					<b-form-select class="mt-2 form-select" size="lg" id="status" v-model="form.status" :options="status_options"></b-form-select>
				</div>
			</div>

			<div class="row mt-2">
				<label for="comments" class="col-md-2 col-form-label">Comments</label>
				<div class="col-md-10">
					<b-form-textarea class="mt-2" id="comments" v-model="form.comments" placeholder="insert your comments" trim></b-form-textarea>
				</div>
            </div>
			
        </form>
    </b-modal>
</template>

<script>

import moment from 'moment';
import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';

export default {
    name: 'ModalNewTask',
	setup() {
		const store = defaultStore()
		return { store}
	},
    data() {
        return {		
            priority_options: [
				{ value: null, text: 'Please select priority level' },
				{ value: 1, text: 'High' },
				{ value: 2, text: 'Medium' },
				{ value: 3, text: 'Low' }
			],
			status_options: [
				{ value: null, text: 'Please select an option' },
				{ value: 1, text: 'Done' },
				{ value: 2, text: 'Progress' },
				{ value: 3, text: 'Pending' }
			],
			task_options: [
				{ value: null, text: 'Please select task' },
				{ value: 1, text: 'Administrative' },
				{ value: 2, text: 'Technical' },
			],
			form: {
				deadline: '',
				priority: null,
				responsible: '',
				status: 3,
				taskDescription: '',
				toDo: '',
				task: null,
				progress: 0,
				insertTs: moment().format('YYYY-MM-DDThh:mm:ss'),
				comments: '',
				plant: { plantId: null }
			},
			show: true,
			plants: [
				{ value: null, text: 'Please select plant' },
			],
        }
    },
	watch: { 
		
	},
    computed: {
		isDisabled: function(){
			return !this.responsibleState || !this.descriptionState || !this.todoState || !this.taskState || !this.plantState;
		},
		deadlineState() {
			return this.form.deadline.length > 1 ? true : false
		},
		priorityState() {
			return this.form.priority === null ? false : true
		},
		responsibleState() {
			return this.form.responsible === '' ? false : true
		},
		descriptionState() {
			return this.form.taskDescription === '' ? false : true
		},
		todoState() {
			return this.form.toDo === '' ? false : true
		},
		taskState() {
			return this.form.task === null ? false : true
		},
		plantState() {
			return this.form.plant.plantId > 0 ? true : false
		},
		progressState() {
			return this.form.progress < 0 && this.form.progress > 100 ? false : true
		},
		
    },
    methods: {
        /* MODAL */
        // Validator function
		isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
            this.form.deadline = '';
			this.form.priority = null;
			this.form.responsible = '';
			this.form.status = 3;
			this.form.taskDescription = '';
			this.form.toDo = '';
			this.form.task = null;
			this.form.plant.plantId = null;
			this.show = false;

			this.$nextTick(() => {
				this.show = true
			});	
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()

			//const today = new Date();
			//const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
			this.form.deadline = this.form.deadline ? this.form.deadline+'T00:00:00': null;
			//this.form.insertTs = this.form.insertTs+'T00:00:00';

			console.log(JSON.stringify(this.form));

			if(this.form.task == 1) {
				console.log('Administrative:');

				TechnicalMgmtService.regAdministrative( JSON.stringify(this.form) ).then((response) => {
					//console.log( response.status );   
					if( response.status == 201 ) {
						//get administrative after register
						TechnicalMgmtService.getAdministrative().then((response) => {
							console.log(response);
							this.$parent.gridApi.setRowData( response.data )
						}).catch((err) => {
							console.log(err);
						});
					}				   
				}).catch((err) => {
					console.log(err);
				});
			} else {
				console.log('Technical');

				TechnicalMgmtService.regTechnical( JSON.stringify(this.form) ).then((response) => {
					//console.log( response.status );   
					if( response.status == 201 ) {
						//get technical after register
						TechnicalMgmtService.getTechnical().then((response) => {
							console.log(response);
							this.$parent.gridApi.setRowData( response.data )
						}).catch((err) => {
							console.log(err);
						});
					}				   
				}).catch((err) => {
					console.log(err);
				});
			}
 
            this.$nextTick(() => {
                this.$bvModal.hide('modal-newTask');
            })
        },
		getModalData() {
			TechnicalMgmtService.getPlants( JSON.stringify(this.form) ).then((response) => {

				let array = this.sortedArray(response.data);
				
				for (const [key, value] of Object.entries(array)) {
					this.plants.push( { value: value.plantId, text: value.name, index: key } );
				} 
            }).catch((err) => {
				console.log(err);
			});
		},
		sortedArray(arrays) {
			function compare(a, b) {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			}
			return arrays.sort(compare);
		}
    },
	created() {
		this.getModalData();
	}
}
</script>