<template>
	<div id="content" class="app-content">
		<h1 class="page-header mb-3">
			Hi, Sean. <small>here's what's happening with your store today.</small>
		</h1>
		<!-- BEGIN row -->
		<b-row>
			<!-- BEGIN col-6 -->
			<b-col xl="6" class="mb-3">
				<!-- BEGIN card -->
				<b-card no-body class="text-white text-opacity-70 h-100 overflow-hidden">
					<!-- BEGIN card-img-overlay -->
					<div class="card-img-overlay d-block d-lg-none bg-blue rounded"></div>
					<!-- END card-img-overlay -->
					
					<!-- BEGIN card-img-overlay -->
					<div class="card-img-overlay d-none d-md-block bg-blue rounded mb-n1 mx-n1" v-bind:style="{ backgroundImage: 'url(/assets/img/bg/wave-bg.png)'}" style="background-position: right bottom; background-repeat: no-repeat; background-size: 100%;"></div>
					<!-- END card-img-overlay -->
					
					<!-- BEGIN card-img-overlay -->
					<div class="card-img-overlay d-none d-md-block bottom-0 top-auto">
						<b-row>
							<b-col md="8" xl="6"></b-col>
							<b-col md="4" xl="6" class="mb-n2">
								<img src="/assets/img/page/dashboard.svg" alt="" class="d-block ms-n3 mb-5" style="max-height: 310px" />
							</b-col>
						</b-row>
					</div>
					<!-- END card-img-overlay -->
					
					<!-- BEGIN card-body -->
					<b-card-body class="position-relative">
						<!-- BEGIN row -->
						<b-row>
							<!-- BEGIN col-8 -->
							<b-col md="8">
								<!-- stat-top -->
								<div class="d-flex">
									<div class="me-auto">
										<h5 class="text-white text-opacity-80 mb-3">{{ $t('general.yes') }}</h5>
										<h3 class="text-white mt-n1 mb-1">$2,999.80</h3>
										<p class="mb-1 text-white text-opacity-60 text-truncate">
											<i class="fa fa-caret-up"></i> <b>32%</b> increase compare to last week
										</p>
									</div>
								</div>
								
								<hr class="bg-white bg-opacity-75 mt-3 mb-3" />
								
								<!-- stat-bottom -->
								<b-row>
									<b-col cols="6" lg="5">
										<div class="mt-1">
											<i class="fa fa-fw fa-shopping-bag fs-28px text-black text-opacity-50"></i>
										</div>
										<div class="mt-1">
											<div>Store Sales</div>
											<div class="fw-600 text-white">$1,629.80</div>
										</div>
									</b-col>
									<b-col cols="6" lg="5">
										<div class="mt-1">
											<i class="fa fa-fw fa-retweet fs-28px text-black text-opacity-50"></i>
										</div>
										<div class="mt-1">
											<div>Referral Sales</div>
											<div class="fw-600 text-white">$700.00</div>
										</div>
									</b-col>
								</b-row>
								
								<hr class="bg-white bg-opacity-75 mt-3 mb-3" />
								
								<div class="mt-3 mb-2">
									<a href="#" class="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px fw-600"><i class="fa fa-wallet me-2 ms-n2"></i> Withdraw money</a>
								</div>
								<p class="fs-12px">
									It usually takes 3-5 business days for transferring the earning to your bank account.
								</p>
							</b-col>
							<!-- END col-8 -->
							
							<!-- BEGIN col-4 -->
							<b-col md="4" class="d-none d-md-block" style="min-height: 380px;"></b-col>
							<!-- END col-4 -->
						</b-row>
						<!-- END row -->
					</b-card-body>
					<!-- END card-body -->
				</b-card>
				<!-- END card -->
			</b-col>
			<!-- END col-6 -->
			
			<!-- BEGIN col-6 -->
			<b-col xl="6">
				<!-- BEGIN row -->
				<div class="row">
					<!-- BEGIN col-6 -->
					<div class="col-sm-6">
						<!-- BEGIN card -->
						<b-card no-body class="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-orange" style="min-height: 199px;">
							<!-- BEGIN card-img-overlay -->
							<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
								<img src="/assets/img/icon/order.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
							</div>
							<!-- END card-img-overlay -->
							
							<!-- BEGIN card-body -->
							<b-card-body class="position-relative">
								<h5 class="text-white text-opacity-80 mb-3 fs-16px">New Orders</h5>
								<h3 class="text-white mt-n1">56</h3>
								<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
									<div class="progrss-bar progress-bar-striped bg-white" style="width: 80%"></div>
								</div>
								<div class="text-white text-opacity-80 mb-4"><i class="fa fa-caret-up"></i> 16% increase <br />compare to last week</div>
								<div><a href="#" class="text-white d-flex align-items-center text-decoration-none">View report <i class="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
							</b-card-body>
							<!-- BEGIN card-body -->
						</b-card>
						<!-- END card -->
						
						<!-- BEGIN card -->
						<b-card no-body class="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-teal" style="min-height: 199px;">
							<!-- BEGIN card-img-overlay -->
							<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
								<img src="/assets/img/icon/visitor.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
							</div>
							<!-- END card-img-overlay -->
							
							<!-- BEGIN card-body -->
							<b-card-body class="position-relative">
								<h5 class="text-white text-opacity-80 mb-3 fs-16px">Page Visitors</h5>
								<h3 class="text-white mt-n1">60.5k</h3>
								<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
									<div class="progrss-bar progress-bar-striped bg-white" style="width: 50%"></div>
								</div>
								<div class="text-white text-opacity-80 mb-4"><i class="fa fa-caret-up"></i> 33% increase <br />compare to last week</div>
								<div><a href="#" class="text-white d-flex align-items-center text-decoration-none">View report <i class="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
							</b-card-body>
							<!-- END card-body -->
						</b-card>
						<!-- END card -->
					</div>
					<!-- END col-6 -->
					
					<!-- BEGIN col-6 -->
					<div class="col-sm-6">
						<!-- BEGIN card -->
						<b-card no-body class="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-pink" style="min-height: 199px;">
							<!-- BEGIN card-img-overlay -->
							<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
								<img src="/assets/img/icon/email.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
							</div>
							<!-- END card-img-overlay -->
							
							<!-- BEGIN card-body -->
							<b-card-body class="position-relative">
								<h5 class="text-white text-opacity-80 mb-3 fs-16px">Unread email</h5>
								<h3 class="text-white mt-n1">30</h3>
								<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
									<div class="progrss-bar progress-bar-striped bg-white" style="width: 80%"></div>
								</div>
								<div class="text-white text-opacity-80 mb-4"><i class="fa fa-caret-down"></i> 5% decrease <br />compare to last week</div>
								<div><a href="#" class="text-white d-flex align-items-center text-decoration-none">View report <i class="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
							</b-card-body>
							<!-- END card-body -->
						</b-card>
						<!-- END card -->
						
						<!-- BEGIN card -->
						<b-card no-body class="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-indigo" style="min-height: 199px;">
							<!-- BEGIN card-img-overlay -->
							<div class="card-img-overlay mb-n4 me-n4 d-flex" style="bottom: 0; top: auto;">
								<img src="/assets/img/icon/browser.svg" alt="" class="ms-auto d-block mb-n3" style="max-height: 105px" />
							</div>
							<!-- end card-img-overlay -->
							
							<!-- BEGIN card-body -->
							<b-card-body class="position-relative">
								<h5 class="text-white text-opacity-80 mb-3 fs-16px">Page Views</h5>
								<h3 class="text-white mt-n1">320.4k</h3>
								<div class="progress bg-black bg-opacity-50 mb-2" style="height: 6px">
									<div class="progrss-bar progress-bar-striped bg-white" style="width: 80%"></div>
								</div>
								<div class="text-white text-opacity-80 mb-4"><i class="fa fa-caret-up"></i> 20% increase <br />compare to last week</div>
								<div><a href="#" class="text-white d-flex align-items-center text-decoration-none">View report <i class="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
							</b-card-body>
							<!-- END card-body -->
						</b-card>
						<!-- END card -->
					</div>
					<!-- BEGIN col-6 -->
				</div>
				<!-- END row -->
			</b-col>
			<!-- END col-6 -->
		</b-row>
		<!-- END row -->
		
		<!-- BEGIN row -->
		<b-row>
			<!-- BEGIN col-6 -->
			<b-col xl="6">
				<!-- BEGIN row -->
				<b-row>
					<!-- BEGIN col-6 -->
					<b-col cols="6" class="mb-3 d-flex flex-column">
						<!-- BEGIN card -->
						<b-card class="mb-3 flex-1">
							<div class="d-flex mb-3">
								<div class="flex-grow-1">
									<h5 class="mb-1">Total Users</h5>
									<div>Store user account registration</div>
								</div>
								<a href="#" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
							</div>
							
							<div class="d-flex">
								<div class="flex-grow-1">
									<h3 class="mb-1">184,593</h3>
									<div class="text-success fw-600 fs-13px">
										<i class="fa fa-caret-up"></i> +3.59%
									</div>
								</div>
								<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
									<i class="fa fa-user fa-lg text-primary"></i>
								</div>
							</div>
						</b-card>
						<!-- END card -->
						
						<!-- BEGIN card -->
						<b-card>
							<div class="d-flex mb-3">
								<div class="flex-grow-1">
									<h5 class="mb-1">Social Media</h5>
									<div>Facebook page stats overview</div>
								</div>
								<a href="#" data-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
							</div>
							
							<!-- BEGIN row -->
							<b-row>
								<!-- BEGIN col-6 -->
								<b-col cols="6" class="text-center">
									<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center mb-2 ms-auto me-auto">
										<i class="fa fa-thumbs-up fa-lg text-primary"></i>
									</div>
									<div class="fw-600 text-dark">306.5k</div>
									<div class="fs-13px">Likes</div>
								</b-col>
								<!-- END col-6 -->
								
								<!-- BEGIN col-6 -->
								<b-col cols="6" class="text-center">
									<div class="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center mb-2 ms-auto me-auto">
										<i class="fa fa-comments fa-lg text-primary"></i>
									</div>
									<div class="fw-600 text-dark">27.5k</div>
									<div class="fs-13px">Comments</div>
								</b-col>
								<!-- END col-6 -->
							</b-row>
							<!-- END row -->
						</b-card>
						<!-- END card -->
					</b-col>
					<!-- END col-6 -->
					
					<!-- BEGIN col-6 -->
					<b-col cols="6" class="mb-3">
						<!-- BEGIN card -->
						<b-card class="h-100">	
							<div class="d-flex mb-3">
								<div class="flex-grow-1">
									<h5 class="mb-1">Web Traffic</h5>
									<div class="fs-13px">Traffic source and category</div>
								</div>
								<a href="#" data-bs-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
							</div>
							
							<div class="mb-4">
								<h3 class="mb-1">320,958</h3>
								<div class="text-success fs-13px fw-600">
									<i class="fa fa-caret-up"></i> +20.9%
								</div>
							</div>
							
							<div class="progress mb-4" style="height: 10px;">
								<div class="progress-bar" style="width: 42.66%"></div>
								<div class="progress-bar bg-teal" style="width: 36.80%"></div>
								<div class="progress-bar bg-yellow" style="width: 15.34%"></div>
								<div class="progress-bar bg-pink" style="width: 9.20%"></div>
								<div class="progress-bar bg-gray-200" style="width: 5.00%"></div>
							</div>
							
							<div class="fs-13px">
								<div class="d-flex align-items-center mb-2">
									<div class="flex-grow-1 d-flex align-items-center">
										<i class="fa fa-circle fs-9px fa-fw text-primary me-2"></i> Direct visit
									</div>
									<div class="fw-600 text-dark">42.66%</div>
								</div>
								<div class="d-flex align-items-center mb-2">
									<div class="flex-grow-1 d-flex align-items-center">
										<i class="fa fa-circle fs-9px fa-fw text-teal me-2"></i> Organic Search
									</div>
									<div class="fw-600 text-dark">36.80%</div>
								</div>
								<div class="d-flex align-items-center mb-2">
									<div class="flex-grow-1 d-flex align-items-center">
										<i class="fa fa-circle fs-9px fa-fw text-warning me-2"></i> Referral Website
									</div>
									<div class="fw-600 text-dark">15.34%</div>
								</div>
								<div class="d-flex align-items-center mb-2">
									<div class="flex-grow-1 d-flex align-items-center">
										<i class="fa fa-circle fs-9px fa-fw text-danger me-2"></i> Social Networks
									</div>
									<div class="fw-600 text-dark">9.20%</div>
								</div>
								<div class="d-flex align-items-center mb-15px">
									<div class="flex-grow-1 d-flex align-items-center">
										<i class="fa fa-circle fs-9px fa-fw text-gray-200 me-2"></i> Others
									</div>
									<div class="fw-600 text-dark">5.00%</div>
								</div>
								<div class="fs-12px text-end">
									<span class="fs-10px">powered by </span>
									<span class="d-inline-flex fw-600">
										<span class="text-primary">G</span>
										<span class="text-danger">o</span>
										<span class="text-warning">o</span>
										<span class="text-primary">g</span>
										<span class="text-green">l</span>
										<span class="text-danger">e</span>
									</span>
									<span class="fs-10px">Analytics API</span>
								</div>
							</div>
						</b-card>
						<!-- END card -->
					</b-col>
					<!-- END col-6 -->
				</b-row>
				<!-- END row -->
			</b-col>
			<!-- END col-6 -->
			
			<!-- BEGIN col-6 -->
			<b-col xl="6" class="mb-3">
				<!-- BEGIN card -->
				<b-card class="h-100">
					<div class="d-flex mb-3">
						<div class="flex-grow-1">
							<h5 class="mb-1">Sales Analytics</h5>
							<div class="fs-13px">Weekly sales performance chart</div>
						</div>
						<a href="#" data-toggle="dropdown" class="text-muted"><i class="fa fa-redo"></i></a>
					</div>
					<apexchart height="255px" :options="chartData.options" :series="chartData.series"></apexchart>
				</b-card>
				<!-- END card -->
			</b-col>	
			<!-- END col-6 -->
		</b-row>
		<!-- END row -->
		
		<!-- BEGIN row -->
		<b-row>
			<!-- BEGIN col-6 -->
			<b-col xl="6" class="mb-3">
				<!-- BEGIN card -->
				<b-card class="h-100">
					<div class="d-flex align-items-center mb-4">
						<div class="flex-grow-1">
							<h5 class="mb-1">Bestseller</h5>
							<div class="fs-13px">Top 3 product sales this week</div>
						</div>
						<a href="#">See All</a>
					</div>
					
					<!-- product -->
					<div class="d-flex align-items-center" v-bind:class="{ 'mb-3': index < 4 }" v-for="(product, index) in bestSellerProduct" v-bind:key="index">
						<div class="d-flex align-items-center justify-content-center me-3 w-50px h-50px">
							<img v-bind:src="product.image" alt="" class="mw-100 mh-100" />
						</div>
						<div class="flex-grow-1">
							<div>
								<div class="text-primary fs-10px fw-600" v-if="(index == 0)">TOP SALES</div>
								<div class="text-dark fw-600">{{ product.name }}</div>
								<div class="fs-13px">${{ product.price }}</div>
							</div>
						</div>
						<div class="ps-3 text-center">
							<div class="text-dark fw-600">{{ product.sales }}</div>
							<div class="fs-13px">sales</div>
						</div>
					</div>
				</b-card>
				<!-- END card -->
			</b-col>
			<!-- END col-6 -->
			
			<!-- BEGIN col-6 -->
			<b-col xl="6" class="mb-3">
				<!-- BEGIN card -->
				<b-card class="h-100">
					<div class="d-flex align-items-center mb-2">
						<div class="flex-grow-1">
							<h5 class="mb-1">Transaction</h5>
							<div class="fs-13px">Latest transaction history</div>
						</div>
						<a href="#">See All</a>
					</div>
					
					<!-- BEGIN table-responsive -->
					<div class="table-responsive mb-n2">
						<table class="table table-borderless mb-0">
							<thead>
								<tr class="text-dark">
									<th class="ps-0">No</th>
									<th>Order Details</th>
									<th class="text-center">Status</th>
									<th class="text-end pe-0">Amount</th>
								</tr>
							</thead>
							<tbody v-if="transactionData">
								<tr v-for="(transaction, index) in transactionData" v-bind:key="index">
									<td class="ps-0">{{ index + 1 }}.</td>
									<td>
										<div class="d-flex align-items-center">
											<div class="w-40px h-40px">
												<img v-bind:src="transaction.icon" alt="" class="mw-100 mh-100" />
											</div>
											<div class="ms-3 flex-grow-1">
												<div class="fw-600 text-dark">{{ transaction.name }}</div>
												<div class="fs-13px">{{ transaction.time }}</div>
											</div>
										</div>
									</td>
									<td class="text-center"><span v-bind:class="transaction.labelClass" style="min-width: 60px;">{{ transaction.label }}</span></td>
									<td class="text-end pe-0">${{ transaction.price }}</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="4">No records found</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- END table-responsive -->
				</b-card>
				<!-- END card -->
			</b-col>
			<!-- END col-6 -->
		</b-row>
		<!-- END row -->
	</div>
</template>

<script>
export default {
  name: 'dashboard',
  data() {
		return {
			bestSellerProduct: [{
				id: 1,
				name: 'iPhone 11 Pro Max (256GB)',
				price: '1,099',
				sales: 382,
				image: '/assets/img/product/product-1.jpg'
			},{
				id: 2,
				name: 'Macbook Pro 13 inch (2020)',
				price: '1,120',
				sales: 102,
				image: '/assets/img/product/product-2.jpg'
			},{
				id: 3,
				name: 'Apple Watch Series 4(2020)',
				price: '349',
				sales: 75,
				image: '/assets/img/product/product-3.jpg'
			},{
				id: 4,
				name: '12.9-inch iPad Pro (256GB)',
				price: '1,099',
				sales: 62,
				image: '/assets/img/product/product-4.jpg'
			},{
				id: 5,
				name: 'iPhone 11 (128gb)',
				price: '799',
				sales: 59,
				image: '/assets/img/product/product-5.jpg'
			}],
			transactionData: [{
				id: 1,
				name: 'Macbook Pro 15 inch',
				time: '5 minutes ago',
				price: '1,699.00',
				icon: '/assets/img/icon/paypal2.svg',
				label: 'Success',
				labelClass: 'badge bg-success bg-opacity-20 text-success'
			},{
				id: 2,
				name: 'Apple Watch 5 Series',
				time: '5 minutes ago',
				price: '699.00',
				icon: '/assets/img/icon/mastercard.svg',
				label: 'Success',
				labelClass: 'badge bg-success bg-opacity-20 text-success'
			},{
				id: 3,
				name: 'iPhone 11 Pro Max',
				time: '12 minutes ago',
				price: '1,299.00',
				icon: '/assets/img/icon/visa.svg',
				label: 'Pending',
				labelClass: 'badge bg-warning bg-opacity-20 text-warning'
			},{
				id: 4,
				name: 'Apple Magic Keyboard',
				time: '15 minutes ago',
				price: '199.00',
				icon: '/assets/img/icon/paypal2.svg',
				label: 'Cancelled',
				labelClass: 'badge text-dark text-opacity-50 bg-dark bg-opacity-10'
			},{
				id: 5,
				name: 'iPad Pro 12.9 inch',
				time: '15 minutes ago',
				price: '1,099.00',
				icon: '/assets/img/icon/mastercard.svg',
				label: 'Cancelled',
				labelClass: 'badge bg-success bg-opacity-20 text-success'
			}],
			chartData: {
				series: [{
					data: [
						8107, 8128, 8122, 8165, 8340, 8423, 8423, 8514, 8481, 8487, 
						8506, 8626, 8668, 8602, 8607, 8512, 8496, 8600, 8881, 9340
					]
				}],
				options: {
					labels: [
						'13 Nov 2020', '14 Nov 2020', '15 Nov 2020', '16 Nov 2020',
						'17 Nov 2020', '20 Nov 2020', '21 Nov 2020', '22 Nov 2020',
						'23 Nov 2020', '24 Nov 2020', '27 Nov 2020', '28 Nov 2020',
						'29 Nov 2020', '30 Nov 2020', '01 Dec 2020', '04 Dec 2020', 
						'05 Dec 2020', '06 Dec 2020', '07 Dec 2020', '08 Dec 2020'
					],
					colors: ['#1f6bff'],
					chart: {
						height: 256,
						type: 'line',
						toolbar: {
							show: false
						}
					},
					annotations: {
						yaxis: [{
							y: 8200,
							borderColor: '#640DF3',
							label: {
								borderColor: '#640DF3',
								style: {
									color: '#ffffff',
									background: '#640DF3',
								},
								text: 'Support',
							}
						}, {
							y: 8600,
							y2: 9000,
							borderColor: '#FF9500',
							fillColor: '#FF9500',
							opacity: 0.1,
							label: {
								borderColor: '#FFCC00',
								style: {
									fontSize: '10px',
									color: '#212837',
									background: '#FFCC00',
								},
								text: 'Earning',
							}
						}],
						xaxis: [{
							x: new Date('23 Nov 2020').getTime(),
							strokeDashArray: 0,
							borderColor: '#212837',
							label: {
								borderColor: '#212837',
								style: {
									color: '#ffffff',
									background: '#212837',
								},
								text: 'Anno Test',
							}
						}, {
							x: new Date('26 Nov 2020').getTime(),
							x2: new Date('28 Nov 2020').getTime(),
							fillColor: '#0CD095',
							opacity: 0.4,
							label: {
								borderColor: '#0CD095',
								style: {
									fontSize: '10px',
									color: '#fff',
									background: '#0CD095',
								},
								offsetY: -7,
								text: 'X-axis range',
							}
						}],
						points: [{
							x: new Date('01 Dec 2020').getTime(),
							y: 8607.55,
							marker: {
								size: 8,
								fillColor: '#ffffff',
								strokeColor: '#FF2D55',
								radius: 2
							},
							label: {
								borderColor: '#FF2D55',
								offsetY: 0,
								style: {
									color: '#ffffff',
									background: '#FF2D55',
								},

								text: 'Point Annotation',
							}
						}]
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'straight'
					},
					grid: {
						padding: {
							right: 30,
							left: 0
						},
						borderColor: 'rgba('+ window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb') + ', .15)'
					},
					xaxis: {
						type: 'datetime',
					},
				}
			}
		}
  }
}
</script>