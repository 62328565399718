import axios from 'axios';

/** @typedef {{sapr: String, year: Number, month: Number}} InvoiceIdentifier */

function isLocal() {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
}

const HOST = isLocal() ? 'localhost:3000' : 'edm.psaierenergies.it';
const PROTOCOL = isLocal() ? 'http' : 'https';

const API_V2_BASE_URL = `${PROTOCOL}://${HOST}/api/v2`;

class CommercialMgmtService {

    invoicesCount({year, month, fund, producer, sapr}) {
        return axios.get(API_V2_BASE_URL + '/invoices/count', {
            params: {
                year: year,
                month: month,
                fund: fund,
                producer: producer,
                sapr: sapr,
            }
        });
    }

    /**
     * 
     * @param {InvoiceIdentifier[]} invoicesIdentifiers 
     */
    invoicesPublish(invoicesIdentifiers) {
        return axios.post(API_V2_BASE_URL + '/invoices/publish', invoicesIdentifiers);
    }

    invoicesCreate({year, month, sapr}) {
        return axios.post(API_V2_BASE_URL + '/invoices', {
            year: year,
            month: month,
            sapr: sapr,
        });
    }

    async invoicesDownload(invoiceIdentifiers, format) {
        const response = await axios.post(API_V2_BASE_URL + `/invoices/download/${format}`, invoiceIdentifiers);
        const {fileName, base64FileContent} = response.data;
        download_file(fileName, base64FileContent);
        return {
            fileName: fileName,
            base64FileContent: base64FileContent,
        };
    }

	async invoicesUpdateNumber({year, month, sapr, number}) {
		return axios.patch(API_V2_BASE_URL + '/invoices/number', {
			year: year,
			month: month,
			sapr: sapr,
			number: number,
		});
	}

	async plants() {
		const response = await axios.post(API_V2_BASE_URL + '/silver-plants/query');
		return response.data;
	}

}

// copied from old production portal
// eslint-disable-next-line no-unused-vars
function download_file(file_name, file_content) {
	if (window.Blob && window.navigator.msSaveOrOpenBlob) {
		if (file_name.substring(file_name.length - 3) == "pdf" || file_name.substring(file_name.length - 3) == "zip" || file_name.substring(file_name.length - 4) == "xlsx") {
			var bc = atob(file_content);
			var bn = new Array(bc.length);
			for (var i = 0; i < bc.length; i++) {
				bn[i] = bc.charCodeAt(i);
			}
			var ba = new Uint8Array(bn);
			var blob;
			if (file_name.substring(file_name.length - 3) == "pdf") {
				blob = new Blob([ba], {type: "application/pdf"});
			}
			else if (file_name.substring(file_name.length - 4) == "xlsx") {
				blob = new Blob([ba], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
			}
			else {
				blob = new Blob([ba], {type: "application/zip"});
			}
			window.navigator.msSaveOrOpenBlob(blob, file_name);
		}
		else {
			// eslint-disable-next-line no-redeclare
			var blob = new Blob([file_content]);
			window.navigator.msSaveOrOpenBlob(blob, file_name);
		}
	} else {
		var mime = "data:text/plain;charset=utf-8";
		if (file_name.substring(file_name.length - 4) == "xlsx") mime = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64";
		if (file_name.substring(file_name.length - 3) == "zip") mime = "data:application/zip;base64";
		if (file_name.substring(file_name.length - 3) == "pdf") mime = "data:application/pdf;base64";
		var a = document.createElement('a');
		a.href = mime + "," + encodeURIComponent(file_content);
		a.target = "_blank";
		a.download = file_name;
		a.style.display = "none";
		document.body.appendChild(a);
		a.click();
		a.remove();
	}
}

export default new CommercialMgmtService();