<template>
<div id="content" class="app-content">
    <b-container>
        <b-row class="justify-content-center">
            <b-col xl="12">
                <h1 class="page-header">
                    {{ $t('general.users') }}
                </h1>
                <hr class="mb-4" />
            </b-col>
            <b-col xl="12">
            
                <b-card>
                    <b-card-text class="card-text mb-3"></b-card-text>

                    <b-list-group class="border-0" flush>
                        <b-form-group
                            :label="$t('general.role')"
                        >
                            <b-form-select
                                v-model="formData.role_id"
                                :options="roles"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group
                            :label="$t('general.Name')"
                            >
                            <b-form-input
                                v-model="formData.name"
                                :placeholder="$t('general.Name')"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            :label="$t('contact.Email')"
                            >
                            <b-form-input
                                v-model="formData.email"
                                :placeholder="$t('contact.Email')"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            :label="$t('general.password')"
                        >
                            <b-form-input
                                v-model="formData.password"
                                :placeholder="$t('general.password')"
                                type="password"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Authorization columns values"
                        >
                            <b-form-input
                                v-model="formData.authorization_columns_values"
                                placeholder="Authorization columns values"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            :label="$t('general.is_active')"
                        >
                            <b-form-checkbox
                                v-model="formData.is_active"
                            ></b-form-checkbox>
                        </b-form-group>
                    </b-list-group>
                    <div id="grid-form-buttons" class="mt-3">
                        <b-button
                            variant="primary"
                            @click="submit"
                            :disabled="isSaving"
                        >
                            {{ $t('form.submit') }}
                        </b-button>
                        <button
                            @click="$router.push('/grids/users')"
                            class="btn btn-secondary"
                            type="button">
                            {{ $t('form.cancel') }}
                        </button>
                    </div>
                </b-card>
            </b-col>

        </b-row>

    </b-container>
</div>
</template>
<script>

import axios from 'axios';

import { getAPIObjectURL } from '../_services/TechnicalMgmtService';

export default {	
    name: 'UsersForm',
    props: {
        id: Number,
    },
    data() {
        return {
            isSaving: true,
            formData: {
                name: '',
                email: '',
                password: '',
                authorization_columns_values: '{"SPV_id":[1,2...],"plant_id":[],"SAPR":["example1", "example2"],"fund_id":[]}',
                is_active: true,
            },
            locale: localStorage.getItem("customLocale"),
            roles: [],
        };
    },
    async created() {
        const [rolesResponse, usersResponse] = await Promise.all([
            axios.post(getAPIObjectURL('roles/query')),
            this.id && axios.get(getAPIObjectURL('users', this.id))
        ]);
        this.roles = rolesResponse.data.map(role => ({ value: role.id, text: role.name }));
        this.formData.role_id = this.roles[this.roles.length-1].value;
        if (!Number.isNaN(usersResponse)) {
            this.formData = usersResponse.data.data;
        }
        this.isSaving = false;
    },
    methods: {
        async submit() {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            try {
                const data = {
                    ...this.formData,
                };
                if (this.id) {
                    data.id = this.id;
                }
                if (data.password === '') {
                    delete data.password;
                }
                if (data.authorization_columns_values === '') {
                    delete data.authorization_columns_values;
                }
                const role = this.roles.find(role => role.value === data.role_id);
                delete data.role_id;
                data.role_name = role.text;
                const response = await axios.post(getAPIObjectURL('users'), data);
                this.$alert(this.$t('form.success'));
                if (!this.id) {
                    this.$router.push('/users/form/' + response.data.id);
                }
            }
            catch (e) {
                this.$alert(e?.response?.data?.message, this.$t('form.error'), 'error');
                console.error('error while submitting form', e);
            }
            this.isSaving = false;
        },
    },
}
</script>
<style scoped>
#grid-form-buttons {
    margin-right: 0.5em;
}
</style>