<script>
import { JsonForms } from '@jsonforms/vue2';
import moment from 'moment';
import {
  defaultStyles,
  mergeStyles,
  vanillaRenderers,
} from '@jsonforms/vue2-vanilla';
import { getAPIObjectURL } from '../_services/TechnicalMgmtService.js';
import axios from 'axios';
import { useFormStates } from '../_store/form.js';
import { storeToRefs } from 'pinia';

export const SCHEMA_PROPERTIES_TO_HIDE = [
  'id',
  'uid',
  'updated_at',
  'created_at',
];

const renderers = [
  ...vanillaRenderers,
  // here you can add custom renderers, don't even think about it: docu < zero
];

// mergeStyles combines all classes from both styles definitions
const myStyles = mergeStyles(defaultStyles, {
  control: {
    input: 'form-control',
    label: 'form-label',
  },
});

export default {
  name: 'Form',
  components: {
    JsonForms,
  },
  props: {
    apiObjectId: Number,
    apiObjectName: String,
    uischema: Object,
    readonly: Boolean,
    isEdit: Boolean,
  },
  data() {
    return {
      renderers: Object.freeze(renderers),
      record: null,
      schema: undefined,
      isReady: false,
      isView: false,
      userRights: [],
      foreignKeyInformation: [],
      isValidationError: false,
      validationErrors: [],
    };
  },
  provide() {
    return {
      styles: myStyles,
    };
  },
  methods: {
    fixCheckBoxStyle() {
      this.$nextTick(() => {
        for (const el of this.$el.getElementsByTagName('input')) {
          if (el.type === 'checkbox') {
            el.classList.remove('form-control');
          }
        }
      });
    },
    userHasRight(right) {
      return this.userRights.includes(right);
    },
    async loadRecordMetadata() {
      const [response, rightsResponse] = await Promise.all([
        axios.get(`${getAPIObjectURL(this.apiObjectName)}/schema`),
        axios.get(`${getAPIObjectURL(this.apiObjectName)}/current-user-rights`),
      ]);
      this.userRights = rightsResponse.data;
      return response.data;
    },
    removeHiddenProperties(data, isRemoveForeignKey = false) {
      for (const propertyName of SCHEMA_PROPERTIES_TO_HIDE) {
        delete data[propertyName];
      }
      if (isRemoveForeignKey && this.foreignKeyInformation) {
        for (const { columnName } of this.foreignKeyInformation) {
          delete data[columnName];
        }
      }
      return data;
    },
    async loadRecord() {
      if (!this.apiObjectId) {
        return {
          is_view: false,
          data: {},
        };
      }
      const data = await this.requestRecord(this.apiObjectName, this.apiObjectId);
      return data;
    },
    async prepareRecordForVisualization(data, {foreignKeys, JSONSchema}) {
      data = await this.loadForeignKeyValues(data, foreignKeys);
      this.removeHiddenProperties(JSONSchema.properties, true);
      this.schema = JSONSchema;
      data = this.removeHiddenProperties(data);
      data = this.replaceNullValuesIfView(data);
      data = this.parseDateToLocale(data, JSONSchema);
      return data;
    },
    async requestRecord(apiObjectName, apiObjectId) {
      const response = await axios.get(getAPIObjectURL(apiObjectName, apiObjectId));
      return response.data;
    },
    async loadForeignKeyValues(data, foreignKeyInformation) {
      const foreignKeyRequests = [];
      console.log('form: foreign key information', foreignKeyInformation);
      for (const {column_name: columnName, references_column: referencesColumn, references_table: referencesTable} of foreignKeyInformation) {
        const objectName = referencesTable.replace('_v2', '');
        console.log('form: foreign key', columnName, objectName, referencesColumn, referencesTable);
        const foreignID = data[columnName];
        const foreignKeyInformationLength = this.foreignKeyInformation.push({
          columnName,
          objectName,
          referencesColumn,
          referencesTable,
          value: foreignID,
        });
        if (!foreignID) {
          continue;
        }
        const foreignKeyRequest = this.requestRecord(objectName, foreignID)
          .then((response) => {
            this.foreignKeyInformation[foreignKeyInformationLength-1].data = response.data;
          });
        foreignKeyRequests.push(foreignKeyRequest);
      }
      await Promise.all(foreignKeyRequests);
      return data;
    },
    parseDateToLocale(data) {
      for (const [popertyName, schemaDefinition] of Object.entries(this.schema.properties)) {
        if (schemaDefinition?.format === 'date-time') {
          data[popertyName] = moment(data[popertyName])
            .local()
            .format('YYYY-MM-DDTHH:mm:ss');
        }
        else if (schemaDefinition?.format === 'date') {
          data[popertyName] = moment(data[popertyName])
            .format('YYYY-MM-DD');
        }
      }
      return data;
    },
    async saveRecord() {
      let formData = this.getForm(this.apiObjectName, this.apiObjectId);
      if (!formData) {
        console.error('Form data not found');
        return;
      }
      this.isReady = false;
      let body = {
        ...formData.data,
      };
      // body = this.parseDateToLocale(body);
      if (this.isValidationError) {
        return;
      }
      const id = this.apiObjectId;
      let response;
      try {
        if (id) {
          response = await axios.patch(getAPIObjectURL(this.apiObjectName, id), body);
        }
        else {
          response = await axios.post(getAPIObjectURL(this.apiObjectName), body);
        }
        this.$alert(this.$t('form.success'));
        if (!id) {
          this.$router.replace({path: `/forms/${this.apiObjectName}/${response.data.id}`});
        }
        this.record = body;
      }
      catch (e) {
        console.error('form: error while saving form', e);
        this.$alert(this.$t('form.error'), null, 'error');
      }
      this.isReady = true;
    },
    async deleteRecord() {
      if (!this.apiObjectId) {
        console.error('form: no id to delete');
        return;
      }
      if (!confirm(this.$t('form.confirmDelete'))) {
        return;
      }
      this.isReady = false;
      try {
        await axios.delete(getAPIObjectURL(this.apiObjectName, this.apiObjectId))
        this.$alert(this.$t('form.success'));
        this.$router.back();
      }
      catch(e) {
        console.error('form: error while deleting form', e);
        this.$alert(this.$t('form.error'), null, 'error');
      }
      this.isReady = true;
    },
    // jsonforms throws if no schema is provided and a value is null
    replaceNullValuesIfView(data) {
      if (!this.isView) {
        return data;
      }
      for (const [key, value] of Object.entries(data)) {
        if (value === null) {
          data[key] = '';
        }
      }
      return data;
    },
    onChange(event) {
      let data = {...event.data};
      for (const foreignKeyInformation of this.foreignKeyInformation) {
        data[foreignKeyInformation.columnName] = foreignKeyInformation.value;
      }
      this.formStates.setForm(this.apiObjectName, this.apiObjectId, data);
      this.isValidationError = event.errors.length > 0;
      this.validationErrors = event.errors;
    },
    getForeignKeyValue(foreignKey) {
      const data = foreignKey.data;
      const cleanedData = this.removeHiddenProperties({...data});
      let values = [];
      for (const value of Object.values(cleanedData)) {
        if (typeof value === 'string') {
          values.push(value);
        }
      }
      return values.join(', ');
    },
    editForeignKey(foreignKey) {
      this.edit();
      this.$router.push({path: `/grids/${foreignKey.objectName}`, query : {isSelect: true, selectEntityName: this.apiObjectName, selectEntityId: this.apiObjectId, selectEntityColumn: foreignKey.columnName}});
    },
    edit() {
      if (this.isEdit) {
        return;
      }
      this.isView = false;
      if (this.$route.query.isEdit) {
        return;
      }
      this.$router.replace({ ...this.$route.currentRoute, query: { ...this.$route.query, isEdit: true }});
    },
  },
  updated: function () {
    this.fixCheckBoxStyle();
  },
  mounted: function () {
    this.fixCheckBoxStyle();
  },
  created: async function () {
    let dataInEdit = null;
    if (this.isEdit) {
      const form = this.getForm(this.apiObjectName, this.apiObjectId);
      if (form) {
        dataInEdit = {
          is_view: false,
          data: form.data,
        };
      }
    }
    const [data, schema] = await Promise.all([dataInEdit || this.loadRecord(), this.loadRecordMetadata()]);
    if (data.is_view && !this.isEdit) {
      this.isView = true;
    }
    this.record = await this.prepareRecordForVisualization(data.data, schema);
    this.isReady = true;
  },
  setup() {
    const formStates = useFormStates();
    const { getForm } = storeToRefs(formStates);
    return { getForm, formStates };
  }
};
</script>
<template>
  <div>
    <div v-if="!isReady" class="text-center">
      <i class="fa-solid fa-spinner fa-spin-pulse fa-2xl"></i>
    </div>
    <div
      v-else
    >
      <json-forms
      :data="record"
      :schema="isView ? null : schema"
      :uischema="uischema"
      :renderers="renderers"
      @change="onChange"
      :readonly="isView || readonly || !userRights || !userHasRight('update') && !userHasRight('create')"
      />
      <div class="form-group" v-for="foreignKey in foreignKeyInformation" v-bind:key="foreignKey.objectName">
        <label class="form-label">{{ foreignKey.objectName[0].toUpperCase() + foreignKey.objectName.substring(1, foreignKey.objectName.length - 1).replace(/_/g, ' ') }}</label>
        <div class="input-group">
          <input class="form-control" :value="getForeignKeyValue(foreignKey)" disabled />
          <button v-if="!isView && (userHasRight('create') || userHasRight('update'))" class="btn btn-primary" @click="editForeignKey(foreignKey)">{{ $t('form.edit') }}</button>
        </div>
      </div>
      <div id="grid-form-buttons" class="mt-3">
        <button
          v-if="isView"
          class="ml-2 btn btn-primary"
          type="button"
          @click="edit"
          >
            {{ $t('form.edit') }}
        </button>
        <button
          v-if="!isView && (userHasRight('create') || userHasRight('update'))"
          @click="saveRecord"
          class="ml-2 btn btn-primary"
          type="button"
          :disabled="this.isValidationError"
          >
            {{ $t('form.submit') }}
        </button>
        <button
          v-if="!isView && apiObjectId && (userHasRight('delete'))"
          @click="deleteRecord"
          class="ml-2 btn btn-danger"
          type="button"
        >
          {{ $t('form.delete') }}
        </button>
        <button
          @click="$router.back()"
          class="btn btn-secondary"
          type="button">
          {{ $t('form.cancel') }}
        </button>
      </div>
      <div class="mt-4">
        <div v-for="validationError in validationErrors" class="alert alert-warning" role="alert" v-bind:key="validationError.message">
          {{ validationError.message }}
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#grid-form-buttons button {
  margin-right: 0.5em;
}
</style>