<template>
    <b-modal id="modal-productionStatsQuery" centered ref="modal" title="Load production stats" ok-title="SEARCH" ok-variant="success"
        cancel-variant="danger" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk">

		<form ref="form" @submit.stop.prevent="handleSubmit">
			
			<div class="row mt-2">
				<label for="ceId" class="col-md-2 col-form-label">Ce ID</label>
                <div class="col-md-10">
					<b-form-input id="ceId" type="text" value="" v-model="form.meter_id" :state="ceIdState" aria-describedby="input-ceId-feedback" placeholder="ce id" trim></b-form-input>
                    <b-form-invalid-feedback id="input-ceId-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="tsStart" class="col-md-2 col-form-label">From</label>
                <div class="col-md-10">
					<b-form-input id="tsStart" type="month" min="2018" value="" v-model="form.start" :state="tsStartState" aria-describedby="input-tsStart-feedback" placeholder="start" trim></b-form-input>
                    <b-form-invalid-feedback id="input-tsStart-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>

			<div class="row mt-2">
				<label for="tsEnd" class="col-md-2 col-form-label">To</label>
                <div class="col-md-10">
					<b-form-input id="tsEnd" type="month" min="2018" value="" v-model="form.end" :state="tsEndState" aria-describedby="input-tsEnd-feedback" placeholder="end" trim></b-form-input>
					<b-form-invalid-feedback id="input-tsEnd-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>	
			
			<div class="row mt-2">
				<label for="version" class="col-md-2 col-form-label">Version</label>
                <div class="col-md-10">
					<b-form-input id="version" type="text" value="" v-model="form.version" :state="versionState" aria-describedby="input-version-feedback" placeholder="version" trim></b-form-input>
					<b-form-invalid-feedback id="input-version-feedback">Field cannot be empty</b-form-invalid-feedback>
				</div>
			</div>	

        </form>

    </b-modal>
</template>

<script>

import CommercialMgmtService from '../_services/CommercialMgmtService';
//import MonthlyInvoices from '../pages/CmngmInvoices';
import { defaultStore } from '../_store/storage';
import moment from 'moment';

export default {
    name: 'ProductionStatsQuery',
	setup() {
		const store = defaultStore()
		return { store}
	},
	component: {
		//MonthlyInvoices
	},
    data() {
        return {
			form: {
				meter_id: '',
				start: '',
				end: '',
				version: '',
			},
			show: true,
        }
    },
    computed: {
		ceIdState() {
			return this.form.meter_id === '' ? false : true
		},
		tsStartState() {
			return this.form.start === '' ? false : true
		},
		tsEndState() {
			return this.form.end === '' ? false : true
		},
		versionState() {
			return this.form.version === '' ? false : true
		},
    },
    methods: {
        isRequired(value) {
			return value ? true : 'This field is required';
		},
        getFormValues(event) {
			event.preventDefault();
			console.log(JSON.stringify(this.form));
		},
        resetModal() {
			this.form.meter_id = '';
            this.form.start = '';
			this.form.end = '';
			this.form.version = '';

			this.$nextTick(() => {
				this.show = true
			});	
        },
		dateDisabled(ymd, date) {
			const weekday = date.getDay();
			return weekday >= 1 && weekday <= 5;
		},
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()	
			
			//console.log(this.form);
			
			this.form.start = this.form.start+'-01T00:00:00';
			let currday = moment().format('DD');
			this.form.end = this.form.end+'-'+currday+'T00:00:00';//add current day
			this.form.meter_id = parseInt(this.form.meter_id);
			this.form.version = parseInt(this.form.version);

			console.log(this.form)

			CommercialMgmtService.getProductionUPNR( this.form ).then((response) => {
                console.log( response );
				//this.$parent.gridApi.setRowData( response.data )
            }).catch((err) => {
				console.log(err);
			});

            this.$nextTick(() => {
                this.$bvModal.hide('modal-monthlyInvoicesQuery');				
            })
        }
    },
	created() {
		
	}
}
</script>