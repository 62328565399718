<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container>
            <!-- BEGIN row -->
            <b-row class="justify-content-center">
                <b-breadcrumb>
                    <b-breadcrumb-item href="#">Monitoring</b-breadcrumb-item>
                    <b-breadcrumb-item active>Electric Meters</b-breadcrumb-item>
                </b-breadcrumb>
                <!-- BEGIN col-10 -->
                <b-col xl="10">

                    <b-row>
                        <b-col><h2 class="page-header">Electric Meters</h2></b-col>
                    </b-row>	
                
                    <hr class="mb-4" />					

                    <b-row>
                        <b-col xl="12">	
                            <div style="height: 100%">			
                                <ag-grid-vue
                                    @grid-ready="onGridReady"
                                    style="width: 100%; height: 100% "
                                    class="ag-theme-alpine"
                                    :columnDefs="columnDefs"
                                    :rowData="celist"
                                    :rowSelection="rowSelection"
                                    :pagination="true"
                                    :paginationPageSize="paginationPageSize"
                                    @first-data-rendered="onFirstDataRendered"	
                                    @selection-changed="onSelectionChanged"													
                                />
                            </div>
                        </b-col>	
                    </b-row>

                </b-col>
            </b-row>
        </b-container>
        <!-- BEGIN modals -->
        <ModalNewElectricMeter />
        <!-- END modals -->
    </div>
</template>

<script>

import TechnicalMgmtService from '../_services/TechnicalMgmtService';
import { defaultStore } from '../_store/storage';
import ModalNewElectricMeter from '../components/ModalNewElectricMeter';


import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
    name: 'ElectricMeters',
    setup() {
        const store = defaultStore()
        return { store}
    },
    data() {
        return {
            celist: [],
            plants: [],
            columnDefs: [
                { headerName: 'ID', field: 'id', sortable: true, filter: true, minWidth: 50, maxWidth: 150, hide: true },
                { headerName: this.$t('meters.Register'), field: 'serialNumber', sortable: true, filter: true, minWidth: 100 },
                { headerName: 'Plant', field: 'plantDto.plantName', sortable: true, filter: true, minWidth: 100, valueFormatter: this.plantName },
                { headerName: 'SAPR', field: 'plantDto.sapr', sortable: true, filter: true, minWidth: 100, },
            ],
            gridApi: null,
            columnApi: null,
            rowSelection: null,
            defaultColDef: {
                resizable: true,
                flex: 1,
                minWidth: 100
            },
            rowData: null,
        }
    },
    components: {
        ModalNewElectricMeter,
        AgGridVue,
    },
    methods: {
        plantName(params) {
            for (const [key, value] of Object.entries(this.plants)) {
                if(value.plantId === params.value) {
                    return 'ID: '+key + '; Name: ' + value.name;
                }
            } 
        },
        getElectricMeters() {
            console.log('Get electric meters list:');
            this.store.setPlantID(null);

            TechnicalMgmtService.getElectricMeters().then((response) => {
                console.log(response);
                this.celist = response.data;
            }).catch((err) => {
                console.log(err);
                this.dataloggers = [{ "ceId": "ND", "ceNumber": "ND", "plantId": "ND", "loggerId": "ND" }];
            });

            //.modal('show')
        },
        getPlants() {
            console.log('Get plants list:');
            //this.store.setPlantID(null);
            TechnicalMgmtService.getPlants().then((response) => {
                //console.log(response);
                this.plants = response.data;
            }).catch((err) => {
                console.log(err);
            });
        },
        openDetails(id) {
            window.location.href = window.location.origin + "/monitoring/electric-meter/" + id
            // axios.delete('https://your.rest.api/users/' + id)
        },
        onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },
        onSelectionChanged() {
            var selectedRows = this.gridApi.getSelectedRows();
            var selectedRowsString = '';
            var maxToShow = 5;
            selectedRows.forEach(function (selectedRow, index) {
                if (index >= maxToShow) {
                    return;
                }
                if (index > 0) {
                    selectedRowsString += ', ';
                }
                selectedRowsString += selectedRow.id;
            });
            if (selectedRows.length > maxToShow) {
                var othersCount = selectedRows.length - maxToShow;
                selectedRowsString +=
                ' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
            }
            console.log(selectedRowsString);
            this.openDetails(selectedRowsString);
            //document.querySelector('#selectedRows').innerHTML = selectedRowsString;
        },
        onGridReady(params) {
            console.log('grid ready');
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
        },
    },
    async created() {
        this.rowSelection = 'multiple';
        this.paginationPageSize = 10;

        await this.getPlants();
        await this.getElectricMeters();		
    }
}
</script>
