<script>
const azureConfigs = {
    tenant: 'b7a007c4-acfe-40ba-bcd3-eaf2565b06bd',
    clientId: '52c08d76-1757-4788-a607-08f2e7e0257d',
    redirectUri: 'https://edm.psaierenergies.it:443/',
    cacheLocation: 'localStorage',
    requireAuthOnInitialize: false,
    postLogoutRedirectUri: 'https://edm.psaierenergies.it:443/user/login'
}

export default azureConfigs;
</script>