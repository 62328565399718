<template>
	<!-- BEGIN #content -->
	<div id="content" class="app-content">
		<!-- BEGIN container -->
		<b-container>
			<!-- BEGIN row -->
			<b-row class="justify-content-center">
				<!-- BEGIN col-10 -->
				<b-col xl="10">
					<!-- BEGIN row -->
					<b-row>
						<!-- BEGIN col-9 -->
						<b-col xl="9">
							<!-- BEGIN #general -->
							<div id="general" class="mb-5">
								<h4><i class="far fa-user fa-fw"></i> General</h4>
								<p>View and update your general account information and settings.</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Name</div>
												<div class="text-gray-600">Sean Ngu</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Username</div>
												<div class="text-gray-600">@vuestudio</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Phone</div>
												<div class="text-gray-600">+1-202-555-0183</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Email address</div>
												<div class="text-gray-600">support@studio.com</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Password</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #general -->
							
							<!-- BEGIN #notifications -->
							<div id="notifications" class="mb-5">
								<h4><i class="far fa-bell fa-fw"></i> Notifications</h4>
								<p>Enable or disable what notifications you want to receive.</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Comments</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled (Push, SMS)
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Tags</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Reminders</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled (Push, Email, SMS)
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>New orders</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled (Push, Email, SMS)
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #notifications -->
							
							<!-- BEGIN #privacyAndSecurity -->
							<div id="privacyAndSecurity" class="mb-5">
								<h4><i class="far fa-copyright fa-fw"></i> Privacy and security</h4>
								<p>Limit the account visibility and the security settings for your website.</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Who can see your future posts?</div>
												<div class="text-gray-600 d-flex align-items-center">
													Friends only
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Photo tagging</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Location information</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Firewall</div>
												<div class="text-gray-600 d-block d-xl-flex align-items-center">
													<div class="d-flex align-items-center"><i class="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled</div>
													<span class="bg-warning-transparent-1 text-warning ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
														<i class="fa fa-exclamation-circle text-warning fs-12px me-1"></i> 
														<span class="text-warning">Please enable the firewall for your website</span>
													</span>
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #privacyAndSecurity -->
							
							<!-- BEGIN #payment -->
							<div id="payment" class="mb-5">
								<h4><i class="far fa-credit-card fa-fw"></i> Payment</h4>
								<p>Manage your website payment provider</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Allowed payment method</div>
												<div class="text-gray-600">
													Paypal, Credit Card, Apple Pay, Amazon Pay, Google Wallet, Alipay, Wechatpay
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #payment -->
							
							<!-- BEGIN #shipping -->
							<div id="shipping" class="mb-5">
								<h4><i class="far fa-paper-plane fa-fw"></i> Shipping</h4>
								<p>Allowed shipping area and zone setting</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Allowed shipping method</div>
												<div class="text-gray-600">
													Local, Domestic
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #shipping -->
							
							<!-- BEGIN #mediaAndFiles -->
							<div id="mediaAndFiles" class="mb-5">
								<h4><i class="far fa-images fa-fw"></i> Media and Files</h4>
								<p>Allowed files and media format upload setting</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Allowed files and media format</div>
												<div class="text-gray-600">
													.png, .jpg, .gif, .mp4
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Media and files cdn</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #mediaAndFiles -->
							
							<!-- BEGIN #languages -->
							<div id="languages" class="mb-5">
								<h4><i class="fa fa-language fa-fw"></i> Languages</h4>
								<p>Language font support and auto translation enabled</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Language enabled</div>
												<div class="text-gray-600">
													English (default), Chinese, France, Portuguese, Japense
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Auto translation</div>
												<div class="text-gray-600 d-flex align-items-center">
													<i class="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #languages -->
							
							<!-- BEGIN #system -->
							<div id="system" class="mb-5">
								<h4><i class="far fa-hdd fa-fw"></i> System</h4>
								<p>System storage, bandwidth and database setting</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Web storage</div>
												<div class="text-gray-600">
													40.8gb / 100gb
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" class="w-100px">Edit</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Monthly bandwidth</div>
												<div class="text-gray-600">
													Unlimited
												</div>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Database</div>
												<div class="text-gray-600">
													MySQL version 8.0.19
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="default" disabled class="w-100px">Update</b-button>
											</div>
										</b-list-group-item>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Platform</div>
												<div class="text-gray-600">
													PHP 7.4.4, NGINX 1.17.0
												</div>
											</div>
											<div class="w-100px">
												<b-button v-b-modal.modalEdit variant="success" disabled class="w-100px">Update</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #system -->
							
							<!-- BEGIN #resetSettings -->
							<div id="resetSettings" class="mb-5">
								<h4><i class="fa fa-redo fa-fw"></i> Reset settings</h4>
								<p>Reset all website setting to factory default setting.</p>
								<b-card no-body>
									<b-list-group flush>
										<b-list-group-item class="d-flex align-items-center">
											<div class="flex-fill">
												<div>Reset Settings</div>
												<div class="text-gray-600">
													This action will clear and reset all the current website setting.
												</div>
											</div>
											<div class="w-100px">
												<b-button variant="default" class="w-100px">Reset</b-button>
											</div>
										</b-list-group-item>
									</b-list-group>
								</b-card>
							</div>
							<!-- END #resetSettings -->
						</b-col>
						<!-- END col-9-->
						<!-- BEGIN col-3 -->
						<b-col xl="3">
							<!-- BEGIN #sidebar-bootstrap -->
							<div class="navbar navbar-sticky d-none d-xl-block">
								<b-nav v-b-scrollspy="120">
									<b-nav-item href="#general" @click="scrollIntoView">General</b-nav-item>
									<b-nav-item href="#notifications" @click="scrollIntoView">Notifications</b-nav-item>
									<b-nav-item href="#privacyAndSecurity" @click="scrollIntoView">Privacy and security</b-nav-item>
									<b-nav-item href="#payment" @click="scrollIntoView">Payment</b-nav-item>
									<b-nav-item href="#shipping" @click="scrollIntoView">Shipping</b-nav-item>
									<b-nav-item href="#mediaAndFiles" @click="scrollIntoView">Media and Files</b-nav-item>
									<b-nav-item href="#languages" @click="scrollIntoView">Languages</b-nav-item>
									<b-nav-item href="#system" @click="scrollIntoView">System</b-nav-item>
									<b-nav-item href="#resetSettings" @click="scrollIntoView">Reset settings</b-nav-item>
								</b-nav>
							</div>
							<!-- END #sidebar-bootstrap -->
						</b-col>
						<!-- END col-3 -->
					</b-row>
					<!-- END row -->
				</b-col>
				<!-- END col-10 -->
			</b-row>
			<!-- END row -->
		</b-container>
		<!-- END container -->
		
		<b-modal id="modalEdit" title="Edit name">
			<b-form-group class="mb-3" label="Name">
				<b-row class="row-space-10">
					<b-col cols="4">
						<input class="form-control" placeholder="First" value="Sean" />
					</b-col>
					<b-col cols="4">
						<input class="form-control" placeholder="Middle" value="" />
					</b-col>
					<b-col cols="4">
						<input class="form-control" placeholder="Last" value="Ngu" />
					</b-col>
				</b-row>
			</b-form-group>
			<b-alert variant="muted" show>
				<b>Please note:</b> 
				If you change your name, you can't change it again for 60 days. 
				Don't add any unusual capitalization, punctuation, characters or random words. 
				<b-link href="#" class="alert-link">Learn more.</b-link>
			</b-alert>
			<b-form-group label="Other Names">
				<div>
					<b-link href="#"><i class="fa fa-plus fa-fw"></i> Add other names</b-link>
				</div>
			</b-form-group>
			
			<template v-slot:modal-footer="{ ok, cancel }">
				<b-button variant="default" @click="cancel()">Close</b-button>
				<b-button variant="primary" @click="ok()">Save changes</b-button>
			</template>
		</b-modal>
	</div>
	<!-- END #content -->
</template>

<script>
	export default {
		methods: {
			scrollIntoView(evt) {
				evt.preventDefault()
				const href = evt.target.getAttribute('href')
				const el = href ? document.querySelector(href) : null
				if (el) {
					window.scrollTo(0, el.offsetTop);
				}
			}
		}
	}
</script>